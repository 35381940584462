import axios from 'axios'
import { JWT_TOKEN_KEY, API_URL, API_INTEGRATION_URL } from 'app-constants'

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(config => {
  const requestConfig = { ...config }

  if (
    !!requestConfig.baseURL &&
    (requestConfig.baseURL === API_URL || requestConfig.baseURL === API_INTEGRATION_URL) &&
    !requestConfig.public
  ) {
    const token = localStorage.getItem(JWT_TOKEN_KEY)

    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`
    }
  }

  return requestConfig
})

const http = instance

export { http }
