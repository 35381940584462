import React from 'react'
import { GenericCardSmall } from './small'
import { GenericCardLarge } from './large'

export function GenericCardTemplate(props) {
  return {
    small: <GenericCardSmall {...props} />,
    large: <GenericCardLarge {...props} />,
  }
}
