import React from 'react'
import { UnderstandYourBillsModal } from './understand-your-bills'
import { DuplicateInvoiceModal } from './duplicate-invoice'
import { FileUploadModal } from './file-upload'
import { PasswordRecoveryModal } from './password-recovery'
import { PasswordResetModal } from './password-reset'
import { UraEvaluationModal } from './ura-evaluation'
import { UpdateEmailModal } from './update-email'
import { MessagesModal } from './messages'
import { UraEvaluationValidationModal } from './ura-evaluation-validation'
import { ContactUpdateModal } from './contact-update'
import { FileUploadWhatsappModal } from './file-upload-whatsapp'
import { ReferFriendModal } from './refer-friend'
import { PixModal } from './pix'
import { DaysToBreachAgreementModal } from './days-to-breach-agreement'

export function ModalTemplate() {
  return {
    understand_your_bills: <UnderstandYourBillsModal />,
    duplicate_invoice: <DuplicateInvoiceModal />,
    file_upload_sumicity: <FileUploadModal />,
    password_recovery: <PasswordRecoveryModal />,
    password_reset: <PasswordResetModal />,
    ura_evaluation_sucess: <UraEvaluationModal />,
    ura_evaluation_validation: <UraEvaluationValidationModal />,
    update_email: <UpdateEmailModal />,
    messages: <MessagesModal />,
    contact_update: <ContactUpdateModal />,
    file_upload_whatsapp: <FileUploadWhatsappModal />,
    refer_friend: <ReferFriendModal />,
    pix_payment: <PixModal />,
    days_to_breach_agreement: <DaysToBreachAgreementModal />,
  }
}
