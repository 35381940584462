import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { getBookings, useFirebase } from 'hooks'
import { convertISOStringToDate, formatZipCode, toISO } from 'utils'
import { Button, ContextLoader, CustomDatePicker, SelectInput } from 'components'
import {
  FIREBASE_EVENTS,
  SCHEDULE_TYPE,
  SCHEDULING_SHIFT_LABEL,
  SERVICE_ORDER_TYPES,
} from 'app-constants'
import { BookingRadio } from '../booking-radio'
import { ADDRESS_CHANGE_STAGES } from '../../constants'
import './style.scss'

export function BookingForm({ data: userData, setData }) {
  const contractId = useSelector(state => state.contract.selected.id)
  const [isLoading, setIsLoading] = useState(false)
  const [bookingAvailableDays, setBookingAvailableDays] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [availableShifts, setAvailableShifts] = useState([])
  const [selectedShift, setSelectedShift] = useState(null)
  const [codeBooking, setCodeBooking] = useState(null)
  const [selectedTimeFrames, setSelectedTimeFrames] = useState({})
  const [bucket, setBucket] = useState('')
  const [workzone, setWorkzone] = useState('')
  const [unity, setUnity] = useState('')
  const [firstAvailableDate, setFirstAvailableDate] = useState(null)
  const { sendEvent } = useFirebase()

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const {
          neighborhood: district,
          number,
          street,
          ibgeCodeCity,
          city,
          zipcode,
          addressType: transmissionType,
          uf,
        } = userData

        const {
          data: { bookingDaysAvailable, code, firstAvailableDate, workZoneCode, unity },
        } = await getBookings({
          params: {
            contractId,
            district,
            ibgeCodeCity,
            number,
            street,
            transmissionType,
            city,
            state: uf,
            zipCode: formatZipCode(zipcode),
            serviceOrderType: SERVICE_ORDER_TYPES.ADDRESS_CHANGE,
          },
        })

        setBookingAvailableDays(bookingDaysAvailable || [])
        setCodeBooking(code)
        setFirstAvailableDate(firstAvailableDate)
        setWorkzone(workZoneCode)
        setUnity(unity)
      } catch (error) {
        console.error(error)
        dispatch(setStage(ADDRESS_CHANGE_STAGES.ERROR))
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [userData, contractId, dispatch])

  const getScheduleData = useCallback(
    date => bookingAvailableDays.find(day => day?.date === toISO(date)),
    [bookingAvailableDays]
  )

  const handleChangeSchedule = useCallback(
    date => {
      setSelectedDate(date)

      const selectedDateScheduleData = getScheduleData(date)

      const defaultShift = selectedDateScheduleData?.bookingShiftAvailabilities[0]

      setSelectedShift(defaultShift?.shift)
      setSelectedTimeFrames(defaultShift || {})
      setAvailableShifts(selectedDateScheduleData?.bookingShiftAvailabilities)
      setBucket(selectedDateScheduleData?.bucket)
    },
    [getScheduleData]
  )

  function mapBookingAvailableDays() {
    return bookingAvailableDays.map(({ date }) => convertISOStringToDate(date))
  }

  function handleSubmit() {
    sendEvent(FIREBASE_EVENTS.ADDRESS_CHANGE_SCHEDULE)
    setData({
      ...userData,
      date: selectedDate,
      shift: selectedShift,
      codeBooking,
      timeFrames: selectedTimeFrames,
      bucket,
      firstAvailableDate,
      workzone,
      unity,
    })
    dispatch(setStage(ADDRESS_CHANGE_STAGES.CONFIRMATION))
  }

  function getDisableButton() {
    return (
      !selectedDate ||
      !selectedShift ||
      !selectedTimeFrames?.initialTimeFrame ||
      !selectedTimeFrames?.finalTimeFrame
    )
  }

  function getScheduleType() {
    return SCHEDULE_TYPE.TURN
  }

  function renderDefaultTurn() {
    return availableShifts.map(({ shift, initialTimeFrame, finalTimeFrame }) => (
      <BookingRadio
        key={shift.dataCy}
        label={`${SCHEDULING_SHIFT_LABEL[shift]} (${initialTimeFrame} - ${finalTimeFrame})`}
        checked={selectedShift === shift}
        value={shift}
        onChange={() => {
          setSelectedTimeFrames({ initialTimeFrame, finalTimeFrame })
          setSelectedShift(shift)
        }}
        dataCy={shift.dataCy}
      />
    ))
  }

  function renderTurns() {
    return availableShifts.map(({ shift }) => (
      <BookingRadio
        key={shift.dataCy}
        label={SCHEDULING_SHIFT_LABEL[shift]}
        checked={selectedShift === shift}
        value={shift}
        onChange={() => {
          setSelectedTimeFrames({})
          setSelectedShift(shift)
        }}
        dataCy={shift.dataCy}
      />
    ))
  }

  function getSelectedTimeFramePosition(timeFrameList) {
    return timeFrameList.findIndex(
      ({ initialTimeFrame, finalTimeFrame }) =>
        initialTimeFrame === selectedTimeFrames?.initialTimeFrame &&
        finalTimeFrame === selectedTimeFrames?.finalTimeFrame
    )
  }

  function renderTimeFrames() {
    const { bookingShiftTimeFrameAvailabilityResponseDtos: timeFrameList } = availableShifts.find(
      ({ shift }) => shift === selectedShift
    )

    return (
      selectedShift && (
        <>
          <h3 className='field-title'>Escolha o horário de instalação</h3>
          <SelectInput
            onChange={({ target: { value: index } }) => setSelectedTimeFrames(timeFrameList[index])}
            className='time-frame-select'
            value={getSelectedTimeFramePosition(timeFrameList)}
          >
            <option value=''>Horário da Instalação</option>
            {timeFrameList?.map((timeFrames, index) => {
              const { initialTimeFrame, finalTimeFrame } = timeFrames
              const name = `${initialTimeFrame} - ${finalTimeFrame}`

              return (
                <option key={name} value={index}>
                  {name}
                </option>
              )
            })}
          </SelectInput>
        </>
      )
    )
  }

  function renderTimeFramesShift() {
    return (
      <>
        {renderTurns()}
        {renderTimeFrames()}
      </>
    )
  }

  // TODO: Gabriel Hoffmann 10/11/2022 - https://dev.azure.com/Sumicity/Sumicity/_workitems/edit/9300
  function renderScheduleShifts() {
    const scheduleType = getScheduleType()

    const SCHEDULE_SHIFTS = {
      [SCHEDULE_TYPE.TURN]: renderDefaultTurn,
      [SCHEDULE_TYPE.TIME_FRAME]: renderTimeFramesShift,
    }

    return (
      availableShifts.length > 0 && (
        <>
          <h3 className='field-title'>Escolha o melhor período para instalação</h3>
          {SCHEDULE_SHIFTS[scheduleType]()}
        </>
      )
    )
  }

  if (isLoading) return <ContextLoader fixed={false} />

  return (
    <div className='booking-form-component'>
      <h2 className='title'>Agendar mudança de endereço</h2>
      <p className='text'>Selecione uma data para instalação:</p>
      <h3 className='field-title'>Agende a sua instalação</h3>
      <CustomDatePicker
        availableDates={mapBookingAvailableDays()}
        onChange={handleChangeSchedule}
        startDate={new Date()}
        selectedDate={selectedDate}
        dataCy='select-schedule-day'
      />
      {renderScheduleShifts()}
      <Button
        type='button'
        onClick={handleSubmit}
        dataCy='submit-schedule'
        disabled={getDisableButton()}
        isLoading={isLoading}
        color='primary'
        size='large'
      >
        Continuar
      </Button>
    </div>
  )
}
