import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setDrawer, setStage } from 'slices/drawer'
import { fetchConnectionStatus } from 'slices/contract'
import {
  PLAN_STATUS_KEY,
  CONNECTION_STATUS,
  SUSPENDED_CONTRACT_STATUS,
  FIREBASE_EVENTS,
} from 'app-constants'
import { useFirebase } from 'hooks'
import { Card } from 'components/card'
import { Tag } from 'components/tag'
import { STATICS } from 'statics'
import { CardButton } from '../card-button'
import './style.scss'

export function SubscriptionCard({ className }) {
  const { id, address, situation, contractStatus } = useSelector(state => state.contract.selected)
  const { connectionStatus } = useSelector(state => state.contract)
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()
  const { city, type, street, number, uf } = address

  useEffect(() => {
    if (!connectionStatus) dispatch(fetchConnectionStatus(id))
  }, [id, dispatch, connectionStatus])

  function handleOpenPlanDetails() {
    dispatch(setDrawer('plan'))
  }

  function handleOpenDueDate() {
    dispatch(setDrawer('dueDate'))

    if (contractStatus !== PLAN_STATUS_KEY.ST_CONT_HABILITADO) {
      sendEvent(FIREBASE_EVENTS.CLICK_CHANGE_DUE_DATE_INELIGIBLE)
      dispatch(setStage('disabled'))
    } else {
      sendEvent(FIREBASE_EVENTS.CLICK_CHANGE_DUE_DATE)
    }
  }

  function getConnectionStatus() {
    const isSuspendedByDebit = contractStatus === PLAN_STATUS_KEY.ST_CONT_SUSP_DEBITO

    if (isSuspendedByDebit) return SUSPENDED_CONTRACT_STATUS

    return CONNECTION_STATUS[connectionStatus]
  }

  function renderConnectionStatus() {
    const statusInfo = getConnectionStatus()

    if (!statusInfo) return null

    const { LABEL, COLOR } = statusInfo

    return (
      <div className='connection-status'>
        <div className='round' style={{ backgroundColor: COLOR }} />
        <span className='status'>{LABEL}</span>
      </div>
    )
  }

  return (
    <Card className={`subscription-card-component ${className || ''}`}>
      <div className='content'>
        <div className='header'>
          <h3 className='title'>Meu plano</h3>
          <Tag className='tag' color='primary' text={situation} />
        </div>
        <div className='contract'>
          <p className='text'>Contrato: {id}</p>
          <Tag className='tag' color='primary' text={situation} />
        </div>
        <p className='address'>
          {type} {street}, {number} - {city}, {uf}
        </p>
        {renderConnectionStatus()}
      </div>
      <div className='action'>
        <CardButton icon={<STATICS.CHANGE_DUE_DATE />} onClick={handleOpenDueDate}>
          Alterar vencimento da fatura
        </CardButton>
        <CardButton icon={<STATICS.SEE_PLANS />} onClick={handleOpenPlanDetails}>
          Ver meu plano
        </CardButton>
      </div>
    </Card>
  )
}
