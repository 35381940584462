import React from 'react'
import { RadioButton } from 'components'
import './style.scss'

export function BookingRadio({ label, checked, value, onChange, dataCy }) {
  return (
    <div className={`booking-radio-component ${checked ? 'selected' : ''}`}>
      <RadioButton
        name='schedule-shifts'
        dataCy={dataCy}
        label={label}
        checked={checked}
        value={value}
        onChange={onChange}
        labelClassName='booking-label'
        circleClassName='booking-circle'
        inputClassName='booking-input'
      />
    </div>
  )
}
