import { STATICS } from 'statics'

export const MY_SERVICES = {
  FOX: { image: STATICS.FOX },
  SUMICITY_PLAY: {
    eventName: 'CLICK_ON_SVA_SUMICITY_PLAY',
  },
  MUMO: {
    eventName: 'CLICK_ON_SVA_MUMO',
  },
  PARAMOUNT: {
    eventName: 'CLICK_ON_SVA_PARAMOUNT',
  },
  QUALIFICA: {
    eventName: 'CLICK_ON_SVA_QUALIFICA',
  },
  REVISTAS_JA: {
    eventName: 'CLICK_ON_SVA_REVISTAS_JA',
  },
  BIT_TRAINERS: {
    eventName: 'CLICK_ON_SVA_BIT_TRAINERS',
  },
  MINUTO_CARREIRA: {
    eventName: 'CLICK_ON_SVA_MINUTO_CARREIRA',
  },
  CONTA_OUTRA_VEZ: {
    eventName: 'CLICK_ON_SVA_CONTA_OUTRA_VEZ',
  },
  LIVROH: {
    eventName: 'CLICK_ON_SVA_LIVROH',
  },
  PAULISTAO_PLAY: {
    eventName: 'CLICK_ON_SVA_PAULISTAO_PLAY',
  },
  TOP_MOVIES: {
    eventName: 'CLICK_ON_SVA_TOP_MOVIES',
  },
  WATCH_ESPN: {
    eventName: 'CLICK_ON_SVA_WATCH_ESPN',
  },
  HERO: {
    eventName: 'CLICK_ON_SVA_HERO',
  },
  NOGGIN: {
    eventName: 'CLICK_ON_SVA_NOGGIN',
  },
  DISCOVERY_KIDS_PLUS: {
    eventName: 'CLICK_ON_SVA_DISCOVERY_KIDS_PLUS',
  },
  MAQUINA_DE_CONTOS: {
    eventName: 'CLICK_ON_SVA_MAQUINA_DE_CONTOS',
  },
  TOCA_LIVROS: {
    eventName: 'CLICK_ON_SVA_TOCA_LIVROS',
  },
  LEVEDUCA: {
    eventName: 'CLICK_ON_SVA_LEVEDUCA',
  },
  SUPER_COMICS: {
    eventName: 'CLICK_ON_SVA_SUPER_COMICS',
  },
  DGO: {
    eventName: 'CLICK_ON_SVA_DGO',
  },
  ROKU: {
    eventName: 'CLICK_ON_SVA_ROKU',
  },
  WATCH_PARAMOUNT: {
    eventName: 'CLICK_ON_SVA_WATCH_PARAMOUNT',
  },
  BEGAMER: {
    eventName: 'CLICK_ON_SVA_BEGAMER',
  },
  BEBANCA: {
    eventName: 'CLICK_ON_SVA_BEBANCA',
  },
  COQUETEL: {
    eventName: 'CLICK_ON_SVA_COQUETEL',
  },
  ESAUDE: {
    eventName: 'CLICK_ON_SVA_ESAUDE',
  },
  FIT_ANYWHERE: {
    eventName: 'CLICK_ON_SVA_FIT_ANYWHERE',
  },
  GIGA_TV: {
    eventName: 'CLICK_ON_SVA_GIGA_TV',
  },
  SKEELO: {
    eventName: 'CLICK_ON_SVA_SKEELO',
  },
  WATCH_MAX: {
    eventName: 'CLICK_ON_SVA_WATCH_MAX',
  },
}
