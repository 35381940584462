import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'components'
import { setDrawer, setStage } from 'slices/drawer'
import { CONTACT_TYPE } from 'app-constants'
import { STATICS } from 'statics'
import './style.scss'

export function SignupSuccess() {
  const { contacts } = useSelector(({ customer }) => customer.info)
  const dispatch = useDispatch()

  useEffect(() => {
    const existsConfirmedEmail = contacts
      .filter(({ contactType }) => contactType === CONTACT_TYPE.EMAIL)
      .some(contact => contact.confirmed)

    if (!existsConfirmedEmail) {
      dispatch(setStage('initial'))
    }
  }, [contacts, dispatch])

  function handleClick() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='signup-success'>
      <div className='success'>
        <div className='icon'>
          <STATICS.DONE />
        </div>
      </div>
      <h3 className='title'>Parabéns, seu cadastro foi finalizado com sucesso!</h3>
      <Button className='button' size='large' color='primary' onClick={handleClick}>
        Voltar para a home
      </Button>
    </div>
  )
}
