import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ACTIVATION_PENDING_STAGES } from 'app-constants'
import {
  ReschedulingServiceOrder,
  RescheduleActivationConfirmation,
  ServiceOrder,
  SchedulingSuccess,
} from './components'

function stages({ ...props }) {
  return {
    [ACTIVATION_PENDING_STAGES.INITIAL]: <ServiceOrder {...props} />,
    [ACTIVATION_PENDING_STAGES.RESCHEDULE_SERVICE_ORDER]: <ReschedulingServiceOrder {...props} />,
    [ACTIVATION_PENDING_STAGES.RESCHEDULE_ACTIVATION_CONFIRMATION]: (
      <RescheduleActivationConfirmation {...props} />
    ),
    [ACTIVATION_PENDING_STAGES.SCHEDULING_SUCCESS]: <SchedulingSuccess {...props} />,
  }
}

export function ActivationPendingDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)
  const [serviceOrder, setServiceOrder] = useState(null)
  const [bookingSelected, setBookingSelected] = useState(null)

  return stages({ serviceOrder, setServiceOrder, bookingSelected, setBookingSelected })[stage]
}
