import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import {
  AutoSupportInfo,
  MassiveWarning,
  RedirectChat,
  ServiceOrderInfo,
  TalkToAttendant,
  AddressConfirmation,
  SelectAddress,
  CheckModemLights,
  SchedulingSuccess,
  AfterTimeStage,
  SchedulingServiceOrder,
  SelectServiceStage,
  RescheduleConfirmation,
  ScheduleConfirmation,
  ReschedulingServiceOrder,
  VirtualSupportAlert,
  CancelConfirmation,
  CancelSuccess,
} from './components'

function stages({ ...props }) {
  let INITIAL_STAGE = <AutoSupportInfo {...props} />
  if (props.pendingConfirmation) {
    INITIAL_STAGE = <ServiceOrderInfo {...props} />
  } else if (props.suspendedByDebit) {
    INITIAL_STAGE = <VirtualSupportAlert {...props} />
  } else if (props.virtualSupportMassive?.message) {
    INITIAL_STAGE = <MassiveWarning {...props} />
  }
  return {
    [VIRTUAL_SUPPORT_STAGES.INITIAL]: INITIAL_STAGE,
    [VIRTUAL_SUPPORT_STAGES.AUTO_SUPPORT]: <AutoSupportInfo {...props} />,
    [VIRTUAL_SUPPORT_STAGES.SERVICE_ORDER]: <ServiceOrderInfo {...props} />,
    [VIRTUAL_SUPPORT_STAGES.TALK_TO_ATTENDANT]: <TalkToAttendant {...props} />,
    [VIRTUAL_SUPPORT_STAGES.ADDRESS_CONFIRMATION]: <AddressConfirmation {...props} />,
    [VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS]: <SelectAddress {...props} />,
    [VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT]: <RedirectChat {...props} />,
    [VIRTUAL_SUPPORT_STAGES.CHECK_MODEM_LIGHTS]: <CheckModemLights {...props} />,
    [VIRTUAL_SUPPORT_STAGES.SELECT_SERVICES]: <SelectServiceStage {...props} />,
    [VIRTUAL_SUPPORT_STAGES.AFTER_TIME]: <AfterTimeStage {...props} />,
    [VIRTUAL_SUPPORT_STAGES.SCHEDULING_SERVICE_ORDER]: <SchedulingServiceOrder {...props} />,
    [VIRTUAL_SUPPORT_STAGES.SCHEDULE_CONFIRMATION]: <ScheduleConfirmation {...props} />,
    [VIRTUAL_SUPPORT_STAGES.SCHEDULING_SUCCESS]: <SchedulingSuccess {...props} />,
    [VIRTUAL_SUPPORT_STAGES.RESCHEDULE_CONFIRMATION]: <RescheduleConfirmation {...props} />,
    [VIRTUAL_SUPPORT_STAGES.RESCHEDULING_SERVICE_ORDER]: <ReschedulingServiceOrder {...props} />,
    [VIRTUAL_SUPPORT_STAGES.CANCEL_CONFIRMATION]: <CancelConfirmation {...props} />,
    [VIRTUAL_SUPPORT_STAGES.CANCEL_SUCCESS]: <CancelSuccess {...props} />,
  }
}

export function VirtualSupportDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)
  const { pendingConfirmation } = useSelector(state => state.serviceOrder)
  const { virtualSupportMassive } = useSelector(({ massive }) => massive)
  const { suspendedByDebit } = useSelector(({ contract }) => contract.selected)
  const [serviceOrder, setServiceOrder] = useState(null)
  const [contractAddressSelected, setContractAddressSelected] = useState(null)
  const [bookingSelected, setBookingSelected] = useState(null)
  const [variantWarning, setVariantWarning] = useState(false)

  return stages({
    virtualSupportMassive,
    suspendedByDebit,
    serviceOrder,
    setServiceOrder,
    contractAddressSelected,
    setContractAddressSelected,
    bookingSelected,
    setBookingSelected,
    variantWarning,
    setVariantWarning,
    pendingConfirmation,
  })[stage]
}
