import React from 'react'
import { PasswordRecovery } from 'components'
import { ROUTES } from 'app-constants'

export function FirstAccessDocument() {
  return (
    <PasswordRecovery
      title='Primeiro acesso'
      subtitle='Informe o CPF ou CNPJ do titular da conta'
      buttonLabel='Enviar código'
      nextStep={ROUTES.FIRST_ACCESS_TOKEN.PATH}
    />
  )
}
