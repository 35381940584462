import React from 'react'
import { useDispatch } from 'react-redux'
import { setModal } from 'slices/modal'
import { Button } from 'components'
import { STATICS } from 'statics'
import './style.scss'

export function Copy() {
  const dispatch = useDispatch()

  function handleClick() {
    dispatch(setModal({ key: '' }))
  }

  return (
    <div className='container-copy'>
      <div className='container-alert'>
        <div className='icon'>
          <STATICS.SUCCESS />
        </div>
        <span className='description'>Código copiado!</span>
      </div>
      <div className='container-steps'>
        <div className='step first'>
          <div className='number'>1</div>
          <span className='description'>
            Acesse o app do seu banco ou internet banking de preferência.
          </span>
        </div>
        <div className='step'>
          <div className='number'>2</div>
          <span className='description'>
            Escolha pagar com Pix, cole o código e finalize o pagamento.
          </span>
        </div>
      </div>
      <div className='container-buttons'>
        <Button className='button' onClick={() => handleClick()} color='primary'>
          Ok, entendi!
        </Button>
      </div>
    </div>
  )
}
