import React from 'react'
import { STATICS } from 'statics'
import { BRAND_DISPLAY_NAME } from 'app-constants'
import './style.scss'

export function ContextLoader({ fixed = true }) {
  return (
    <div
      data-testid='context-loader-component'
      className={`context-loader-component ${fixed ? '-fixed' : ''}`}
    >
      <img alt={BRAND_DISPLAY_NAME} src={STATICS.LOGO} className='loader-img' />
    </div>
  )
}
