import React from 'react'
import { useSelector } from 'react-redux'
import { PasswordEdit, PasswordSuccess } from './components'

export function PasswordDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages()[stage]
}

function stages() {
  return {
    initial: <PasswordEdit />,
    success: <PasswordSuccess />,
  }
}
