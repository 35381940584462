import React from 'react'
import { BRAND_DISPLAY_NAME, REFER_FRIEND_REGULATION } from 'app-constants'
import { STATICS } from 'statics'
import './style.scss'

export function ReferFriendModal() {
  return (
    <div className='refer-friend'>
      <div className='refer-friend-icon'>
        <STATICS.MESSAGE_PLUS />
      </div>
      <h2 className='refer-friend-title'>
        Participe do programa ‘Indique Um Amigo’ e ganhe 100% de desconto na sua fatura
      </h2>
      <p className='refer-friend-text'>
        Envie seu cupom exclusivo para seus amigos. Eles ganham 20% de desconto e você ganha fatura
        grátis!
      </p>

      <h3 className='refer-friend-subtitle'>Como usar seu cupom</h3>
      <ul className='refer-friend-list'>
        <li className='refer-list-item'>1- Compartilhe seu código com quem você quiser</li>
        <li className='refer-list-item'>
          2- Peça para que seu amigo acesse o site da {BRAND_DISPLAY_NAME} e escolha um dos planos
        </li>
        <li className='refer-list-item'>
          3- Basta seu amigo inserir o código no momento da assinatura no campo:
          <strong> Código de desconto</strong>, e finalizar o pedido
        </li>
      </ul>

      <h3 className='refer-friend-subtitle'>Informações importantes sobre o programa</h3>
      <ul className='refer-friend-list'>
        <li className='refer-list-item'>
          - Os descontos serão concedidos após a instalação do plano de internet do cliente
          indicado;
        </li>
        <li className='refer-list-item'>
          - O desconto em fatura depende do ciclo de faturamento de sua conta;
        </li>
        <li className='refer-list-item'>
          - O desconto é cumulativo, ou seja, se 5 pessoas assinarem um plano da Sumicity com o seu
          cupom, você terá 5 faturas com 100% de desconto;
        </li>
        <li className='refer-list-item'>
          - Para conferir mais sobre o programa, consulte o{' '}
          <a
            href={REFER_FRIEND_REGULATION}
            rel='noreferrer'
            target='_blank'
            className='refer-friend-regulation'
          >
            regulamento
          </a>
          .
        </li>
      </ul>

      <p className='refer-friend-text discount-info'>
        * O desconto em fatura depende do ciclo de faturamento de sua conta e da data de ativação do
        plano do amigo indicado
      </p>
    </div>
  )
}
