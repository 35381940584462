import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { formatPhone } from 'utils'
import { REGISTRATION_DATA_STAGES, CONTACT_TYPE, FIREBASE_EVENTS } from 'app-constants'
import { useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { Card, Button } from 'components'
import './style.scss'

export function PhoneList({ setData }) {
  const [defaultPhone, setDefaultPhone] = useState({})
  const [phonesList, setPhonesList] = useState([])
  const { sendEvent } = useFirebase()
  const {
    info: { contacts },
  } = useSelector(state => state.customer)
  const dispatch = useDispatch()

  useEffect(() => {
    function manipulateContacts() {
      const phonesArr = contacts.filter(item => item.contactType === CONTACT_TYPE.PHONE)
      const phonesDefault = phonesArr.find(phone => phone.principal)

      setDefaultPhone(phonesDefault)
      setPhonesList(phonesArr.filter(phone => !phone.principal))
    }

    manipulateContacts()
  }, [contacts])

  function handleAdd() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_ADD_NEW_PHONE)
    dispatch(setStage(REGISTRATION_DATA_STAGES.ADD))
  }

  function handleConfirm(phone) {
    setData(phone)
    dispatch(setStage(REGISTRATION_DATA_STAGES.CONFIRMATION))
  }

  function handleDelete(phone) {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_DELETE_PHONE)
    setData(phone)
    dispatch(setStage(REGISTRATION_DATA_STAGES.DELETE))
  }

  function handleChangeDefault() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_ALTER_PHONE)
    dispatch(setStage(REGISTRATION_DATA_STAGES.SELECT_DEFAULT))
  }

  function renderPhone(phone) {
    return formatPhone(phone)
  }

  function renderEmpty() {
    return <p className='empty'>Você não possui telefone cadastrado.</p>
  }

  function renderSetDefaultButton() {
    return (
      <Button className='button' color='primary' outlined onClick={handleChangeDefault}>
        Mudar telefone principal
      </Button>
    )
  }

  function renderContactNotConfirmedInfo(phone) {
    return (
      <>
        <span className='not-confirmed'>Telefone não confirmado!</span>
        <Button className='button' color='primary' outlined onClick={() => handleConfirm(phone)}>
          Confirmar telefone
        </Button>
      </>
    )
  }

  function renderContent() {
    return (
      <div className='content'>
        <h3 className='title'>Meus telefones</h3>
        {!!defaultPhone && (
          <div className='section'>
            <span className='title'>Telefone principal</span>
            <Card className='card'>
              <div className='content'>
                <div className='info'>
                  <span className='phone'>{renderPhone(defaultPhone?.contact)}</span>
                </div>
              </div>
              {phonesList.length > 0 && renderSetDefaultButton()}
            </Card>
          </div>
        )}
        {!!phonesList.length && (
          <div className='section'>
            <span className='title'>Meus demais telefones</span>
            {phonesList.map(item => (
              <Card className='card' key={String(item.contactId)}>
                <div className='content'>
                  <div className='info'>
                    <span className='phone'>{renderPhone(item.contact)}</span>
                    {!item.confirmed && renderContactNotConfirmedInfo(item)}
                  </div>
                  <div className='actions'>
                    <STATICS.DELETE className='delete' onClick={() => handleDelete(item)} />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='phone-list-component'>
      {!defaultPhone ? renderEmpty() : renderContent()}
      <Button className='button' color='primary' size='large' onClick={handleAdd}>
        Adicionar novo telefone
      </Button>
    </div>
  )
}
