import { PasswordResetForm } from 'components'

export function PasswordResetRequired() {
  return (
    <PasswordResetForm
      title='Atualização de senha'
      subtitle='Caro cliente, atualize sua senha para garantir a segurança da sua conta e continuar
            desfrutando dos nossos serviços com tranquilidade.'
      required
    />
  )
}
