import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ACTIVATION_PENDING_STAGES,
  DEFAULT_ERROR_MESSAGE,
  BRAND_DISPLAY_NAME,
  SERVICE_ORDER_TYPES,
  FIREBASE_EVENTS,
  SO_TYPES,
} from 'app-constants'
import { BackButton, Button, ContextLoader, TrackingButton } from 'components'
import { getServiceOrderDetails, useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { setDrawer, setDrawerAlert, setStage } from 'slices/drawer'
import { formatDate, formatTurn, getFirstName } from 'utils'
import './style.scss'

export function ServiceOrder({ serviceOrder, setServiceOrder }) {
  const [isOverdue, setIsOverdue] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const {
    info: { name },
  } = useSelector(({ customer }) => customer)
  const { id } = useSelector(({ contract }) => contract.selected)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  useEffect(() => {
    async function loadActivationStatus() {
      try {
        const { data } = await getServiceOrderDetails(id, SERVICE_ORDER_TYPES.ACTIVATION)
        setIsOverdue(data.isDateAbleToReschedule)
        setServiceOrder(data)
        setIsLoadingPage(false)
      } catch (error) {
        const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE

        console.error(error)
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      }
    }

    loadActivationStatus()
  }, [id, dispatch, setServiceOrder])

  function handleReschedule() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SERVICE_ORDER_RESCHEDULE)
    dispatch(setStage(ACTIVATION_PENDING_STAGES.RESCHEDULE_SERVICE_ORDER))
  }

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function renderInfosCard() {
    return (
      <div className='card-infos'>
        <div className='tag-status'>
          <p className='status'>{serviceOrder.type}</p>
        </div>
        <div className='infos'>
          <p className='abstract'>Resumo do Pedido</p>
          <p className='info'>
            Número: <span>#{serviceOrder.osId}</span>
          </p>
          <p className='info'>
            Data da instalação: <span>{formatDate(serviceOrder.expectedInstallationDate)}</span>
          </p>
          <p className='info'>
            Turno: <span>{formatTurn(serviceOrder.expectedInstallationTurn)}</span>
          </p>
          {serviceOrder.technician && (
            <p className='info'>
              Técnico: <span>{serviceOrder.technician}</span>
            </p>
          )}
        </div>
        <div className='alert'>
          <div className='icon-alert'>
            <STATICS.INFO_OUTLINE />
          </div>
          <p className='alert-schedule'>
            Após a ativação, você terá acesso à todas as funcionalidades da Central do Assinante{' '}
            {BRAND_DISPLAY_NAME}.
          </p>
        </div>
      </div>
    )
  }

  function renderConfirmAction() {
    const osIsFinished = serviceOrder.type === SO_TYPES.FINISHED

    if (osIsFinished) {
      return (
        <Button className='button' color='primary' size='large' onClick={handleClose}>
          Ok, entendi!
        </Button>
      )
    }

    return (
      <>
        <TrackingButton className='button' osId={serviceOrder.osId} />
        {isOverdue && (
          <Button
            className='button'
            color='primary'
            size='large'
            outlined
            onClick={() => handleReschedule()}
          >
            Reagendar visita técnica
          </Button>
        )}
        <BackButton className='button' />
      </>
    )
  }

  if (isLoadingPage) return <ContextLoader fixed={false} />

  return (
    <div className='activation-pending-component'>
      <div className='content'>
        <h3 className='title'>{`Olá ${getFirstName(name)}`}</h3>
        <span className='subtitle'>Acompanhe aqui o status da sua instalação</span>
        {renderInfosCard()}
      </div>
      <div className='links'>{renderConfirmAction()}</div>
    </div>
  )
}
