import { BRAND_DISPLAY_NAME } from 'app-constants'

export const CONTENT = [
  {
    TOPIC: 'Você realizou a troca do seu plano no último mês?',
    ANSWER:
      'Quando você solicita uma troca de plano, cobramos pelo proporcional de dias de uso entre o plano antigo e o novo plano, até a data de fechamento de sua fatura.',
  },
  {
    TOPIC: 'Solicitou alguma mudança do equipamento para outro cômodo ou endereço?',
    ANSWER:
      'Se sim, é importante lembrar da taxa do serviço mencionada no atendimento, ela pode estar sendo cobrada nesta fatura.',
  },
  {
    TOPIC: 'Seu serviço foi suspenso por falta de pagamento?',
    ANSWER:
      'Não consideramos no valor da fatura os dias de serviço suspenso por falta de pagamento.',
  },
  {
    TOPIC: 'Seu plano atual já completou 12 meses?',
    ANSWER: [
      `Se o seu contrato foi fechado por um valor promocional na época, este desconto pode ter acabado após um ano. Além disso, seu contrato pode ter sofrido reajuste anual. A ${BRAND_DISPLAY_NAME} reajusta a cada 12 meses (medidos a partir do início da prestação do serviço) o valor da fatura usando como índice o IGP-M (FGV).`,
      'Os preços serão reajustados anualmente, de acordo com a legislação vigente, ou ainda, no menor prazo permitido por lei, pela variação do IGP-M/FGV, ou outro índice oficial que venha substitui-los.',
    ],
  },
  {
    TOPIC: 'É sua primeira fatura?',
    ANSWER: `Se você ainda não completou 30 dias como cliente da ${BRAND_DISPLAY_NAME}, sua fatura poderá vir com o valor menor do que foi contratado.`,
  },
  {
    TOPIC: 'Alterou a data de vencimento da sua fatura no último mês?',
    ANSWER:
      'Quando você troca a data de vencimento da fatura, o valor cobrado será proporcional aos dias de uso até a nova data de vencimento escolhida.',
  },
]
