import React from 'react'
import { STATICS } from 'statics'

export function PasswordHeader({ title, subtitle, alert = false }) {
  function renderSubtitle() {
    if (alert) {
      return (
        <div className='warning'>
          <span className='icon'>
            <STATICS.INFO_TRIANGLE />
          </span>
          <p>{subtitle}</p>
        </div>
      )
    }

    return <h3 className='subtitle'>{subtitle}</h3>
  }

  return (
    <>
      <h1 className='title'>{title}</h1>
      {renderSubtitle()}
    </>
  )
}
