import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPendingConfirmation } from 'slices/serviceOrder'
import { DRAWER_KEY } from 'app-constants'
import { ConfirmScheduleInfo } from './components'

export function ConfirmServiceScheduleDrawer() {
  const { stage, key } = useSelector(({ drawer }) => drawer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (key === DRAWER_KEY.NONE) {
      dispatch(setPendingConfirmation(null))
    }
  }, [key, dispatch])

  return stages()[stage]

  function stages() {
    return {
      initial: <ConfirmScheduleInfo />,
    }
  }
}
