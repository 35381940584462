import React from 'react'
import { useDispatch } from 'react-redux'
import { setModal } from 'slices/modal'
import { STATICS } from 'statics'
import { Button } from 'components'
import './style.scss'

export function SuccessStage() {
  const dispatch = useDispatch()

  function handleButtonClick() {
    dispatch(setModal(''))
  }

  return (
    <div className='contact-update-success-stage'>
      <STATICS.ROUND_GREEN_CHECK />
      <span className='description'>Atualização cadastral concluída</span>
      <span className='description'>Seu telefone e e-mail foram atualizados com sucesso.</span>
      <Button onClick={handleButtonClick} className='button' color='primary'>
        Ok, entendi!
      </Button>
    </div>
  )
}
