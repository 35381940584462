import React from 'react'
import { STATICS } from 'statics'
import './style.scss'

export function Loader({ className }) {
  return (
    <div className={`loader-component ${className || ''}`} data-testid='loader-component'>
      <img className='img' src={STATICS.LOADER_GIF} alt='loader' />
    </div>
  )
}
