import React from 'react'
import { Button } from 'components'
import { STATICS } from 'statics'
import './style.scss'

export function DocumentLine({ name, urlFile, description, btnLabel = 'Baixar Documento', event }) {
  function renderDownload() {
    return (
      <Button
        className='button'
        icon={<STATICS.ALTERNATIVE_DOWNLOAD />}
        color='primary'
        onClick={handleClick}
      >
        {btnLabel}
      </Button>
    )
  }

  function handleClick() {
    window.open(urlFile)
  }

  return (
    <div className='document-line-component'>
      <div className='wrapper'>
        <p className='field'>{name}</p>
      </div>
      <div className='wrapper'>
        <p className='field'>{description}</p>
      </div>
      <div className='actions'>{renderDownload()}</div>
    </div>
  )
}
