import React from 'react'
import { STATICS } from 'statics'
import './style.scss'

export function AfterTimeStage() {
  return (
    <div className='container-after-time'>
      <div className='container-icon'>
        <STATICS.INFO />
      </div>
      <div className='container-info'>
        <span className='info'>
          Caro cliente, nosso canal de atendimento via chat funciona todos os dias de 6h às 00h,
          para falar com um de nossos atendentes ligue para <span className='number'>103 53</span>
        </span>
      </div>
    </div>
  )
}
