import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearHistory, setDrawer } from 'slices/drawer'
import { fetchContacts } from 'slices/customer'
import { Button } from 'components'
import { STATICS } from 'statics'
import './style.scss'

export function EmailSuccess() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchContacts())
    dispatch(clearHistory())
  }, [dispatch])

  function handleClose() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='email-success-component'>
      <div className='content'>
        <div className='round'>
          <STATICS.DONE className='icon' />
        </div>
        <h3 className='title'>Seu e-mail foi atualizado com sucesso!</h3>
        <h5 className='subtitle'>Caso deseje analisar a mudança, acesse seus dados cadastrais.</h5>
      </div>
      <Button type='button' color='primary' size='large' onClick={handleClose}>
        Voltar para a home
      </Button>
    </div>
  )
}
