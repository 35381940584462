import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setModal } from 'slices/modal'
import { setDrawer, setStage } from 'slices/drawer'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import {
  CALLS_STAGES,
  BRAND_DISPLAY_NAME,
  COMPONENTS_RENDER,
  DYNAMIC_RENDER_COMPONENTS,
} from 'app-constants'
import { CONTENT } from './content'
import './style.scss'

export function UnderstandYourBillsModal() {
  const dispatch = useDispatch()
  const { telephony } = useSelector(state => state.contract)
  const history = useHistory()
  const { id: phoneId } = telephony || {}

  function handleClose() {
    dispatch(setModal({ key: '' }))
  }

  function handleGoToInvoices() {
    handleClose()
    history.push('minhas-faturas#Faturas abertas')
  }

  function handleGoToCallExtract() {
    handleClose()
    dispatch(setDrawer('calls'))
    dispatch(setStage(CALLS_STAGES.EXTRACT))
  }

  function handleOpenPlanDetails() {
    handleClose()
    dispatch(setDrawer('plan'))
  }

  function renderCallExtractButtons() {
    const shouldRenderCallButtons =
      phoneId && COMPONENTS_RENDER[DYNAMIC_RENDER_COMPONENTS.CALL_HISTORIC]

    return (
      shouldRenderCallButtons && (
        <div className='col'>
          <Button
            type='button'
            className='button'
            color='default'
            size='large'
            onClick={handleGoToCallExtract}
          >
            Ir para extrato de ligações
          </Button>
        </div>
      )
    )
  }

  return (
    <div className='understand-your-bills-component'>
      <div className='round'>
        <STATICS.BILL />
      </div>
      <h4 className='title'>Entenda suas faturas</h4>
      <h5 className='subtitle'>
        Sua fatura veio com um valor diferente do que você está acostumado a pagar?
        <br />
        Entenda o que pode ter acontecido.
      </h5>

      <div className='container'>
        <ul className='topics'>
          {CONTENT.map(({ TOPIC, ANSWER }) => (
            <li>
              <div className='topic'>{TOPIC}</div>
              <div className='topic-answer'>
                {Array.isArray(ANSWER) ? ANSWER.map(answer => <p>{answer}</p>) : ANSWER}
              </div>
            </li>
          ))}
          {!!phoneId && (
            <li>
              <div className='topic'>Fez ligações do seu telefone fixo?</div>
              <div className='topic-answer'>
                Você pode estar sendo cobrado por ligações excedentes à franquia do seu plano.
                Verifique o seu{' '}
                <button className='link' onClick={handleOpenPlanDetails}>
                  plano
                </button>{' '}
                e seu{' '}
                <button className='link' onClick={handleGoToCallExtract}>
                  extrato de ligações
                </button>
                .
              </div>
            </li>
          )}
        </ul>

        <div className='post-script'>
          Para dúvidas e informações sobre seu plano atual e o valor dele, acesse a opção{' '}
          <button className='link' onClick={handleOpenPlanDetails}>
            Ver meu plano
          </button>
          . <br />
          <b>Importante:</b> nossas faturas são pós-pagas. Você usa os serviços da{' '}
          {BRAND_DISPLAY_NAME} e depois paga. A fatura é emitida cerca de 10 dias antes do
          vencimento do boleto.
        </div>

        <div className='col'>
          <Button
            type='button'
            className='button'
            color='primary'
            size='large'
            onClick={handleGoToInvoices}
          >
            Ir para minhas faturas
          </Button>
        </div>
        {renderCallExtractButtons()}
      </div>
    </div>
  )
}
