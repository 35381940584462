import { Button } from 'components'
import './style.scss'

export function UnlockAccountContent({
  backgroundColor = 'red',
  staticIcon: StaticIcon,
  subTitle = '',
  title = '',
  onClick = () => {},
  loading = false,
  info = '',
  buttonLabel = '',
}) {
  return (
    <div className='unlock-account-content'>
      <div className={`round -${backgroundColor}`}>
        <StaticIcon className='icon' />
      </div>
      <h4 className={`title -${!subTitle ? 'paddingBottom' : ''}`}>{title}</h4>
      {subTitle && <h5 className='subtitle'>{subTitle}</h5>}

      <Button type='button' color='primary' size='large' onClick={onClick} isLoading={loading}>
        {buttonLabel}
      </Button>

      {info && <p className='info'>{info}</p>}
    </div>
  )
}
