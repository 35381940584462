import { URL_FILES } from './envs'

export const TECHNICAL_SUPPORT_PAGE_LABELS = {
  INTERNET: {
    name: 'Internet',
    items: [
      {
        urlFile: `${URL_FILES}suporte/DICAS-FIBERHOME-MARCAS.pdf`,
        name: 'Fiberhome',
        description: 'Procedimentos para equipamentos Fiberhome',
      },
      {
        urlFile: `${URL_FILES}suporte/DICAS-NOKIA-MARCAS.pdf`,
        name: 'Nokia',
        description: 'Procedimentos para equipamentos Nokia',
      },
      {
        urlFile: `${URL_FILES}suporte/DICAS-HUAWEI-MARCAS.pdf`,
        name: 'Huawei',
        description: 'Procedimentos para equipamentos Huawei',
      },
    ],
  },
  TELEPHONY: {
    name: 'Telefonia',
    items: [
      {
        urlFile: `${URL_FILES}telefonia/TELEFONIA.pdf`,
        name: 'Telefonia',
        description: 'Procedimentos para equipamentos de telefonia',
      },
    ],
  },
  TV: {
    name: 'TV',
    items: [
      {
        urlFile: `${URL_FILES}suporte/ANDROID-MARCAS.pdf`,
        name: 'Android TV',
        description: 'Procedimentos para equipamentos de Android TV',
      },
      {
        urlFile: `${URL_FILES}suporte/HOMECAST-MARCAS.pdf`,
        name: 'Home Cast',
        description: 'Procedimentos para equipamentos de Home Cast',
      },
    ],
  },
}
