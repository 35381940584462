export const API_URL = process.env.REACT_APP_API_URL
export const API_KEY = process.env.REACT_APP_API_KEY
export const API_INTEGRATION_URL = process.env.REACT_APP_API_INTEGRATION_URL
export const API_REQUEST_ORIGIN = process.env.REACT_APP_REQUEST_ORIGIN
export const URL_FILES = `${process.env.REACT_APP_URL_FILES}central-assinante/`
export const URL_FILES_GIGAMAIS = `${process.env.REACT_APP_URL_FILES}gigamais-central-assinante/`
export const API_URA_URL = process.env.REACT_APP_API_URA_URL
export const API_URA_TOKEN = process.env.REACT_APP_API_URA_TOKEN
export const API_URA_KEY = process.env.REACT_APP_API_URA_KEY
export const WHATSAPP_NUMBER = process.env.REACT_APP_WHATSAPP_NUMBER
export const CHAT_CSS = process.env.REACT_APP_CHAT_CSS
export const REFER_FRIEND_REGULATION = process.env.REACT_APP_REFER_FRIEND_REGULATION
export const API_ADAPTER_URL = process.env.REACT_API_ADAPTER_URL
export const API_ADAPTER_KEY = process.env.REACT_API_ADAPTER_KEY
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME
export const BRAND_DISPLAY_NAME = process.env.REACT_APP_BRAND_DISPLAY_NAME
export const SIGMA_CHATBOT_ICON = process.env.REACT_APP_SIGMA_CHATBOT_ICON
export const API_FAST_BILLET_URL = process.env.REACT_APP_API_FAST_BILLET_URL
export const API_FAST_BILLET_KEY = process.env.REACT_APP_API_FAST_BILLET_KEY
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
export const FIREBASE_DB_NAME = process.env.REACT_APP_FIREBASE_DB_NAME
export const CENTRAL_GIGA_URL = process.env.REACT_APP_CENTRAL_GIGA_URL
export const BRAND_DISPLAY_NAME_REDIRECT = process.env.REACT_APP_BRAND_DISPLAY_NAME_REDIRECT
export const CLOUDFLARE_SECRET = process.env.REACT_APP_CLOUDFLARE_SECRET
