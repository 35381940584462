import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clearHistory, setStage } from 'slices/drawer'
import { CONTACT_TYPE } from 'app-constants'
import { SignupPhone, SignupEmail, SignupSuccess } from './components'
import { PhoneConfirmation } from '../phone/components/confirmation'
import { EmailConfirmation } from '../email/components/confirmation'

export function CompleteSignupDrawer() {
  const [data, setData] = useState(null)

  const { contacts } = useSelector(({ customer }) => customer.info)
  const dispatch = useDispatch()

  useEffect(() => {
    const phoneList = contacts.filter(({ contactType }) => contactType === CONTACT_TYPE.PHONE)
    const emailList = contacts.filter(({ contactType }) => contactType === CONTACT_TYPE.EMAIL)
    const existsConfirmedPhone = phoneList.some(({ confirmed }) => confirmed)
    const existsConfirmedEmail = emailList.some(({ confirmed }) => confirmed)

    if (phoneList.length > 0 && !existsConfirmedPhone) {
      const unconfirmedPhone = contacts.find(
        contact =>
          !contact.confirmed && contact.contactType === CONTACT_TYPE.PHONE && contact.principal
      )

      setData(unconfirmedPhone)
      dispatch(setStage('phoneConfirmation'))
      dispatch(clearHistory())
    } else if (emailList.length > 0 && !existsConfirmedEmail) {
      const unconfirmedEmail = contacts.find(
        contact => !contact.confirmed && contact.contactType === CONTACT_TYPE.EMAIL
      )

      setData(unconfirmedEmail)
      dispatch(setStage('emailConfirmation'))
      dispatch(clearHistory())
    } else if (phoneList.length === 0) {
      dispatch(setStage('initial'))
      dispatch(clearHistory())
    } else if (emailList.length === 0) {
      dispatch(setStage('email'))
      dispatch(clearHistory())
    }
  }, [contacts, dispatch])

  const { stage } = useSelector(({ drawer }) => drawer)

  return stages({ data })[stage]
}

function stages(props) {
  return {
    initial: <SignupPhone {...props} />,
    email: <SignupEmail {...props} />,
    phoneConfirmation: <PhoneConfirmation {...props} />,
    emailConfirmation: <EmailConfirmation {...props} />,
    success: <SignupSuccess />,
  }
}
