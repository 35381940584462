import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { FIREBASE_EVENTS } from 'app-constants'
import { formatCurrency, formatDate } from 'utils'
import { Button } from 'components/button'
import { Input } from 'components/input'
import { useFirebase } from 'hooks'
import { STAGES } from '../../constants'
import { TotalNegotiationCard } from '../total-card'
import './style.scss'

export function NegotiationOptions({ invoices, parcels, setSelectedOption }) {
  const [selected, setSelected] = useState({})
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.BILLET_NEGOTIATION)
  }, [sendEvent])

  function handleAcceptClick() {
    setSelectedOption(selected)
    dispatch(setStage(STAGES.CONFIRMATION))
  }

  function handleRejectClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_BILLET_NEGOTIATION_REJECT_NEGOTIATION)

    dispatch(setStage(STAGES.REFUSE_OPTIONS))
  }

  function handleCheckboxChange(parcel) {
    setSelected(parcel)
  }

  function renderParcelValue(parcel) {
    const { quantity, firstParcelValue, parcelValue, debtDiscountPercent } = parcel

    if (quantity > 1) {
      return (
        <div className='container-values'>
          <span className='option-value'>
            {formatCurrency(firstParcelValue)} + {quantity - 1} x {formatCurrency(parcelValue)}
          </span>
        </div>
      )
    }

    return (
      <div className='container-values'>
        <span className='option-value'>
          {quantity} x {formatCurrency(firstParcelValue)}
        </span>
        <span className='discount'>{debtDiscountPercent}% de desconto</span>
      </div>
    )
  }

  function renderDueDate(parcel) {
    const { dueDate, quantity } = parcel
    const dueDateString = formatDate(dueDate, 'dd/MM')

    if (quantity === 1) {
      return <span className='option-due-date'>Vencimento em {dueDateString}</span>
    }

    return <span className='option-due-date'>venc. 1ª parcela em {dueDateString}</span>
  }

  function renderOptions() {
    return parcels.map(parcel => {
      const { idGroup } = parcel
      const isChecked = selected?.idGroup === idGroup

      return (
        <div className='option-card'>
          <Input
            onChange={() => handleCheckboxChange(parcel)}
            name='select-parcel'
            className='option-checkbox'
            type='checkbox'
            checked={isChecked}
          />
          <div className='option-info'>
            {renderDueDate(parcel)}
            {renderParcelValue(parcel)}
          </div>
        </div>
      )
    })
  }

  return (
    <span className='negotiation-options'>
      <h3 className='title'>Negociar Pagamento</h3>
      <TotalNegotiationCard invoices={invoices} />
      <div className='description-container'>
        <h4 className='title'>Opções da Pagamento</h4>
        <p className='description'>
          Selecione a opção de pagamento abaixo para negociar suas faturas em aberto.
        </p>
      </div>
      <div className='option-container'>{renderOptions()}</div>
      <div className='button-container'>
        <Button disabled={!selected} className='button' color='primary' onClick={handleAcceptClick}>
          Negociar
        </Button>
        <Button className='button refuse' color='primary' outlined onClick={handleRejectClick}>
          Rejeitar negociação
        </Button>
      </div>
    </span>
  )
}
