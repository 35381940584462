import React from 'react'
import { Button } from 'components/button'
import './style.scss'

export function GenericCardSmall({ title, children, Icon, onClick, buttonLabel = 'Saiba mais' }) {
  function handleClick(e) {
    e.stopPropagation()
    onClick()
  }

  return (
    <>
      <div className='image'>
        <Icon className='icon' />
      </div>
      <div className='container'>
        <h3 className='title'>{title}</h3>
        <p className='text'>{children}</p>
      </div>
      <div className='action'>
        <Button className='button' onClick={e => handleClick(e)}>
          {buttonLabel}
        </Button>
      </div>
    </>
  )
}
