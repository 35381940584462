import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  AddressChangeAlert,
  AddressChangeConfirmation,
  AddressChangeForm,
  BookingForm,
  AddressChangeCondominium,
  RequestStatus,
  Reschedule,
  RescheduleConfirmation,
  RescheduleSuccess,
  CancelConfirmation,
  CancelSuccess,
} from './components'
import { ADDRESS_CHANGE_STAGES, ALERT_THEME } from './constants'

function stages({ ...props }) {
  return {
    [ADDRESS_CHANGE_STAGES.INITIAL]: <AddressChangeForm {...props} />,
    [ADDRESS_CHANGE_STAGES.BOOKING]: <BookingForm {...props} />,
    [ADDRESS_CHANGE_STAGES.CONFIRMATION]: <AddressChangeConfirmation />,
    [ADDRESS_CHANGE_STAGES.CONDOMINIUM]: <AddressChangeCondominium {...props} />,
    [ADDRESS_CHANGE_STAGES.SUCCESS]: <AddressChangeAlert theme={ALERT_THEME.SUCCESS} {...props} />,
    [ADDRESS_CHANGE_STAGES.ERROR]: <AddressChangeAlert theme={ALERT_THEME.ERROR} {...props} />,
    [ADDRESS_CHANGE_STAGES.OPEN_INVOICES]: (
      <AddressChangeAlert theme={ALERT_THEME.OPEN_INVOICES} {...props} />
    ),
    [ADDRESS_CHANGE_STAGES.UNAVAILABLE_FEATURE]: (
      <AddressChangeAlert theme={ALERT_THEME.UNAVAILABLE_FEATURE} {...props} />
    ),
    [ADDRESS_CHANGE_STAGES.CONDOMINIUM_NOT_REGISTERED]: (
      <AddressChangeAlert theme={ALERT_THEME.CONDOMINIUM_NOT_REGISTERED} {...props} />
    ),
    [ADDRESS_CHANGE_STAGES.ADDRESS_INVIABILITY]: (
      <AddressChangeAlert theme={ALERT_THEME.ADDRESS_INVIABILITY} {...props} />
    ),
    [ADDRESS_CHANGE_STAGES.CONFIRMATION]: <AddressChangeConfirmation {...props} />,
    [ADDRESS_CHANGE_STAGES.SERVICE_ORDER_STATUS]: <RequestStatus {...props} />,
    [ADDRESS_CHANGE_STAGES.RESCHEDULE]: <Reschedule {...props} />,
    [ADDRESS_CHANGE_STAGES.RESCHEDULE_CONFIRMATION]: <RescheduleConfirmation {...props} />,
    [ADDRESS_CHANGE_STAGES.RESCHEDULE_SUCCESS]: <RescheduleSuccess />,
    [ADDRESS_CHANGE_STAGES.CANCEL_CONFIRMATION]: <CancelConfirmation {...props} />,
    [ADDRESS_CHANGE_STAGES.CANCEL_SUCCESS]: <CancelSuccess {...props} />,
  }
}

export function AddressChangeDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)
  const [data, setData] = useState({})
  const [selectedSchedule, setSelectedSchedule] = useState('')
  const [bookingCode, setBookingCode] = useState('')
  const [serviceOrderInfo, setServiceOrderInfo] = useState('')

  return stages({
    data,
    setData,
    selectedSchedule,
    setSelectedSchedule,
    setBookingCode,
    bookingCode,
    setServiceOrderInfo,
    serviceOrderInfo,
  })[stage]
}
