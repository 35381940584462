import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatePaidCall } from 'slices/contract'
import { setStage } from 'slices/drawer'
import { CALLS_STAGES, FIREBASE_EVENTS } from 'app-constants'
import { Card, Toggle } from 'components'
import { STATICS } from 'statics'
import { useFirebase } from 'hooks'
import './style.scss'

export function Calls() {
  const {
    selected: { id: idContract },
    telephony,
  } = useSelector(state => state.contract)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  const { collectCall, id: phoneId } = telephony || {}

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.TELEPHONY_MY_CALLS)
  }, [sendEvent])

  function handleToggle() {
    sendEvent(FIREBASE_EVENTS.TELEPHONY_COLLECT_CALL)
    dispatch(updatePaidCall({ idContract, phoneId, collectCall: !collectCall }))
  }

  function handleOpenCallsHistory() {
    dispatch(setStage(CALLS_STAGES.HISTORY))
  }

  function handleOpenCallsExtract() {
    dispatch(setStage(CALLS_STAGES.EXTRACT))
  }

  return (
    <div className='my-calls-component'>
      <h3 className='title'>Minhas ligações</h3>
      <Card className='card'>
        <p className='text'>Receber ligações a cobrar</p>
        <div className='toggle'>
          <Toggle
            label={collectCall ? 'Habilitado' : 'Desabilitado'}
            checked={collectCall}
            onChange={handleToggle}
          />
        </div>
      </Card>
      <button type='button' className='button' onClick={handleOpenCallsHistory}>
        Histórico de ligações{' '}
        <div className='icon'>
          <STATICS.ARROW_RIGHT />
        </div>
      </button>
      <button type='button' className='button' onClick={handleOpenCallsExtract}>
        Extrato de ligações{' '}
        <div className='icon'>
          <STATICS.ARROW_RIGHT />
        </div>
      </button>
    </div>
  )
}
