import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useForm } from 'hooks'
import { Input, Button } from 'components'
import { addCustomerContact, fetchContacts } from 'slices/customer'
import { setDrawerAlert } from 'slices/drawer'
import { MESSAGES, CONTACT_TYPE, DEFAULT_ERROR_MESSAGE } from 'app-constants'
import { formatPhoneToDB } from 'utils'
import './style.scss'

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required(MESSAGES.REQUIRED)
    .min(14, 'Formato inválido'),
})

export function SignupPhone() {
  const dispatch = useDispatch()
  const [error, setError] = useState('')

  const form = useForm({
    initialValues: {
      phone: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    const errors = [...Object.values(form.errors)]
    setError(errors[0])
  }, [form.errors])

  async function handleSubmit(values) {
    try {
      await dispatch(
        addCustomerContact({
          contact: formatPhoneToDB(values.phone),
          contactType: CONTACT_TYPE.PHONE,
          valid: true,
        })
      ).unwrap()

      await dispatch(fetchContacts()).unwrap()
      dispatch(setDrawerAlert({ severity: 'success', title: 'Telefone adicionado com sucesso!' }))
    } catch (error) {
      dispatch(setDrawerAlert({ severity: 'error', title: DEFAULT_ERROR_MESSAGE }))
    }
  }

  return (
    <form className='signup-initial' onSubmit={form.handleSubmit}>
      <h3 className='title'>Finalize seu cadastro</h3>
      <p className='text'>Preencha os dados que faltam para completarmos seu cadastro abaixo:</p>
      <p className='label'>Insira seu telefone</p>
      <Input
        className='input'
        name='phone'
        value={form.values.phone}
        onChange={form.handleChange}
        label='Telefone'
        hasError={form.errors.phone}
        mask='(99) 99999-9999'
      />
      {!!error && <p className='error'>{error}</p>}
      <Button
        className='button'
        size='large'
        color='primary'
        type='submit'
        isLoading={form.isSubmitting}
      >
        Continuar
      </Button>
    </form>
  )
}
