import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearHistory, setDrawer } from 'slices/drawer'
import { STATICS } from 'statics'
import { Button } from 'components/button'
import { FIREBASE_EVENTS } from 'app-constants'
import { useFirebase } from 'hooks'
import './style.scss'

export function DueDateSuccess() {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.CHANGE_DUE_DATE_SUCCESS)
    dispatch(clearHistory())
  }, [dispatch, sendEvent])

  function handleClose() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='due-date-success-component'>
      <div className='content'>
        <div className='round'>
          <STATICS.DONE className='icon' />
        </div>
        <h3 className='title'>Data de Vencimento foi atualizada com sucesso!</h3>
        <h4 className='info-title'>Atenção:</h4>
        <span className='info'>
          A alteração da data de vencimento pode ocasionar a cobrança proporcional dos dias de
          utilização até a nova data escolhida.
        </span>
      </div>
      <Button type='button' color='primary' size='large' onClick={handleClose}>
        Voltar para a home
      </Button>
    </div>
  )
}
