/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { getCurrentWifiTimer, removeWifiTimer } from 'utils'

const initialState = {
  countdownTimer: 0,
  inProgress: false,
  firstTimerFinished: false,
}

const wifiSlice = createSlice({
  name: 'wifi',
  initialState,
  reducers: {
    setTimer: state => {
      const countdownTimer = getCurrentWifiTimer()
      state.countdownTimer = countdownTimer
      if (countdownTimer) {
        state.inProgress = true
      }
    },
    updateFirstTimerStatus: (state, action) => {
      state.firstTimerFinished = action.payload
    },
    finishProgress: state => {
      state.inProgress = false
      state.firstTimerFinished = false
      state.countdownTimer = 0
      removeWifiTimer()
    },
  },
})

export const { setTimer, updateFirstTimerStatus, finishProgress } = wifiSlice.actions

export default wifiSlice.reducer
