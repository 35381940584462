import React from 'react'
import { ROUTES } from 'app-constants'
import { ValidateToken } from 'components'

export function FirstAccessValidateToken() {
  return (
    <ValidateToken
      title='Primeiro acesso'
      nextStep={ROUTES.FIRST_ACCESS_PASSWORD.PATH}
      prevStep={ROUTES.FIRST_ACCESS.PATH}
    />
  )
}
