import React from 'react'
import './style.scss'

export function Loader({ color = 'white' }) {
  return (
    <div className={`dots-component -${color}`}>
      <div className='dot' />
      <div className='dot' />
      <div className='dot' />
    </div>
  )
}
