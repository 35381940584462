import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Card } from 'components'
import { STATICS } from 'statics'
import { TECHNICAL_SUPPORT_PAGE_LABELS } from 'app-constants'
import { SupportLine } from './components'
import './style.scss'

const { INTERNET: internetTab, TELEPHONY: telephonyTab, TV: tvTab } = TECHNICAL_SUPPORT_PAGE_LABELS

export function TechnicalSupport() {
  const { hash } = useLocation()
  const [tab, setTab] = useState(hash.replace('#', ''))

  function renderTab(name) {
    let supportItem = null
    switch (name) {
      case 'Internet':
        supportItem = TECHNICAL_SUPPORT_PAGE_LABELS.INTERNET
        break
      case 'Telefonia':
        supportItem = TECHNICAL_SUPPORT_PAGE_LABELS.TELEPHONY
        break
      case 'TV':
        supportItem = TECHNICAL_SUPPORT_PAGE_LABELS.TV
        break
      default:
        supportItem = TECHNICAL_SUPPORT_PAGE_LABELS.INTERNET
        break
    }
    return supportItem.items.map(({ urlFile, name, description }) => (
      <SupportLine key={name} urlFile={urlFile} name={name} description={description} />
    ))
  }

  return (
    <div className='technical-support-page'>
      <Link className='return' to='/'>
        <div className='icon'>
          <STATICS.ARROW_BACK />
        </div>
        VOLTAR
      </Link>
      <h1 className='title'>Suporte Técnico</h1>
      <Card className='card'>
        <div className='tabs'>
          <button
            className={`tab ${tab === internetTab.name ? '-active' : ''}`}
            onClick={() => setTab(internetTab.name)}
          >
            {internetTab.name}
          </button>
          <button
            className={`tab ${tab === telephonyTab.name ? '-active' : ''}`}
            onClick={() => setTab(telephonyTab.name)}
          >
            {telephonyTab.name}
          </button>
          <button
            className={`tab ${tab === tvTab.name ? '-active' : ''}`}
            onClick={() => setTab(tvTab.name)}
          >
            {tvTab.name}
          </button>
        </div>
        {renderTab(tab)}
      </Card>
    </div>
  )
}
