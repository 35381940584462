export const CONTRACT_SITUATION = {
  ENABLED: 'Habilitado',
  DISABLED: 'Cancelado',
}

export const BANKSLIPS = {
  MAX_DAYS: 58,
}

export const CONTACT_TYPE = {
  PHONE: 'TELEFONE',
  EMAIL: 'EMAIL',
}

export const STATUS_CODE = {
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
}
