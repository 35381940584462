export const DYNAMIC_RENDER_COMPONENTS = {
  APP_DOWNLOAD_BUTTONS: 'APP_DOWNLOAD_BUTTONS',
  ATTEDANCE_TEXT: 'ATTEDANCE_TEXT',
  CONTRACT_PDF_DOWNLOAD: 'CONTRACT_PDF_DOWNLOAD',
  CALL_HISTORIC: 'CALL_HISTORIC',
  ADDRESS_CARD_CHANGE_ADDRESS_BUTTON: 'ADDRESS_CARD_CHANGE_ADDRESS_BUTTON',
  CONTACT_UPDATE_MODAL: 'CONTACT_UPDATE_MODAL',
  BANK_SLIPS_CLERK_BUTTON: 'BANK_SLIPS_CLERK_BUTTON',
  CREDIT_CARD_PAYMENT_BUTTON: 'CREDIT_CARD_PAYMENT_BUTTON',
  APPLICATION_REDIRECT: 'APPLICATION_REDIRECT',
}
