import React from 'react'
import { STATICS } from 'statics'
import './style.scss'

export function EmptyBills({ billType }) {
  return (
    <div className='empty-bills-component'>
      <div className='icon'>
        <STATICS.INVOICES_HISTORIC />
      </div>
      {billType}
    </div>
  )
}
