import React from 'react'
import { Card, Tag } from 'components'
import './style.scss'

const TAG_COLORS = {
  Cancelado: 'light',
  Habilitado: 'primary',
  'Aguardando Ativação': 'secondary',
  'Suspenso p/ Cancelamento': 'light',
  'Suspenso p/ Débito': 'light'
}

export function ContractCard({ contract, onClick }) {
  const {
    id,
    situation,
    address: { city, street, number, uf },
  } = contract

  return (
    <Card className='contract-card-partial' onClick={onClick}>
      <div className='info'>
        <p className='contract'>Contrato: {id}</p>
        <p className='address'>
          {street.toLowerCase()}, {number} - {city.toLowerCase()}, {uf}
        </p>
      </div>
      <Tag text={situation} color={TAG_COLORS[situation]} />
    </Card>
  )
}
