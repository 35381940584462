import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Button } from 'components'
import {
  DEFAULT_ERROR_MESSAGE,
  EVENT_STATUS,
  FIREBASE_EVENTS,
  SERVICE_ORDER_TYPES,
} from 'app-constants'
import { changeInstallationDate, useFirebase } from 'hooks'
import { setDrawerAlert, setStage } from 'slices/drawer'
import { formatDate, formatTurn } from 'utils'
import { ADDRESS_CHANGE_STAGES } from '../../constants'
import './style.scss'

export function RescheduleConfirmation({ selectedSchedule, bookingCode, serviceOrderInfo }) {
  const [isLoading, setIsLoading] = useState(false)
  const { id: contractId } = useSelector(state => state.contract.selected)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  async function handleClick() {
    sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_CONFIRM)

    try {
      setIsLoading(true)
      const params = {
        date: format(selectedSchedule.date, 'yyyy-MM-dd'),
        shift: selectedSchedule.turn,
        code: bookingCode,
        reference: selectedSchedule.reference,
        contractId,
        serviceOrderType: SERVICE_ORDER_TYPES.ADDRESS_CHANGE,
      }

      await changeInstallationDate(params, serviceOrderInfo.osId)
      sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_SUCCESS, EVENT_STATUS.SUCCESS)
      dispatch(setStage(ADDRESS_CHANGE_STAGES.RESCHEDULE_SUCCESS))
    } catch (error) {
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      console.error(error)
      sendEvent(
        FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_ERROR,
        EVENT_STATUS.ERROR,
        error?.response?.status
      )
      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className='reschedule-confirmation'>
      <div className='reschedule-confirmation-info'>
        <h3 className='reschedule-confirmation-title'>
          Reagendamento de solicitação de mudança de endereço
        </h3>
        <div className='reschedule-overview'>
          <p className='reschedule-confirmation-text'>
            Você solicitou o reagendamento da solicitação de mudança de endereço.
          </p>
          <p className='reschedule-confirmation-text'>Confira o resumo da solicitação:</p>
          <h4 className='info-title'>Data de agendamento:</h4>
          <p>{formatDate(selectedSchedule.date)}</p>
          <h4 className='info-title'>Turno:</h4>
          <p>{formatTurn(selectedSchedule.turn)}</p>
        </div>
      </div>

      <Button
        className='button'
        color='primary'
        size='large'
        onClick={() => handleClick()}
        isLoading={isLoading}
      >
        Solicitar reagendamento
      </Button>
    </div>
  )
}
