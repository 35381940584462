import { STORAGE_STEPS } from 'app-constants'

function base64ToBytes(base64) {
  const binString = window.atob(base64)
  return Uint8Array.from(binString, m => m.codePointAt(0))
}

function getDecodedData(base64) {
  return new TextDecoder().decode(base64ToBytes(base64))
}

function getBaseData(step = STORAGE_STEPS.GENERAL) {
  const base64data = sessionStorage.getItem(step.toLowerCase()) || window.btoa(JSON.stringify({}))

  return JSON.parse(getDecodedData(base64data))
}

function bytesToBase64(bytes) {
  const binString = String.fromCodePoint(...bytes)
  return window.btoa(binString)
}

function getEncodedData(data) {
  return bytesToBase64(new TextEncoder().encode(data))
}

function getItem({ step = STORAGE_STEPS.GENERAL, itemName }) {
  return getBaseData(step)[itemName]
}

function setItem({ step = STORAGE_STEPS.GENERAL, itemName, item }) {
  const data = getBaseData(step)
  data[itemName] = item

  const value = getEncodedData(JSON.stringify(data))
  sessionStorage.setItem(step.toLowerCase(), value)
}

export { getItem, setItem }
