import React from 'react'
import { STATICS } from 'statics'

export const STEPS = [
  {
    key: 1,
    icon: <STATICS.MODEM_OUTLINE className='chevron' />,
    message: 'Tire seu equipamento da tomada;',
  },
  {
    key: 2,
    icon: <STATICS.CLOCK_RESTART className='chevron' />,
    message: 'Aguarde 30 segundos e ligue novamente;',
  },
  {
    key: 3,
    icon: <STATICS.WIFI_RESTART className='chevron' />,
    message:
      'Aguarde aproximadamente 2 minutos até que seu equipamento esteja completamente iniciado;',
  },
  {
    key: 4,
    icon: <STATICS.WIFI_CHECK className='chevron' />,
    message: 'Verifique se sua conexão foi normalizada.',
  },
]
