import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useForm, sendUraEvaluation, getUraProtocol, getUraEvaluationProtocol } from 'hooks'
import { setModal } from 'slices/modal'
import { Button, Alert } from 'components'
import { STATICS } from 'statics'
import { EVALUATION_UTILS } from './utils'
import './style.scss'

export function UraEvaluation() {
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [sumicityEvaluation, setSumicityEvaluation] = useState(null)
  const [friendEvaluation, setFriendEvaluation] = useState(null)
  const [solicitation, setSolicitation] = useState(null)
  const { protocolo } = useParams()

  useEffect(() => {
    async function consultProtocol() {
      const response = await getUraProtocol(protocolo)

      if (!response.data.id) {
        setError({
          title: 'Número de protocolo inválido',
          text: 'Verifique o link e tente novamente.',
        })

        setDisabled(true)
      }

      const data = await getUraEvaluationProtocol(protocolo)

      if (data.data.protocolo) {
        dispatch(setModal({ key: 'ura_evaluation_validation', locked: true }))
      }
    }

    consultProtocol()
  }, [dispatch, protocolo])

  const form = useForm({
    onSubmit: handleSubmit,
  })

  function selectSumicityEvaluation(number) {
    setSumicityEvaluation(number)
  }

  function selectFriendEvaluation(number) {
    setFriendEvaluation(number)
  }

  async function handleSubmit() {
    const body = {
      solicitacaoAtendida: solicitation,
      indicariaAmigo: friendEvaluation,
      protocolo: protocolo,
      satisfacao: sumicityEvaluation,
      criacao: new Date(),
    }

    try {
      await sendUraEvaluation(body)

      dispatch(setModal({ key: 'ura_evaluation_sucess', locked: true }))
    } catch (error) {
      setError({
        title: 'Ocorreu um erro',
        text: 'Tente novamente mais tarde.',
      })
    }
  }

  function handleAlertClose() {
    setError(null)
  }

  function setRadioButton(event) {
    setSolicitation(event.target.value)
  }

  return (
    <div className='ura-page'>
      <img className='logo' src={STATICS.LOGO} alt='sumicity logo' />
      {!!error && (
        <Alert
          className='alert'
          severity='error'
          size='large'
          title={error.title}
          text={error.text}
          onClose={handleAlertClose}
        />
      )}
      <form className='form' onSubmit={form.handleSubmit}>
        <h1 className='title'>{EVALUATION_UTILS.FORM.TITLE_FIRST}</h1>
        <h3 className='subtitle'>{EVALUATION_UTILS.FORM.QUESTION_1}</h3>
        <div className='radios'>
          <input type='radio' value='true' name='gender' onClick={event => setRadioButton(event)} />{' '}
          Sim
          <input
            type='radio'
            value='false'
            name='gender'
            onClick={event => setRadioButton(event)}
          />{' '}
          Não
        </div>

        <div className='billet-box'>
          <div className='infos'>{EVALUATION_UTILS.FORM.QUESTION_2}</div>

          <div className='radios-wrapper'>
            {EVALUATION_UTILS.NUMBERS.map(item => (
              <label
                className={`radio-item number-${item.id}`}
                key={item.id}
                htmlFor={`number-${item.id}`}
                onClick={e => selectFriendEvaluation(item.id)}
              >
                <input
                  type='radio'
                  value={item.id}
                  name='number-input'
                  id={`number-${item.id}`}
                  className='input'
                />
                <span className={`label-number number-${item.id}`}>{item.id}</span>
              </label>
            ))}
          </div>
        </div>
        <div className='billet-box'>
          <div className='infos'>{EVALUATION_UTILS.FORM.QUESTION_3}</div>
          <div className='radios-wrapper'>
            {EVALUATION_UTILS.NUMBERS.map(item => (
              <label
                className='radio-item'
                key={item.id}
                htmlFor={`number-${item.id}-second`}
                onClick={e => selectSumicityEvaluation(item.id)}
              >
                <input
                  type='radio'
                  value={item.id}
                  name='second-number-input'
                  id={`number-${item.id}-second`}
                  className='input'
                />
                <span className={`label-number number-${item.id}`}>{item.id}</span>
              </label>
            ))}
          </div>
        </div>
        <Button
          className='button'
          type='submit'
          color='primary'
          size='large'
          isLoading={form.isSubmitting}
          disabled={
            solicitation == null ||
            friendEvaluation == null ||
            sumicityEvaluation == null ||
            disabled
          }
        >
          {EVALUATION_UTILS.FORM.BUTTON}
        </Button>
      </form>
    </div>
  )
}
