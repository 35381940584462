import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getParameter } from 'hooks'
import { FaqInitial, FaqCategory } from './components'
import { FAQ_TEXT_FEEDBACK_PARAMETER_CODE, STAGES } from './constants'

export function FaqDrawer() {
  const [faq, setFaq] = useState([])
  const [category, setCategory] = useState('')
  const [isFeedbackEnabled, setIsFeedbackEnabled] = useState(null)
  const { stage } = useSelector(({ drawer }) => drawer)

  useEffect(() => {
    async function getIsFeedbackEnabled() {
      try {
        const { data } = await getParameter(FAQ_TEXT_FEEDBACK_PARAMETER_CODE)

        setIsFeedbackEnabled(data.active)
      } catch (error) {
        console.error(error)

        setIsFeedbackEnabled(false)
      }
    }

    if (isFeedbackEnabled === null) getIsFeedbackEnabled()
  }, [isFeedbackEnabled])

  return stages({ faq, setFaq, category, setCategory, isFeedbackEnabled })[stage]
}

function stages({ ...props }) {
  return {
    [STAGES.INITIAL]: <FaqInitial {...props} />,
    [STAGES.CATEGORY]: <FaqCategory {...props} />,
  }
}
