import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  PhoneList,
  PhoneAdd,
  PhoneConfirmation,
  PhoneSuccess,
  PhoneDelete,
  PhoneSelectDefault,
} from './components'

export function PhoneDrawer() {
  const [data, setData] = useState({})
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages({ data, setData })[stage]
}

function stages({ ...props }) {
  return {
    initial: <PhoneList {...props} />,
    add: <PhoneAdd {...props} />,
    confirmation: <PhoneConfirmation {...props} />,
    success: <PhoneSuccess {...props} />,
    select_default: <PhoneSelectDefault {...props} />,
    delete: <PhoneDelete {...props} />,
  }
}
