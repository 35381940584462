import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
  SO_OPEN,
  STATUS_CODE,
  VIRTUAL_SUPPORT_STAGES,
} from 'app-constants'
import { Button, Card, RadioButton, ContextLoader } from 'components'
import { getMassiveWarningByUnityAndContract, getServiceOrderRepair, useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { setDrawer, setDrawerAlert, setStage } from 'slices/drawer'
import { isAfter } from 'utils'
import { LOS_STATUS, STATUS } from './constants'
import './style.scss'

export function CheckModemLights({ contractAddressSelected, setVariantWarning }) {
  const dispatch = useDispatch()
  const [modemStatus, setModemStatus] = useState(null)
  const [massiveWarning, setMassiveWarning] = useState(null)
  const [hasServiceOrder, setHasServiceOrder] = useState(false)
  const [loading, setLoading] = useState(true)
  const { sendEvent } = useFirebase()
  const { id: idContract, unity } = contractAddressSelected

  useEffect(() => {
    async function loadServiceOrder() {
      setLoading(true)
      try {
        const { data } = await getServiceOrderRepair({ contractId: idContract })
        const isScheduledAfter = isAfter(data.schedulingDate, new Date())
        const statusOpen = data.status === SO_OPEN

        if (isScheduledAfter && statusOpen) {
          setHasServiceOrder(true)
        }
      } catch (error) {
        const controledErrors =
          error?.response?.status === STATUS_CODE.NOT_FOUND ||
          error?.response?.status === STATUS_CODE.BAD_REQUEST

        if (!controledErrors) {
          const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE

          dispatch(
            setDrawerAlert({
              severity: 'error',
              title: errorMessage,
            })
          )
        }
      } finally {
        setLoading(false)
      }
    }

    async function loadMassiveWarningByUnity() {
      try {
        const { data } = await getMassiveWarningByUnityAndContract({ unity, idContract })

        setMassiveWarning(data)
      } catch (error) {
        const controledError = error?.response?.status === STATUS_CODE.NOT_FOUND

        if (!controledError) {
          const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
          dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
        }
      }
    }

    loadServiceOrder()
    loadMassiveWarningByUnity()
    // TODO lucas.santos: Incluir chamada para getMassiveWarningByOlt quando disponível pelo backend
  }, [dispatch, idContract, hasServiceOrder, unity])

  async function handleContinue() {
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_SELECT_MODEM_PROBLEM)
    if (modemStatus.key !== LOS_STATUS) {
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
    } else if (massiveWarning) {
      setVariantWarning(true)
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.MASSIVE_WARNING))
    } else if (hasServiceOrder) {
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
    } else {
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.SCHEDULING_SERVICE_ORDER))
    }
  }

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function handleModemStatus(status) {
    setModemStatus(status)
  }

  function renderCards() {
    return STATUS.map(item => (
      <Card
        className={`card ${modemStatus?.key === item.key ? '-selected' : ''}`}
        onClick={() => handleModemStatus(item)}
        key={item.key}
      >
        <RadioButton id={item.key} checked={modemStatus?.key === item.key} />
        <div className='card-content'>
          <p className='text-card'>{item.label}</p>
        </div>
      </Card>
    ))
  }

  function renderButtons() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => handleContinue()}
          disabled={!modemStatus}
        >
          Continuar
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          onClick={() => {
            handleClose()
          }}
        >
          Cancelar
        </Button>
      </div>
    )
  }

  if (loading) return <ContextLoader fixed={false} />

  return (
    <div className='virtual-support-info'>
      <div className='content'>
        <div className='round'>
          <STATICS.MODEM className='icon' />
        </div>
        <p className='subtitle'>
          Verifique seu modem e selecione abaixo o que está acontecendo com a sua internet:
        </p>
        {renderCards()}
      </div>
      {renderButtons()}
    </div>
  )
}
