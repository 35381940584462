import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_ERROR_MESSAGE, FIREBASE_EVENTS, SO_TYPES } from 'app-constants'
import { useFirebase } from 'hooks'
import { Button } from 'components/button'
import { BackButton } from 'components/back-button'
import { TrackingButton } from 'components/tracking-button'
import { setDrawer, setDrawerAlert, setStage } from 'slices/drawer'
import { formatDate, formatTurn } from 'utils'
import { ADDRESS_CHANGE_STAGES, BUTTON_CONTENT, BUTTON_THEME } from '../../constants'
import './style.scss'

export function RequestStatus({ data, setServiceOrderInfo }) {
  const dispatch = useDispatch()
  const { schedulingDate, turn, type, isDateAbleToReschedule, technician, serviceOrderInfo } = data
  const idContract = useSelector(state => state.contract.selected.id)
  const { sendEvent } = useFirebase()

  useEffect(() => {
    function loadAddressChangeStatus() {
      try {
        setServiceOrderInfo(serviceOrderInfo)
      } catch (error) {
        console.error(error)
        const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      }
    }

    loadAddressChangeStatus()
  }, [idContract, dispatch, setServiceOrderInfo, serviceOrderInfo])

  function handleClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SERVICE_ORDER_RESCHEDULE)
    dispatch(setStage(ADDRESS_CHANGE_STAGES.RESCHEDULE))
  }

  function handleCancel() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SERVICE_ORDER_CANCEL)
    dispatch(setStage(ADDRESS_CHANGE_STAGES.CANCEL_CONFIRMATION))
  }

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function renderButtons() {
    const osIsFinished = serviceOrderInfo.type === SO_TYPES.FINISHED

    if (osIsFinished) {
      return (
        <Button className='button' color='primary' size='large' onClick={handleClose}>
          Ok, entendi!
        </Button>
      )
    }

    return (
      <>
        <TrackingButton osId={serviceOrderInfo.osId} />
        {isDateAbleToReschedule && (
          <>
            <Button
              className='order-button'
              type='button'
              color='primary'
              size='large'
              outlined
              onClick={handleClick}
            >
              {BUTTON_CONTENT[BUTTON_THEME.RESCHEDULE].TEXT}
            </Button>
            <Button className='button' color='primary' size='large' outlined onClick={handleCancel}>
              {BUTTON_CONTENT[BUTTON_THEME.CANCEL].TEXT}
            </Button>
          </>
        )}
        <BackButton className='back-button' />
      </>
    )
  }

  return (
    <div className='address-change-status'>
      <div className='content'>
        <h3 className='title'>Acompanhe o status da sua solicitação</h3>
        <div className='card'>
          <div className='status'>
            <span>{type}</span>
          </div>
          <span className='label'>
            Solicitação:
            <span className='text'> Mudança de Endereço</span>
          </span>
          <div className='infos'>
            <div className='order-info'>
              <span className='label'>Data de agendamento:</span>
              <span className='text'>{formatDate(schedulingDate)}</span>
            </div>
            <div className='order-shift'>
              <span className='label'>Turno:</span>
              <span className='text'>{formatTurn(turn)}</span>
            </div>
          </div>
          {technician && (
            <span className='label'>
              Técnico:
              <span className='text'> {technician}</span>
            </span>
          )}
        </div>
      </div>

      <div className='buttons'>{renderButtons()}</div>
    </div>
  )
}
