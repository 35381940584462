import React, { useState } from 'react'
import { STATICS } from 'statics'
import { BRAND_DISPLAY_NAME } from 'app-constants'
import { ListBankSlip, ResponseBankSlip, SearchBankSlip } from './components'
import { PAGE_STAGES_KEYS } from './constants'
import './style.scss'

export function FastBillet() {
  const [bankSlipList, setBankSlipList] = useState({})
  const [pageStage, setPageStage] = useState(PAGE_STAGES_KEYS.SEARCH_BANK_SLIP)

  const PAGE_COMPONENT = {
    [PAGE_STAGES_KEYS.SEARCH_BANK_SLIP]: (
      <SearchBankSlip setPageStage={setPageStage} setBankSlipList={setBankSlipList} />
    ),
    [PAGE_STAGES_KEYS.LIST_BANK_SLIP]: <ListBankSlip bankSlipList={bankSlipList} />,
    [PAGE_STAGES_KEYS.RESPONSE_BANK_SLIP]: <ResponseBankSlip setPageStage={setPageStage} />,
  }

  return (
    <div className='fast-billet-page'>
      <img className='logo' src={STATICS.LOGO} alt={`Logo ${BRAND_DISPLAY_NAME}`} />
      {PAGE_COMPONENT[pageStage]}
    </div>
  )
}
