import React, { useState, useEffect } from 'react'
import { getCallRecord, getCallExtract, useFirebase } from 'hooks'
import { formatCurrency, formatDate } from 'utils'
import { FIREBASE_EVENTS } from 'app-constants'
import { useSelector, useDispatch } from 'react-redux'
import { setDrawerAlert } from 'slices/drawer'
import { Card } from 'components/card'
import { Loader } from 'components/loader'
import { ContextLoader } from 'components/context-loader'
import { STATICS } from 'statics'
import { CALLS_EXTRACT_DOWNLOAD_ERROR, CALLS_EXTRACT_ERROR } from '../../constants'
import './style.scss'

export function CallsExtract() {
  const idContract = useSelector(state => state.contract.selected.id)
  const [extracts, setExtracts] = useState([])
  const [loading, setLoading] = useState(true)
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()

  useEffect(() => {
    async function loadCallsExtract() {
      try {
        const response = await getCallRecord(idContract)
        const dataByContractId = response.data.filter(data => data.contractId === idContract)

        const data = dataByContractId.map(item => ({
          ...item,
          formattedInitialDate: formatDate(item.dateInitial),
          formattedFinalDate: formatDate(item.dateFinal),
          formattedPrice: formatCurrency(item.value),
        }))

        setExtracts(data)
      } catch (err) {
        console.error(err)
        dispatch(setDrawerAlert({ severity: 'error', title: CALLS_EXTRACT_ERROR }))
      } finally {
        setLoading(false)
      }
    }

    loadCallsExtract()
  }, [idContract, dispatch])

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.TELEPHONY_CALL_EXTRACT)
  }, [sendEvent])

  async function handleDownloadExtract(id, index) {
    if (extracts[index].downloading) return

    try {
      const extractsObj = [...extracts]

      extractsObj[index].downloading = true
      setExtracts(extractsObj)

      const { data } = await getCallExtract(id)

      window.open(data.urlFile)
    } catch (err) {
      console.error(err)
      dispatch(setDrawerAlert({ severity: 'error', title: CALLS_EXTRACT_DOWNLOAD_ERROR }))
    } finally {
      const extractsObj = [...extracts]

      extractsObj[index].downloading = false
      setExtracts(extractsObj)
    }
  }

  function renderItemIcon(item, index) {
    if (item.downloading)
      return (
        <div className='loader'>
          <Loader className='img' />
        </div>
      )

    return (
      <STATICS.DOWNLOAD_BOX
        className='download'
        onClick={() => handleDownloadExtract(item.id, index)}
      />
    )
  }

  if (loading) return <ContextLoader fixed={false} />

  return (
    <div className='calls-extract-component'>
      <h3 className='title'>Extrato de ligações</h3>
      {extracts.length ? (
        extracts.map((item, index) => (
          <Card className='card' key={String(index)}>
            <div className='period'>
              <p className='title'>Período</p>
              <p className='text'>
                {item.formattedInitialDate} - {item.formattedFinalDate}
              </p>
            </div>
            <div className='total'>
              <p className='title'>Total</p>
              <p className='text'>{item.formattedPrice}</p>
            </div>
            {item.existCallExtract && renderItemIcon(item, index)}
          </Card>
        ))
      ) : (
        <>
          <STATICS.INVOICES_HISTORIC className='icon' />
          <p className='message'>Você ainda não tem extrato de ligações</p>
        </>
      )}
    </div>
  )
}
