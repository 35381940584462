/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDrawerAlert } from 'slices/drawer'
import { STATICS } from 'statics'
import { sendFaqVote } from 'hooks'
import {
  FAQ_VOTE_ERROR,
  FAQ_VOTE_SUCCESS,
  FEEDBACK_MAX_LENGTH,
  OPTIONS,
} from 'components/drawer/templates/faq/constants'
import { Button } from 'components/button'
import './style.scss'

export function Accordion({ title = '', description = '', isFeedbackEnabled, id }) {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState('')
  const [textareaContent, setTextAreaContent] = useState('')
  const [shouldShowFeedback, setShouldShowFeedback] = useState(false)
  const [reviewHeight, setReviewHeight] = useState('0px')
  const [isSendingReview, setIsSendingReview] = useState(false)
  const dispatch = useDispatch()
  const contentHeight = useRef()

  useEffect(() => {
    const height = isOpen ? contentHeight.current.scrollHeight : '0px'

    setReviewHeight(height)
  }, [shouldShowFeedback, isOpen])

  function handleTextareaChange(event) {
    const { value } = event.target

    setTextAreaContent(value)
  }

  async function handleClickVote(isPositive, voteId) {
    setSelected(voteId)

    if (isPositive || !isFeedbackEnabled) {
      sendReview(isPositive)
      setShouldShowFeedback(false)
      setTextAreaContent('')
    } else setShouldShowFeedback(true)
  }

  async function sendReview(isPositive) {
    setIsSendingReview(true)

    try {
      const body = { isPositive }

      if (!isPositive) body.feedback = textareaContent

      await sendFaqVote(id, body)

      setTextAreaContent('')
      dispatch(setDrawerAlert({ severity: 'success', title: FAQ_VOTE_SUCCESS }))
    } catch (error) {
      console.error(error)

      dispatch(setDrawerAlert({ severity: 'error', title: FAQ_VOTE_ERROR }))
    } finally {
      setIsSendingReview(false)
    }
  }

  function renderVotes() {
    return OPTIONS.map(({ Icon, voteId, text, isPositive }) => {
      const isSelected = selected === voteId

      return (
        <button
          id={voteId}
          onClick={() => handleClickVote(isPositive, voteId)}
          type='button'
          className={`vote ${isSelected ? 'selected' : ''}`}
          disabled={!!selected}
          key={voteId}
        >
          <Icon />
          {text}
        </button>
      )
    })
  }

  function renderFeedback() {
    if (!shouldShowFeedback) return null

    return (
      <div className='container-feedback'>
        <span className='title'>Entendi! Conta mais pra gente?</span>
        <div className='container-textarea'>
          <textarea
            onChange={handleTextareaChange}
            maxLength={FEEDBACK_MAX_LENGTH}
            className='textarea'
            placeholder='Digite seu texto aqui (opcional)'
            value={textareaContent}
          />
          <span className='counter'>
            {textareaContent.length}/{FEEDBACK_MAX_LENGTH}
          </span>
        </div>
        <Button
          isLoading={isSendingReview}
          className='button'
          color='primary'
          size='large'
          onClick={() => sendReview(false)}
        >
          Enviar
        </Button>
      </div>
    )
  }

  function renderReview() {
    return (
      <>
        <div className='container-review'>
          <span className='text'>Essa informação foi útil para você?</span>
          <div className='container-votes'>{renderVotes()}</div>
        </div>
        {renderFeedback()}
      </>
    )
  }

  return (
    <div className={`accordion-component ${isOpen && '-open'}`}>
      <button className='header-button' type='button' onClick={() => setIsOpen(!isOpen)}>
        {title}
        <div className='icon'>
          <STATICS.ARROW_DOWN />
        </div>
      </button>
      <div
        className='container-answer body-content'
        ref={contentHeight}
        style={{
          height: reviewHeight,
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: description }} />
        {renderReview()}
      </div>
    </div>
  )
}
