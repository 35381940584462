import { useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { STATICS } from 'statics'
import { Button } from 'components/button'
import './style.scss'

export function UnlockAccountError() {
  const dispatch = useDispatch()

  function handleClose() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='unlock-account-error'>
      <div className='round'>
        <STATICS.CLOSE className='icon' />
      </div>
      <h4 className='title'>Não foi possível realizar o desbloqueio da sua conta.</h4>
      <h5 className='subtitle'>
        Para mais detalhes, entre em contato através do 10353 ou pelo nosso chat.
      </h5>
      <Button type='button' color='primary' size='large' onClick={handleClose}>
        Ok, entendi
      </Button>
    </div>
  )
}
