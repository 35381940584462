import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { ROUTES } from 'app-constants'
import { RouteWrapper } from './route-wrapper'
import { routes } from './routes'

export function Routes() {
  return (
    <Switch>
      {routes.map(route => (
        <RouteWrapper key={route.path} {...route} />
      ))}
      <Redirect to={ROUTES.HOME.PATH} />
    </Switch>
  )
}
