import { useFormik } from 'formik'

export function useForm(config = {}) {
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setErrors,
    setFieldValue,
    isSubmitting,
    setValues,
    setFieldError
  } = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: true,
    ...config,
  })

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    setErrors,
    setFieldValue,
    isSubmitting,
    setValues,
    setFieldError
  }
}
