export const CUSTOMER_INVOICES_INITIAL_STATE = {
  isLoading: true,
  content: [],
  hasMore: false,
  page: 0,
}

export const CUSTOMER_PAID_BILLS_INITIAL_STATE = {
  isLoading: true,
  content: [],
  hasMore: false,
  page: 0,
}
