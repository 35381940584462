export const DISRUPTION_FLAW_INITIAL =
  'Caro cliente, verificamos que sua região está passando por uma anormalidade. 😥\nNossa equipe já está em campo, trabalhando para resolver o quanto antes. 🚀  '

export const DISRUPTION_FLAW_TIME_LEFT = '\n\nA previsão de normalização é de'

export const DISRUPTION_FLAW_FINAL =
  '\n\nEssa é a mesma informação que você receberá em nosso atendimento.\nAgradecemos a compreensão!\n'

export const DISRUPTION_FLAW_INSTRUCTION =
  'Se ainda precisar de ajuda, acesse a opção Suporte Virtual localizada abaixo das notas fiscais.'

export const MASSIVE_MESSAGE_TITLE = 'Comunicado de anormalidade na rede'

export const ENERGY_FLAW =
  'Caro cliente, identificamos uma queda de energia em sua região. ⚡\n Retire o equipamento da tomada, aguarde 5 minutos e ligue novamente. ⏱\n\n Essa é a mesma informação que você receberá em nosso atendimento. Agradecemos a compreensão!'

export const SABOTAGE_FLAW =
  'Caro cliente, informamos que, lamentavelmente, nossa rede em sua região sofreu atos de vandalismo, interrompendo todos os serviços. 😥\n Nossa equipe já está em campo, trabalhando para resolver o quanto antes. 🚀\n\n Essa é a mesma informação que você receberá em nosso atendimento. Agradecemos a compreensão!'
