export const LOS_STATUS = 'ONLY_LOS'

export const STATUS = [
  {
    label: 'Somente a luz de LOS está vermelha',
    key: LOS_STATUS,
  },
  {
    label: 'Somente a luz POWER está acesa',
    key: 'power',
  },
  {
    label: 'Todas as luzes estão apagadas',
    key: 'all_off',
  },
  {
    label: 'Não sei ou não posso verificar agora',
    key: 'cant_verify',
  },
]
