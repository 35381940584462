import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goBack, setModal } from 'slices/modal'
import { STATICS } from 'statics'
import { Card } from 'components/card'
import { ModalTemplate } from './templates'
import './style.scss'

export function Modal() {
  const [isOpen, setIsOpen] = useState(false)
  const { key, locked, history } = useSelector(({ modal }) => modal)
  const dispatch = useDispatch()

  useEffect(() => {
    setIsOpen(!!key)
  }, [key])

  const handleClose = useCallback(() => {
    if (!locked) {
      dispatch(setModal({ key: '' }))
    }
  }, [dispatch, locked])

  const handleReturn = useCallback(() => {
    dispatch(goBack())
  }, [dispatch])

  function getCardClass() {
    const contactUpdateKey = 'contact_update'
    const isContactUpdate = key === contactUpdateKey

    return `card ${isContactUpdate ? 'contactUpdate' : ''}`
  }

  function renderBackButton() {
    return <STATICS.ARROW_BACK className='icon back' onClick={handleReturn} />
  }

  const renderContent = () => (
    <div className={`modal-component ${isOpen ? '-open' : ''}`}>
      <Card className={getCardClass()}>
        {history.length > 0 && renderBackButton()}
        {!locked && <STATICS.CLOSE_WITH_BOX className='icon close' onClick={handleClose} />}
        <div className='modal-content'>{ModalTemplate()[key]}</div>
      </Card>
      <div className='overlay' onClick={handleClose} />
    </div>
  )

  return renderContent()
}
