import { DRAWER_KEY } from './drawer'

export const REDIRECT_QUERY = {
  MODEM: 'redirectWifi',
  NEGOTIATION: 'redirectNegociacao',
  SERVICE_ORDERS: 'redirectOrdensServico',
  UNLOCK_ACCOUNT: 'redirectDesbloqueioConfianca',
  VIRTUAL_SUPPORT: 'redirectSuporteVirtual',
}

export const DEEP_LINKS_DRAWER = [
  { query: REDIRECT_QUERY.MODEM, drawerKey: DRAWER_KEY.MODEM },
  { query: REDIRECT_QUERY.NEGOTIATION, drawerKey: DRAWER_KEY.NEGOTIATION },
  { query: REDIRECT_QUERY.SERVICE_ORDERS, drawerKey: DRAWER_KEY.SERVICE_ORDERS },
  { query: REDIRECT_QUERY.UNLOCK_ACCOUNT, drawerKey: DRAWER_KEY.UNLOCK_ACCOUNT },
  { query: REDIRECT_QUERY.VIRTUAL_SUPPORT, drawerKey: DRAWER_KEY.VIRTUAL_SUPPORT },
]
