import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'components'
import { DOCUMENTS_PAGE_LABELS } from 'app-constants'
import { STATICS } from 'statics'
import { DocumentLine } from './components'
import './style.scss'

export function Documents() {
  const [tab, setTab] = useState(DOCUMENTS_PAGE_LABELS[0].name)

  function renderTab(name) {
    const documentItem =
      DOCUMENTS_PAGE_LABELS.find(pageLabel => pageLabel.name === name) || DOCUMENTS_PAGE_LABELS[0]

    return documentItem.items.map(({ urlFile, name, description, event }, index) => (
      <DocumentLine
        key={index}
        urlFile={urlFile}
        name={name}
        description={description}
        event={event}
      />
    ))
  }

  return (
    <div className='documents-page'>
      <Link className='return' to='/'>
        <div className='icon'>
          <STATICS.ARROW_BACK />
        </div>
        VOLTAR
      </Link>
      <h1 className='title'>Documentos</h1>
      <Card className='card'>
        {DOCUMENTS_PAGE_LABELS.map((item, index) => (
          <div className='tabs' key={index}>
            <button
              className={`tab ${tab === item.name ? '-active' : ''}`}
              onClick={() => setTab(item.name)}
            >
              {item.name}
            </button>
          </div>
        ))}
        {renderTab(tab)}
      </Card>
    </div>
  )
}
