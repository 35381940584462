import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { HASH_SIGMA_CHAT } from 'app-constants'
import { SigmaChatBot } from 'components'

import './style.scss'

export function ChatApp() {
  const { hash } = useParams()
  const { push } = useHistory()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hash === HASH_SIGMA_CHAT) {
      window.SigmaChat.showChat()
      const sigmaContainer = document.querySelector('.sigma-container')
      sigmaContainer.classList.add('chat-app-container')
    } else {
      push('/')
    }
  }, [hash, push])

  return <SigmaChatBot />
}
