import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useForm, changePassword, useFirebase } from 'hooks'
import { Input, Button, PasswordForce } from 'components'
import { clearDrawerAlert, clearHistory, setDrawerAlert, setStage } from 'slices/drawer'
import {
  MESSAGES,
  NEITHER_WHITESPACE_NOR_COMMA_PATTERN,
  EVENTS,
  MINIMUM_PASSWORD_LENGTH,
  DEFAULT_ERROR_MESSAGE,
  ORIGIN,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import './style.scss'

const validationSchema = Yup.object().shape({
  password: Yup.string().required(MESSAGES.REQUIRED),
  newPassword: Yup.string()
    .required(MESSAGES.REQUIRED)
    .min(MINIMUM_PASSWORD_LENGTH, MESSAGES.PASSWORD_MIN_LENGTH)
    .test(
      'newPassword',
      'A senha não pode conter espaços ou vírgulas',
      value => value && NEITHER_WHITESPACE_NOR_COMMA_PATTERN.test(value)
    ),
  confirmationNewPassword: Yup.string()
    .required(MESSAGES.REQUIRED)
    .oneOf([Yup.ref('newPassword'), null], MESSAGES.PASSWORDS_DONT_MATCH),
})

export function PasswordEdit() {
  const {
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { sendEvent } = useFirebase()
  const form = useForm({
    initialValues: {
      password: '',
      newPassword: '',
      confirmationNewPassword: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.INFORM_NEW_SAC_PASSWORD)
  }, [sendEvent])

  useEffect(() => {
    const errors = [...Object.values(form.errors)]
    setError(errors[0])
  }, [form.errors])

  async function handleSubmit(values) {
    setIsLoading(true)

    try {
      await changePassword({
        ...values,
        brand: ORIGIN,
      })

      sendEvent(FIREBASE_EVENTS.CHANGE_SAC_PASSWORD_SUCCESS, EVENT_STATUS.SUCCESS)
      dispatch(setStage('success'))
      dispatch(clearDrawerAlert())
      dispatch(clearHistory())
    } catch (error) {
      const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE

      sendEvent(
        FIREBASE_EVENTS.CHANGE_SAC_PASSWORD_ERROR,
        EVENT_STATUS.ERROR,
        error?.response?.status
      )
      dispatch(setDrawerAlert({ title: errorMessage, severity: 'error' }))
      setIsLoading(false)
    }
  }

  return (
    <form className='password-edit' onSubmit={form.handleSubmit}>
      <h3 className='title'>Altere sua senha</h3>
      <p className='text'>
        Deseja alterar sua senha de acesso? Informe sua senha atual e cadastre a nova
      </p>
      <p className='label'>Informe sua senha atual</p>
      <Input
        className='input'
        name='password'
        label='Senha atual'
        type='password'
        maxLength='20'
        value={form.values.password}
        onChange={form.handleChange}
        hasError={form.errors.password}
        dataCy='input-current-password'
      />
      <p className='label'>Informe sua nova senha</p>
      <Input
        className='input'
        name='newPassword'
        label='Nova senha'
        type='password'
        maxLength='20'
        value={form.values.newPassword}
        onChange={form.handleChange}
        hasError={form.errors.newPassword}
        dataCy='input-new-password'
      />
      <PasswordForce password={form.values.newPassword} />
      <p className='label'>Repetir nova senha</p>
      <Input
        className='input'
        name='confirmationNewPassword'
        label='Nova senha'
        type='password'
        maxLength='20'
        value={form.values.confirmationNewPassword}
        onChange={form.handleChange}
        hasError={form.errors.confirmationNewPassword}
        dataCy='input-confirmation-new-password'
      />
      {!!error && <p className='error'>{error}</p>}
      <div className='button-container'>
        <Button
          className='button'
          color='primary'
          size='large'
          type='submit'
          isLoading={isLoading}
          dataCy='button-change-password'
        >
          Alterar senha
        </Button>
      </div>
    </form>
  )
}
