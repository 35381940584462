import { useEffect, useState } from 'react'
import { isRemindPasswordLaterStorage, setRemindPasswordLaterStorage } from 'utils'

function useRemindPasswordLater() {
  const [isRemindPasswordLater, setIsRemindPasswordLater] = useState(false)

  useEffect(() => {
    if (isRemindPasswordLaterStorage()) {
      setIsRemindPasswordLater(true)
    }
  }, [])

  function setRemindPasswordLater(value) {
    setRemindPasswordLaterStorage(value)
    setIsRemindPasswordLater(value)
  }

  return { isRemindPasswordLater, setRemindPasswordLater }
}

export { useRemindPasswordLater }
