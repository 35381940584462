export const PASSWORD_FORCES = {
  WEAK: {
    KEY: 'weak',
    TRANSLATE: 'Fraca',
  },
  MEDIUM: {
    KEY: 'medium',
    TRANSLATE: 'Média',
  },
  STRONG: {
    KEY: 'strong',
    TRANSLATE: 'Forte',
  },
  EMPTY: {
    KEY: 'empty',
    TRANSLATE: 'Fraca',
  },
}

export const PASSWORD_BARS = [
  {
    activeKeys: [PASSWORD_FORCES.WEAK.KEY, PASSWORD_FORCES.MEDIUM.KEY, PASSWORD_FORCES.STRONG.KEY],
  },
  { activeKeys: [PASSWORD_FORCES.MEDIUM.KEY, PASSWORD_FORCES.STRONG.KEY] },
  { activeKeys: [PASSWORD_FORCES.STRONG.KEY] },
]

export const MINIMUM_PASSWORD_LENGTH = 6

export const MAXIMUM_PASSWORD_LENGTH = 20
