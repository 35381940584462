import React from 'react'
import { useSelector } from 'react-redux'
import { MyServices } from './components'

export function MyServicesDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages()[stage]
}

function stages() {
  return {
    initial: <MyServices />,
  }
}
