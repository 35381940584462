import React from 'react'
import { toast } from 'react-toastify'
import './style.scss'
import 'react-toastify/dist/ReactToastify.css'
import { STATICS } from 'statics'

export function useToaster() {
  toast.configure()

  function Content({ message, Icon }) {
    return (
      <div>
        <Icon />
        {message}
      </div>
    )
  }

  function toastTrigger(message, Icon, toastType) {
    toast(<Content message={message} Icon={Icon} />, { className: `toast toast__${toastType}` })
  }

  function showSuccessToast(message) {
    toastTrigger(message, STATICS.SUCCESS, 'success')
  }

  function showErrorToast(message) {
    toastTrigger(message, STATICS.ERROR, 'error')
  }

  function showWarnToast(message) {
    toastTrigger(message, STATICS.INFO, 'warn')
  }

  function showInfoToast(message) {
    toastTrigger(message, STATICS.INFO, 'info')
  }

  return { showSuccessToast, showErrorToast, showWarnToast, showInfoToast }
}
