import { ROUTES } from 'app-constants'
import {
  Dashboard,
  MyBills,
  Documents,
  TechnicalSupport,
  PasswordRecoveryDocument,
  EmailPasswordReset,
  UraEvaluation,
  PasswordReset,
  ChatApp,
  ChatAppVip,
  ChatAppNiu,
  ChatAppMob,
  PasswordValidateToken,
  FastBillet,
  FirstAccessDocument,
  FirstAccessValidateToken,
  FirstAccessPassword,
  PasswordResetRequired,
} from 'pages'

export const RESET_PASSWORD_PAGE_PATH = '/redefinir-senha'

export const routes = [
  {
    path: '/',
    privateRoute: true,
    exact: true,
    component: Dashboard,
  },
  {
    path: '/suporte-tecnico',
    privateRoute: true,
    exact: true,
    component: TechnicalSupport,
  },
  {
    path: '/minhas-faturas',
    privateRoute: true,
    exact: true,
    component: MyBills,
  },
  {
    path: '/documentos',
    privateRoute: true,
    exact: true,
    component: Documents,
  },
  {
    path: ROUTES.PASSWORD_RECOVERY.PATH,
    privateRoute: false,
    exact: true,
    component: EmailPasswordReset,
  },
  {
    path: ROUTES.FORGOT_PASSWORD.PATH,
    privateRoute: false,
    exact: true,
    component: PasswordRecoveryDocument,
  },
  {
    path: '/ura-evaluation/:protocolo',
    privateRoute: false,
    exact: true,
    component: UraEvaluation,
  },
  {
    path: RESET_PASSWORD_PAGE_PATH,
    privateRoute: true,
    exact: true,
    ignoreSelectContract: true,
    component: PasswordReset,
  },
  {
    path: '/chat-app/:hash',
    privateRoute: false,
    exact: true,
    component: ChatApp,
  },
  {
    path: '/chat-app-vip/:hash',
    privateRoute: false,
    exact: true,
    component: ChatAppVip,
  },
  {
    path: '/chat-app-niu/:hash',
    privateRoute: false,
    exact: true,
    component: ChatAppNiu,
  },
  {
    path: '/chat-app-mob/:hash',
    privateRoute: false,
    exact: true,
    component: ChatAppMob,
  },
  {
    path: ROUTES.VALIDATE_TOKEN.PATH,
    privateRoute: false,
    exact: true,
    component: PasswordValidateToken,
  },
  {
    path: '/boleto-rapido',
    privateRoute: false,
    exact: true,
    component: FastBillet,
  },
  {
    path: ROUTES.FIRST_ACCESS.PATH,
    privateRoute: false,
    exact: true,
    component: FirstAccessDocument,
  },
  {
    path: ROUTES.REQUIRED_PASSWORD_UPDATE.PATH,
    privateRoute: true,
    exact: true,
    ignoreSelectContract: true,
    component: PasswordResetRequired,
  },
  {
    path: ROUTES.FIRST_ACCESS_TOKEN.PATH,
    privateRoute: false,
    exact: true,
    component: FirstAccessValidateToken,
  },
  {
    path: ROUTES.FIRST_ACCESS_PASSWORD.PATH,
    privateRoute: false,
    exact: true,
    component: FirstAccessPassword,
  },
]
