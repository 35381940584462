import { CONTACT_UPDATE_STAGES } from './stages'

export const CONFIRMATION_INPUT_PLACEHOLDER = {
  [CONTACT_UPDATE_STAGES.INITIAL]: 'Código recebido por telefone',
  [CONTACT_UPDATE_STAGES.EMAIL]: 'Código recebido por e-mail',
}

export const CONFIRMATION_DESCRIPTION = {
  [CONTACT_UPDATE_STAGES.INITIAL]:
    'E ai?! Recebeu o código? Agora, informe os números recebidos por sms e em seguida clique em confirmar código!',
  [CONTACT_UPDATE_STAGES.EMAIL]:
    'Agora, informe o código recebido por e-mail em seguida clique em confirmar código.',
}
