import { useEffect } from 'react'
import { BRAND_DISPLAY_NAME, SIGMA_CHATBOT_COLORS, SIGMA_CHATBOT_ICON } from 'app-constants'

export function SigmaChatBot() {
  useEffect(() => {
    // eslint-disable-next-line no-new, no-undef
    new SigmaChat({
      id: 'sumicity',
      icon: {
        src: SIGMA_CHATBOT_ICON,
        height: '50px',
      },
      headline: 'Bem-vindo',
      autoMessage: 'Olá',
      links: [],
      drawFAB: false,
      phone: {
        code: '+55',
        pattern: '\\([0-9]{2}\\)[0-9]{4,5}-[0-9]{4}',
        title: 'Formato válido: (99)9999-9999 | (99)99999-9999',
        maskOptions: { mask: [{ mask: '(00)0000-0000' }, { mask: '(00)00000-0000' }] },
      },
      fields: [],
      lang: {
        name: 'Nome',
        namePH: 'Digite seu nome',
        email: 'Email',
        emailPH: 'Digite seu email',
        phone: 'Telefone',
        phonePH: 'Digite seu telefone',
        submit: 'Enviar',
        message: 'Mensagem',
        messagePH: 'Digite sua mensagem aqui...',
        download: 'Baixar',
        assistance: BRAND_DISPLAY_NAME,
      },
    })

    document.documentElement.style.setProperty(
      '--sigma-client-color',
      SIGMA_CHATBOT_COLORS.CLIENT_COLOR
    )
    document.documentElement.style.setProperty(
      '--sigma-assistent-color',
      SIGMA_CHATBOT_COLORS.ASSISTENT_COLOR
    )
    document.documentElement.style.setProperty(
      '--sigma-start-chat-color',
      SIGMA_CHATBOT_COLORS.START_COLOR
    )
    document.documentElement.style.setProperty(
      '--sigma-start-chat-hover-color',
      SIGMA_CHATBOT_COLORS.START_HOVER_COLOR
    )
    document.documentElement.style.setProperty(
      '--sigma-background-chat',
      SIGMA_CHATBOT_COLORS.BACKGROUND_COLOR
    )
  }, [])

  return null
}
