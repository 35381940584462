import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { formatCnpj, formatCpf, isValidDocumentLength } from 'utils'
import { MESSAGES, CPF_LENGTH_WITHOUT_MASK, DOCUMENT_FIELD_NAME } from 'app-constants'
import { getFastBilletBankSlips, useForm } from 'hooks'
import { Alert, Button, Input } from 'components'
import { PAGE_STAGES_KEYS } from '../../constants'
import './style.scss'

const validationSchema = Yup.object().shape({
  [DOCUMENT_FIELD_NAME]: Yup.string()
    .required(MESSAGES.REQUIRED)
    .test(DOCUMENT_FIELD_NAME, MESSAGES.INVALID_DOCUMENT, (value = '') =>
      isValidDocumentLength(value)
    ),
})

export function SearchBankSlip({ setPageStage, setBankSlipList }) {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState('')

  const form = useForm({
    initialValues: {
      [DOCUMENT_FIELD_NAME]: '',
    },
    validationSchema,
    onSubmit: handleSearchBankSlip,
  })

  useEffect(() => {
    const errors = [...Object.values(form.errors)]
    if (errors[0]) {
      setError({ title: errors[0] })
    }
  }, [form.errors])

  function formatDocument(document) {
    const unmasked = document.replace(/\D/g, '')

    return unmasked.length > CPF_LENGTH_WITHOUT_MASK ? formatCnpj(unmasked) : formatCpf(unmasked)
  }

  async function handleSearchBankSlip(values) {
    try {
      setIsLoading(true)
      const { document } = values
      const unmaskedDocument = document.replace(/\D/g, '')
      const { data } = await getFastBilletBankSlips(unmaskedDocument)

      setBankSlipList(data)
      const nextStep = data?.boletos?.length
        ? PAGE_STAGES_KEYS.LIST_BANK_SLIP
        : PAGE_STAGES_KEYS.RESPONSE_BANK_SLIP
      setPageStage(nextStep)
    } catch (err) {
      console.error(err)
      setError({
        title: 'Ocorreu um erro ao buscar sua lista de faturas.',
        text: 'Verifique o documento inserido ou tente novamente mais tarde.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  function handleAlertClose() {
    setError(null)
  }

  return (
    <form className='search-ticket' onSubmit={form.handleSubmit}>
      <h3>Boleto Rápido</h3>
      <p className='text'>Insira seu CPF/CNPJ pra consultar seus boletos</p>
      {!!error && (
        <Alert
          className='alert'
          severity='error'
          size='large'
          title={error.title}
          text={error.text}
          onClose={handleAlertClose}
        />
      )}
      <Input
        className='input'
        name={DOCUMENT_FIELD_NAME}
        label='CPF ou CNPJ'
        maxLength='18'
        value={formatDocument(form.values[DOCUMENT_FIELD_NAME])}
        onChange={form.handleChange}
        hasError={form.errors[DOCUMENT_FIELD_NAME]}
      />
      <Button className='button' color='primary' type='submit' isLoading={isLoading}>
        Buscar Boletos
      </Button>
      <div className='alert-content'>
        <p className='text'>
          Caso não receba, atualize e confirme seu e-mail e telefone na{' '}
          <a href='/'>Central do Assinante</a>
        </p>
      </div>
    </form>
  )
}
