import React from 'react'
import { Alert } from '../alert'

export function PasswordAlert({ error, onClose }) {
  return (
    !!error && (
      <Alert
        className='alert'
        severity='error'
        size='large'
        title={error.title}
        text={error.text}
        onClose={onClose}
      />
    )
  )
}
