const COLOR_OPTION = {
  SUCCESS: '#4FD600',
  ERROR: '#FF6969',
  NOT_FOUND: '#ACACAC',
  WARNING: '#FFAB5E',
}

const LABEL_OPTION = {
  CONNECTION_AVAILABLE: 'Conectado',
  NO_CONNECTION: 'Sem conexão',
  SUSPENDED: 'Suspenso por débito',
  ENERGY_FAILURE: 'Falha de energia elétrica',
  DEVICE_NOT_IDENTIFIED: 'Equipamento não identificado',
  ENERGY_BLACKOUT: 'Queda de energia',
}

export const SUSPENDED_CONTRACT_STATUS = {
  COLOR: COLOR_OPTION.ERROR,
  LABEL: LABEL_OPTION.SUSPENDED,
}

export const CONNECTION_STATUS = {
  ONLINE: {
    COLOR: COLOR_OPTION.SUCCESS,
    LABEL: LABEL_OPTION.CONNECTION_AVAILABLE,
  },
  OFFLINE: {
    COLOR: COLOR_OPTION.ERROR,
    LABEL: LABEL_OPTION.NO_CONNECTION,
  },
  SUSPENDED: {
    COLOR: COLOR_OPTION.ERROR,
    LABEL: LABEL_OPTION.SUSPENDED,
  },
  ENERGY_FAILURE: {
    COLOR: COLOR_OPTION.WARNING,
    LABEL: LABEL_OPTION.ENERGY_FAILURE,
  },
  NO_ENERGY: {
    COLOR: COLOR_OPTION.WARNING,
    LABEL: LABEL_OPTION.ENERGY_FAILURE,
  },
  ENERGY_BLACKOUT: {
    COLOR: COLOR_OPTION.WARNING,
    LABEL: LABEL_OPTION.ENERGY_BLACKOUT,
  },
  ENERGY_LOSS: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  UNKNOWN_EQUIPMENT: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  COMMUNICATION_FAILURE: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  BAD_SIGNAL: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  SIGNAL_DYING: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
  UNKNOWN: {
    COLOR: COLOR_OPTION.NOT_FOUND,
    LABEL: LABEL_OPTION.DEVICE_NOT_IDENTIFIED,
  },
}
