import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ContextLoader } from 'components'
import { requestBooking, useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { setDrawer, setStage } from 'slices/drawer'
import { formatDate, toISO } from 'utils'
import { EVENT_STATUS, FIREBASE_EVENTS, SCHEDULING_SHIFT_LABEL } from 'app-constants'
import { ADDITIONAL_INFO, ADDRESS_CHANGE_STAGES } from '../../constants'
import './style.scss'

export function AddressChangeConfirmation({ data }) {
  const [isLoading, setIsLoading] = useState(false)
  const contractId = useSelector(state => state.contract.selected.id)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const {
    zipcode,
    city,
    complement,
    date,
    neighborhood: district,
    number,
    street,
    shift,
    timeFrames,
    bucket,
    firstAvailableDate,
    workzone,
    unity,
  } = data

  async function handleClickRequestButton() {
    try {
      setIsLoading(true)
      const { ibgeCodeCity, addressType: transmissionType, preReservationUUID, codeBooking } = data
      const body = {
        cep: zipcode,
        city,
        code: codeBooking,
        complement,
        contractId,
        date: toISO(date),
        district,
        ibgeCodeCity,
        preReservationUUID,
        street,
        transmissionType,
        shift,
        number,
        initialTimeFrame: timeFrames?.initialTimeFrame,
        finalTimeFrame: timeFrames?.finalTimeFrame,
        workzone,
        bucket,
        firstAvailableDate,
        unity,
      }

      await requestBooking(body)
      sendEvent(FIREBASE_EVENTS.ADDRESS_CHANGE_CONFIRMATION)
      dispatch(setStage(ADDRESS_CHANGE_STAGES.SUCCESS))
    } catch (error) {
      console.error(error)
      sendEvent(FIREBASE_EVENTS.ADDRESS_CHANGE_CONFIRMATION, EVENT_STATUS.ERROR)
      dispatch(setStage(ADDRESS_CHANGE_STAGES.ERROR))
    } finally {
      setIsLoading(false)
    }
  }

  function handleClickCancelButton() {
    dispatch(setDrawer(''))
  }

  function renderAdditionalInfo() {
    return ADDITIONAL_INFO.map(({ key, warning, content }) => (
      <div key={key} className='additional-info-container'>
        <STATICS.INFO_OUTLINE className={`icon ${warning ? '-red' : '-primary'}`} />
        <p className={warning ? 'warning' : undefined}>{content}</p>
      </div>
    ))
  }

  if (isLoading) return <ContextLoader fixed={false} />

  return (
    <div className='address-change-request-summary-component'>
      <h2 className='title'>Resumo da sua solicitação</h2>
      <div className='summary-container'>
        <p className='request-text'>
          Você solicitou uma visita técnica para instalação da sua internet em um novo endereço.
        </p>
        <h3 className='new-address'>Novo Endereço:</h3>
        <p>CEP: {zipcode}</p>
        <p>Endereço: {street}</p>
        <p>Número: {number}</p>
        {complement && <p>Complemento: {complement}</p>}
        <p>Bairro: {district}</p>
        <p>Cidade: {city}</p>
        <div className='booking-data-container'>
          <div>
            <strong className='booking-data-label'>Data de agendamento:</strong>
            <p>{formatDate(date)}</p>
          </div>
          <div className='shift-container'>
            <strong className='booking-data-label'>Turno:</strong>
            <p>
              {SCHEDULING_SHIFT_LABEL[shift]} {timeFrames?.initialTimeFrame} -{' '}
              {timeFrames?.finalTimeFrame}
            </p>
          </div>
        </div>
        {renderAdditionalInfo()}
      </div>
      <Button
        onClick={handleClickRequestButton}
        className='request-button'
        type='button'
        color='primary'
      >
        Solicitar
      </Button>
      <Button onClick={handleClickCancelButton} type='button' isLoading={isLoading}>
        Cancelar
      </Button>
    </div>
  )
}
