import React from 'react'
import { useSelector } from 'react-redux'
import { DueDate } from './components'
import { DueDateDisabled } from './components/disabled'
import { DueDateSuccess } from './components/success'

export function DueDateDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages()[stage]
}
function stages() {
  return {
    initial: <DueDate />,
    success: <DueDateSuccess />,
    disabled: <DueDateDisabled />,
  }
}
