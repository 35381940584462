import { STATICS } from 'statics'
import { Card } from '../card'
import './style.scss'

export function CardButton({ className, icon, onClick, children }) {
  return (
    <Card className={`card-button-component ${className}`} thin onClick={onClick}>
      <div className='icon'>{icon}</div>
      {children}
      <div className='arrow-icon'>
        <STATICS.ARROW_CARD_BUTTON />
      </div>
    </Card>
  )
}
