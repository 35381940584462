import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/button'
import { setDrawer } from 'slices/drawer'
import { STATICS } from 'statics'
import { setTimer } from 'slices/wifi'
import { CountdownTime } from 'components/countdown-time'
import './style.scss'

export function ModemSuccess() {
  const dispatch = useDispatch()
  const { countdownTimer, inProgress } = useSelector(({ wifi }) => ({ ...wifi }))

  useEffect(() => {
    dispatch(setTimer())
  }, [dispatch])

  function handleClick() {
    dispatch(setDrawer(''))
  }

  function renderCounter() {
    return (
      <div className='countdown-timer'>
        Restam <CountdownTime time={countdownTimer} /> Segundos
      </div>
    )
  }

  return (
    <div className='modem-success'>
      <div className='success'>
        <div className='icon'>
          <STATICS.DONE />
        </div>
      </div>
      <h3 className='title'>Solicitação enviada com sucesso!</h3>
      <p className='text'>
        Estamos processando a sua solicitação. <strong>Dentro de 2 minutos</strong>, você receberá a
        confirmação da alteração da sua senha.
      </p>
      <p className='text'>
        Aguarde a confirmação antes de atualizar os dados nos dispositivos conectados à sua rede.
      </p>
      {inProgress && renderCounter()}
      <Button className='button' color='primary' size='large' onClick={handleClick}>
        Ok, entendi!
      </Button>
    </div>
  )
}
