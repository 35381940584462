import {
  differenceInMonths,
  format,
  formatISO,
  parseISO,
  subDays,
  addDays as addDaysFns,
  isBefore as isBeforeFns,
  isAfter as isAfterFns,
  differenceInDays,
  parse,
  isMatch,
  intervalToDuration,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { DATE_FORMAT } from 'app-constants'

export function formatDate(date, pattern = 'dd/MM/yyyy') {
  if (!date) return ''

  let result = date

  if (typeof date === 'string') {
    result = parseISO(date)
  }

  return format(result, pattern, { locale: ptBR })
}

export function toISO(date) {
  if (!date) return ''

  let result = date
  if (typeof date === 'string') {
    result = parse(date, 'dd/MM/yyyy', new Date())
  }

  return formatISO(result, { representation: 'date' })
}

export function convertISOStringToDate(date) {
  if (!date || typeof date !== 'string' || !isMatch(date, DATE_FORMAT.ISO)) return ''
  return parseISO(date)
}

export function addDays(date, amount) {
  return addDaysFns(date, amount)
}

export function subtractDays(date, amount) {
  return subDays(date, amount)
}

export function diffInMonths(laterDate, earlierDate) {
  let resultLaterDate = laterDate
  let resultEarlierDate = earlierDate

  if (typeof laterDate === 'string') {
    resultLaterDate = parseISO(laterDate)
  }

  if (typeof earlierDate === 'string') {
    resultEarlierDate = parseISO(earlierDate)
  }

  return differenceInMonths(resultLaterDate, resultEarlierDate)
}

export function isBefore(date, dateToCompare) {
  let resultDate = date
  let resultDateToCompare = dateToCompare

  if (typeof date === 'string') {
    resultDate = parseISO(date)
  }

  if (typeof dateToCompare === 'string') {
    resultDateToCompare = parseISO(dateToCompare)
  }

  return isBeforeFns(resultDate, resultDateToCompare)
}

export function isAfter(date, dateToCompare) {
  let resultDate = date
  let resultDateToCompare = dateToCompare

  if (typeof date === 'string') {
    resultDate = parseISO(date)
  }

  if (typeof dateToCompare === 'string') {
    resultDateToCompare = parseISO(dateToCompare)
  }

  return isAfterFns(resultDate, resultDateToCompare)
}

export function diffInDays(laterDate, earlierDate) {
  let resultLaterDate = laterDate
  let resultEarlierDate = earlierDate

  if (typeof laterDate === 'string') {
    resultLaterDate = parseISO(laterDate)
  }

  if (typeof earlierDate === 'string') {
    resultEarlierDate = parseISO(earlierDate)
  }

  return differenceInDays(resultLaterDate, resultEarlierDate)
}

export function formatTurn(turn) {
  return turn === 'MANHA' || turn === 'Manhã' ? 'Manhã (08h às 12h)' : 'Tarde (13h às 18h)'
}

export function formatDateTime(date) {
  return format(date, DATE_FORMAT.DATE_TIME, { locale: ptBR })
}

export function currentInterval(initialDate) {
  return intervalToDuration({
    start: parseISO(initialDate),
    end: new Date(),
  })
}
