import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getFaqs, useFirebase } from 'hooks'
import { setDrawerAlert, setStage } from 'slices/drawer'
import { Input } from 'components/input'
import { STATICS } from 'statics'
import { FIREBASE_EVENTS, ORIGIN } from 'app-constants'
import { Accordion } from '../accordion'
import {
  FAQ_CATEGORIES,
  FAQ_CATEGORIES_ICONS,
  FAQ_CATEGORIES_LABELS,
  GET_FAQ_ERROR_MESSAGE,
  STAGES,
} from '../../constants'
import './style.scss'

export function FaqInitial({ faq, setFaq, setCategory, isFeedbackEnabled }) {
  const [searchFilter, setSearchFilter] = useState('')
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getFaqs({ brand: ORIGIN })
        setFaq(data)
      } catch (error) {
        const errorMessage = error?.response?.data?.message || GET_FAQ_ERROR_MESSAGE

        console.error(error)
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      }
    }

    fetchData()
  }, [setFaq, dispatch])

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.FAQ_CATEGORIES_VIEW)
  }, [sendEvent])

  function handleCategoryClick(category) {
    setCategory(category)
    dispatch(setStage(STAGES.CATEGORY))
  }

  function handleSearchChange({ target }) {
    setSearchFilter(target.value)
  }

  function renderFilteredFaq() {
    return faq
      .filter(
        ({ question, answer }) => question.includes(searchFilter) || answer.includes(searchFilter)
      )
      .map(({ question, answer }) => (
        <Accordion isFeedbackEnabled={isFeedbackEnabled} title={question} description={answer} />
      ))
  }

  function renderCategories() {
    const categoryList = Object.values(FAQ_CATEGORIES)
    const categoriesWithQuestions = categoryList.filter(category =>
      faq.some(({ category: questionCategory }) => questionCategory === category)
    )

    return (
      <>
        <p className='subtitle'>Assuntos mais buscados</p>
        <div className='categories-container'>
          {categoriesWithQuestions.map(category => (
            <button
              key={category}
              type='button'
              className='category-button'
              onClick={() => handleCategoryClick(category)}
            >
              <div className='icon-container'>{FAQ_CATEGORIES_ICONS[category]}</div>
              {FAQ_CATEGORIES_LABELS[category]}
            </button>
          ))}
        </div>
      </>
    )
  }

  function renderSearchInput() {
    return (
      <div className='search-input-container'>
        <Input
          name='search'
          label='Digite uma palavra chave'
          className='input'
          value={searchFilter}
          onChange={handleSearchChange}
        />
        <div className='icon'>
          <STATICS.SEARCH />
        </div>
      </div>
    )
  }

  return (
    <div className='faq-initial-component'>
      <h3 className='title'>Perguntas Frequentes</h3>
      <strong className='subtitle'>Qual a sua dúvida?</strong>
      {renderSearchInput()}
      {searchFilter ? renderFilteredFaq() : renderCategories()}
    </div>
  )
}
