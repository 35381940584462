import React from 'react'
import { Link } from 'react-router-dom'
import { STATICS } from 'statics'
import './style.scss'

export function MenuButton({ children, className, onClick, href, to, dataCy }) {
  function renderContent() {
    return (
      <>
        {children}
        <div className='icon'>
          <STATICS.ARROW_RIGHT />
        </div>
      </>
    )
  }

  function renderAsButton() {
    return (
      <button
        type='button'
        className={`menu-button-partial ${className}`}
        onClick={onClick}
        data-cy={dataCy}
      >
        {renderContent()}
      </button>
    )
  }

  function renderAsLink() {
    return (
      <Link
        className={`menu-button-partial ${className}`}
        to={to}
        onClick={onClick}
        data-cy={dataCy}
      >
        {renderContent()}
      </Link>
    )
  }

  function renderAsAnchor() {
    return (
      <a
        className={`menu-button-partial ${className}`}
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        onClick={onClick}
        data-cy={dataCy}
      >
        {renderContent()}
      </a>
    )
  }

  function renderComponent() {
    if (href) {
      return renderAsAnchor()
    }
    if (to) {
      return renderAsLink()
    }
    return renderAsButton()
  }

  return renderComponent()
}
