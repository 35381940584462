import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/button'
import { setModal } from 'slices/modal'
import './style.scss'

export function DaysToBreachAgreementModal() {
  const { data } = useSelector(({ modal }) => modal)
  const dispatch = useDispatch()

  function handleClose() {
    dispatch(setModal({ key: '' }))
  }

  return (
    <div className='days-to-breach-agreement-modal'>
      <h3 className='title'>Negociação de faturas</h3>
      <p className='info'>
        Esta negociação gerou um novo boleto e deve ser pago até o próximo dia útil.
        <br />
        Caso o pagamento não seja identificado em até <strong>{data.days} dias</strong> após o
        vencimento, sua negociação será cancelada e suas faturas voltarão a ser cobradas.
      </p>
      <Button onClick={handleClose} color='primary' className='button'>
        Ok, entendi!
      </Button>
    </div>
  )
}
