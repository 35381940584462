import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Button } from 'components'
import { STATICS } from 'statics'
import './style.scss'

export function PasswordSuccess() {
  const [keycloak] = useKeycloak()

  function handleClick() {
    localStorage.removeItem('SUMICITY_SESSION_TIME')
    keycloak.logout()
  }

  return (
    <div className='password-success'>
      <div className='success'>
        <div className='icon'>
          <STATICS.DONE />
        </div>
      </div>
      <h3 className='title'>Sua senha foi alterada com sucesso!</h3>
      <p className='text'>Faça login novamente com a nova senha cadastrada</p>
      <Button className='button' color='primary' size='large' onClick={handleClick}>
        Fazer login
      </Button>
    </div>
  )
}
