import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { ptBR } from 'date-fns/locale'
import ReactInputMask from 'react-input-mask'
import { DATE_FORMAT, SCREEN_TABLET } from 'app-constants'
import { STATICS } from 'statics'
import 'react-datepicker/dist/react-datepicker.css'
import './style.scss'

registerLocale('ptBR', ptBR)

export function CustomDatePicker({
  name,
  id,
  dataCy,
  startDate,
  availableDates,
  onChange,
  selectedDate,
  placeholderText = 'Selecione o dia da instalação',
  onMonthChange,
  onYearChange,
  showMonthDropdown = false,
  showYearDropdown = false,
}) {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < SCREEN_TABLET)
    }

    window.onresize = handleResize
    setIsMobile(window.innerWidth < SCREEN_TABLET)
  }, [])

  return (
    <fieldset className={`custom-date-picker-component ${selectedDate ? 'active' : ''}`}>
      <DatePicker
        locale='ptBR'
        className={`input ${selectedDate ? 'selected' : ''}`}
        selected={selectedDate}
        onChange={onChange}
        dateFormat={DATE_FORMAT.SHORT}
        includeDates={availableDates}
        minDate={startDate}
        withPortal={isMobile}
        placeholderText={placeholderText}
        customInput={<ReactInputMask type='text' mask='99/99/9999' data-cy={dataCy || id} />}
        name={name}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode='select'
        id={id}
        autoComplete='off'
        onMonthChange={onMonthChange}
        onYearChange={onYearChange}
      />
      <STATICS.CALENDAR className='icon-calendar' />
    </fieldset>
  )
}
