import { MINIMUM_PASSWORD_LENGTH, MAXIMUM_PASSWORD_LENGTH } from './password'

export const MESSAGES = {
  REQUIRED: 'Campos obrigatórios',
  PASSWORD_MIN_LENGTH: `Deve conter no mínimo ${MINIMUM_PASSWORD_LENGTH} caracteres`,
  PASSWORD_MAX_LENGTH: `Deve conter no máximo  ${MAXIMUM_PASSWORD_LENGTH} caracteres`,
  PASSWORD_CHARS: 'A senha deve conter ao menos uma letra e um número',
  PASSWORD_INVALID_CHARS: 'A senha não pode conter espaços e vírgulas',
  PASSWORDS_DONT_MATCH: 'Senhas não coincidem',
  INVALID_DOCUMENT: 'Documento inválido',
  SAME_EMAIL: 'Por favor, cadastre um e-mail diferente do atual',
  OMBUDSMAN_ERROR_MESSAGE:
    'Ocorreu um erro ao enviar sua solicitação, verifique suas informações de contato',
}
