import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { STATICS } from 'statics'
import { useFirebase } from 'hooks'
import { Button, Card } from 'components'
import { REGISTRATION_DATA_STAGES, CONTACT_TYPE, FIREBASE_EVENTS } from 'app-constants'
import './style.scss'

export function EmailList({ setData }) {
  const [defaultEmail, setDefaultEmail] = useState({})
  const [emailsList, setEmailsList] = useState([])
  const { sendEvent } = useFirebase()
  const {
    info: { contacts },
  } = useSelector(state => state.customer)
  const dispatch = useDispatch()

  useEffect(() => {
    function manipulateContacts() {
      const emailsArr = contacts.filter(item => item.contactType === CONTACT_TYPE.EMAIL)
      const currentDefaultEmail = emailsArr.find(email => email.principal)

      setDefaultEmail(currentDefaultEmail)
      setEmailsList(emailsArr.filter(email => !email.principal))
    }

    manipulateContacts()
  }, [contacts])

  function handleAdd() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_ADD_EMAIL)
    dispatch(setStage(REGISTRATION_DATA_STAGES.ADD))
  }

  function handleClickConfirmEmail(email) {
    setData(email)
    dispatch(setStage(REGISTRATION_DATA_STAGES.CONFIRMATION))
  }

  function handleDelete(email) {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_DELETE_EMAIL)
    setData(email)
    dispatch(setStage(REGISTRATION_DATA_STAGES.DELETE))
  }

  function handleChangeDefault() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_ALTER_MAIN_EMAIL)
    dispatch(setStage(REGISTRATION_DATA_STAGES.SELECT_DEFAULT))
  }

  function renderEmpty() {
    return <p className='empty'>Você não possui e-mail cadastrado.</p>
  }

  function renderSetDefaultButton() {
    return (
      <Button className='button' color='primary' outlined onClick={handleChangeDefault}>
        Mudar e-mail principal
      </Button>
    )
  }

  function renderContactNotConfirmedInfo(email) {
    return (
      <>
        <span className='not-confirmed'>E-mail não confirmado!</span>
        <Button
          className='button'
          color='primary'
          outlined
          onClick={() => handleClickConfirmEmail(email)}
        >
          Confirmar e-mail
        </Button>
      </>
    )
  }

  function renderContent() {
    return (
      <div className='content'>
        <h3 className='title'>Meus e-mails</h3>
        {!!defaultEmail && (
          <div className='section'>
            <p className='title'>E-mail principal</p>
            <Card className='card'>
              <div className='content'>
                <div className='info'>
                  <p className='email'>{defaultEmail?.contact}</p>
                </div>
              </div>
              {emailsList.length > 0 && renderSetDefaultButton()}
            </Card>
          </div>
        )}
        {!!emailsList.length && (
          <div className='section'>
            <span className='title'>Meus demais e-mails</span>
            {emailsList.map(item => (
              <Card className='card' key={String(item.contactId)}>
                <div className='content'>
                  <div className='info'>
                    <p className='email'>{item.contact}</p>
                    {!item.confirmed && renderContactNotConfirmedInfo(item)}
                  </div>
                  <div className='actions'>
                    <STATICS.DELETE className='delete' onClick={() => handleDelete(item)} />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='email-list-component'>
      {!defaultEmail ? renderEmpty() : renderContent()}
      <Button className='button' color='primary' size='large' onClick={handleAdd}>
        Adicionar novo e-mail
      </Button>
    </div>
  )
}
