import React from 'react'
import './style.scss'

export function Toggle({ className, label, onChange, checked }) {
  return (
    <label className={`toggle-component ${className || ''}`}>
      <input className='input' type='checkbox' checked={checked} onChange={onChange} />
      <span className='slider' />
      {label}
    </label>
  )
}
