import React from 'react'
import { ROUTES } from 'app-constants'
import { PasswordResetForm } from 'components'

export function FirstAccessPassword() {
  return (
    <PasswordResetForm
      title='Crie sua senha'
      subtitle='Crie sua senha de acesso:'
      prevStep={ROUTES.FIRST_ACCESS.PATH}
    />
  )
}
