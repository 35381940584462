import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearHistory, setDrawer } from 'slices/drawer'
import { Button } from 'components/button'
import './style.scss'

export function DueDateDisabled() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearHistory())
  }, [dispatch])

  function handleClickClose() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='due-date-disabled-component'>
      <div className='content'>
        <h3 className='title'>Não é possível alterar a data de vencimento!</h3>
        <h5 className='subtitle'>
          Alteração não permitida, pois o contrato selecionado não está habilitado.
        </h5>
      </div>
      <Button type='button' color='primary' size='large' onClick={handleClickClose}>
        Voltar para a home
      </Button>
    </div>
  )
}
