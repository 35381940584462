import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card } from 'components'
import { readMessage, removeMessage } from 'slices/customer'
import { clearHistory, setStage } from 'slices/drawer'
import { formatDate } from 'utils'
import { FIREBASE_EVENTS } from 'app-constants'
import { useFirebase } from 'hooks'
import { STATICS } from 'statics'
import './style.scss'

export function Message({ data }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  useEffect(() => {
    if (!data.visualized) {
      dispatch(readMessage(data.id))
    }
  }, [data.id, dispatch, data.visualized])

  function handleDelete() {
    sendEvent(FIREBASE_EVENTS.NOTIFICATION_DELETE)
    dispatch(removeMessage(data.id))
    dispatch(setStage('initial'))
    dispatch(clearHistory())
  }

  return (
    <div className='message-details-component'>
      <h3 className='title-drawer'>Central de Notificações</h3>
      <Card className='card'>
        <p className='title'>{data.title}</p>
        {data.imageUrl && <img alt={data.title} src={data.imageUrl} />}
        <p className='text description'>{data.description}</p>
        {data.launchUrl && (
          <a className='link' rel='noopener noreferrer' href={data.launchUrl} target='_blank'>
            {' '}
            {data.launchUrl}
          </a>
        )}
        <div className='box'>
          <p className='date text'>{formatDate(data.creationDateTime)}</p>
          <STATICS.DELETE onClick={() => handleDelete()} />
        </div>
      </Card>
    </div>
  )
}
