import React from 'react'
import { Loader } from './components'
import './style.scss'

export function Button({
  className,
  children,
  type,
  onClick,
  disabled,
  isLoading,
  color = 'default',
  outlined = false,
  icon,
  size = 'normal',
  dataCy,
  ...rest
}) {
  function resolveClasses() {
    const classes = []

    classes.push('button-component')
    classes.push(`-${size}`)
    classes.push(className || '')

    if (!disabled) {
      classes.push(`-${color}`)

      outlined && classes.push('-outlined')
    }

    return classes.join(' ')
  }

  return (
    <button
      className={resolveClasses()}
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      data-cy={dataCy}
      {...rest}
    >
      {icon && <div className='icon'>{icon}</div>}
      {isLoading ? <Loader color={outlined ? color : 'white'} /> : children}
    </button>
  )
}
