import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setDrawer, setStage } from 'slices/drawer'
import { unlockAccount } from 'slices/contract'
import { EVENT_STATUS, FIREBASE_EVENTS, PLAN_STATUS_KEY } from 'app-constants'
import { getIsEligibleToUnlockContract, useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { UnlockAccountContent } from '../content'
import { UNLOCK_ACCOUNT_STAGES } from '../../constants'

export function UnlockAccount() {
  const [loading, setLoading] = useState(false)
  const {
    selected: { id: idContract, suspendedByDebit, contractStatus },
    unlockDate,
  } = useSelector(state => state.contract)
  const dispatch = useDispatch()
  const history = useHistory()
  const { sendEvent } = useFirebase()

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function handleGoToInvoices() {
    handleClose()
    history.push('minhas-faturas')
  }

  async function handleUnlock() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_ACCOUNT_UNLOCK_NOW)

    try {
      setLoading(true)
      const { data } = await getIsEligibleToUnlockContract(idContract)

      if (!data.eligibleToUnlock) {
        sendEvent(FIREBASE_EVENTS.UNLOCK_ACCOUNT_NOT_ELIGIBLE)
        dispatch(setStage(UNLOCK_ACCOUNT_STAGES.ERROR))
      } else {
        await dispatch(unlockAccount({ idContract })).unwrap()
        sendEvent(FIREBASE_EVENTS.UNLOCK_ACCOUNT_SUCCESS, EVENT_STATUS.SUCCESS)
      }
    } catch (error) {
      sendEvent(FIREBASE_EVENTS.UNLOCK_ACCOUNT_ERROR, EVENT_STATUS.ERROR, error?.response?.status)
      dispatch(setStage(UNLOCK_ACCOUNT_STAGES.ERROR))
    } finally {
      setLoading(false)
    }
  }

  function renderStatusCanUnlock() {
    return (
      <UnlockAccountContent
        buttonLabel='Desbloquear agora'
        staticIcon={STATICS.ROUNDED_LOCK}
        info='O desbloqueio durará por 24 horas. Efetue o pagamento pendente para não ser bloqueado novamente.'
        backgroundColor='red'
        loading={loading}
        onClick={handleUnlock}
        subTitle='Esqueceu de pagar sua fatura? Não tem problema, nós liberamos o bloqueio até efetuar o pagamento.*'
        title='Sua conta se encontra bloqueada no momento!'
      />
    )
  }

  function renderStatusTemporaryUnlocked() {
    return (
      <UnlockAccountContent
        buttonLabel='Ir para minhas faturas'
        staticIcon={STATICS.ROUNDED_LOCK_OPEN}
        backgroundColor='lightBlue'
        onClick={handleGoToInvoices}
        subTitle='Efetue o pagamento pendendo para não voltar a ser bloqueado'
        title='Sua conta foi desbloqueada temporariamente!'
      />
    )
  }

  function renderStatusBlocked() {
    return (
      <UnlockAccountContent
        buttonLabel='Ir para minhas faturas'
        staticIcon={STATICS.ROUNDED_LOCK}
        onClick={handleGoToInvoices}
        subTitle='Efetue o pagamento pendente para voltar a ter acesso a sua conta. Caso já tenha efetuado o pagamento, aguarde a compensação bancária.'
        title='Sua conta se encontra bloqueada no momento!'
      />
    )
  }

  function renderStatusActivePending() {
    return (
      <UnlockAccountContent
        buttonLabel='Ok, entendi'
        staticIcon={STATICS.ROUNDED_LOCK}
        onClick={handleClose}
        subTitle='Essa funcionalidade está bloqueada até que a instalação seja concluída'
        title='Funcionalidade indisponível'
      />
    )
  }

  function renderStatusUnlocked() {
    sendEvent(FIREBASE_EVENTS.UNLOCK_ACCOUNT_UNBLOCKED_ACCOUNT)

    return (
      <UnlockAccountContent
        backgroundColor='green'
        buttonLabel='Ok, entendi'
        staticIcon={STATICS.DONE}
        onClick={handleClose}
        title='Sua conta não se encontra bloqueada!'
      />
    )
  }

  function renderByStatus() {
    if (suspendedByDebit && !unlockDate) {
      return renderStatusCanUnlock()
    }

    if (suspendedByDebit && unlockDate) {
      return renderStatusTemporaryUnlocked()
    }

    if (suspendedByDebit) {
      return renderStatusBlocked()
    }

    if (contractStatus === PLAN_STATUS_KEY.ST_CONT_EM_ATIVACAO) {
      return renderStatusActivePending()
    }

    return renderStatusUnlocked()
  }

  return renderByStatus()
}
