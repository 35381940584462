import React from 'react'

export const ADDITIONAL_INFO = [
  {
    key: 'bring-your-equipament',
    warning: true,
    content: (
      <>
        Caro cliente, para que seja realizada a sua mudança de endereço, é necessário que você{' '}
        <strong>leve o seu equipamento para a nova residência.</strong> Apenas o{' '}
        <strong>aparelho e o cabo de alimentação de energia</strong> (fonte).
      </>
    ),
  },
  {
    key: 'no-additional-cost',
    warning: false,
    content: (
      <>
        Essa solicitação <strong>não</strong> gera custo adicional
      </>
    ),
  },
  {
    key: 'exclusive-to-address-change',
    warning: false,
    content:
      'Essa solicitação é exclusiva para mudança de endereço, para mudar seu equipamento de cômodo, entre em contato conosco.',
  },
]
