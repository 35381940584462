import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getContractActivationStatus } from 'hooks'
import { Tag } from 'components'
import { formatDate } from 'utils'
import { DEFAULT_ERROR_MESSAGE } from 'app-constants'
import { setDrawerAlert } from 'slices/drawer'
import { STATICS } from 'statics'
import './style.scss'

export function Pending() {
  const [data, setData] = useState({})
  const id = useSelector(({ contract }) => contract.selected.id)

  const dispatch = useDispatch()

  useEffect(() => {
    async function loadActivationStatus() {
      try {
        const { data } = await getContractActivationStatus(id)

        setData({
          ...data,
          expectedInstallationDate: formatDate(data.expectedInstallationDate),
        })
      } catch (error) {
        const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE

        console.error(error)
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      }
    }

    loadActivationStatus()
  }, [id, dispatch])

  return (
    <div className='pending-plan-component'>
      <div className='wrapper'>
        <Tag text='AGUARDANDO INSTALAÇÃO' className='tag' />
        <p className='title'>Resumo do pedido</p>
        <p className='text'>Número: #{data.osId}</p>
        <p className='text'>Data prevista: {data.expectedInstallationDate}</p>
        <p className='text'>
          Período da {data.expectedInstallationTurn} entre: {data.expectedAttendancePeriod}
        </p>
      </div>
      <STATICS.ROUNDED_DONE className='icon' />
    </div>
  )
}
