import React, { useEffect, useState } from 'react'
import JsBarcode from 'jsbarcode'
import { BRAND_DISPLAY_NAME, DATE_FORMAT, ORIGIN } from 'app-constants'
import { downloadPDF, formatCurrency, formatDate } from 'utils'
import { Button } from 'components'
import { createBankSlips, useToaster } from 'hooks'
import {
  COPY_TO_CLIPBOARD,
  CREATE_BANK_SLIP_ERROR_MESSAGE,
  ONE_BANK_SLIP_MESSAGE,
} from '../../constants'
import './style.scss'

export function ListBankSlip({ bankSlipList = [] }) {
  const [isLoading, setIsLoading] = useState(false)
  const { showSuccessToast, showErrorToast } = useToaster()

  useEffect(() => {
    const orderedBankSlips = getOrderedBankSlips()
    orderedBankSlips.forEach(({ codigoBarras: barCode }) => {
      JsBarcode(`#barcode-${barCode}`, `${barCode}`, {
        height: 70,
        displayValue: false,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getOrderedBankSlips() {
    const { boletos: bankSlips } = bankSlipList

    return bankSlips.sort((currentBankSlip, nextBankSlip) => {
      const { vencimento: currentDate } = currentBankSlip
      const { vencimento: nextDate } = nextBankSlip
      const formattedCurrentDate = new Date(currentDate)
      const formattedNextDate = new Date(nextDate)
      if (formattedCurrentDate < formattedNextDate) return -1
      if (formattedCurrentDate > formattedNextDate) return 1
      return 0
    })
  }

  function handleTable(dueDate, price) {
    return (
      <div className='table'>
        <p className='title'>{BRAND_DISPLAY_NAME}</p>
        <div className='value-content'>
          <p>Valor</p>
          <strong>{formatCurrency(price)}</strong>
        </div>
        <div className='date-content'>
          <p>Vencimento</p>
          <strong>{formatDate(dueDate, DATE_FORMAT.SHORT)}</strong>
        </div>
      </div>
    )
  }

  function clipBoard(text) {
    navigator.clipboard.writeText(text)
    showSuccessToast(COPY_TO_CLIPBOARD)
  }

  async function printBankSlip(barCode, digitableLine, value, dueDate) {
    try {
      setIsLoading(true)
      const { data: bankSlipBase64 } = await createBankSlips({
        billetsData: [
          {
            barCode,
            digitableLine,
            value,
            dueDate: formatDate(dueDate, DATE_FORMAT.ISO),
          },
        ],
        brandType: ORIGIN,
      })
      downloadPDF(bankSlipBase64, 'boleto-giga-mais-fibra')
    } catch (error) {
      console.error(error)
      showErrorToast(CREATE_BANK_SLIP_ERROR_MESSAGE)
    } finally {
      setIsLoading(false)
    }
  }

  function renderTitle() {
    const { message, boletos: bankSlips } = bankSlipList

    const title = bankSlips.length === 1 ? ONE_BANK_SLIP_MESSAGE : message

    return <h3 className='title'>{title}</h3>
  }

  function renderList() {
    const orderedBankSlips = getOrderedBankSlips()

    return orderedBankSlips.map(
      ({
        codigoBarras: barCode,
        linhaDigitavel: digitableLine,
        valor: price,
        vencimento: dueDate,
      }) => (
        <div key={digitableLine} className='item-bank-slip'>
          <p className='text'>
            Para obter a versão completa desse boleto, acesse a <a href='/'>Central do Assinante</a>{' '}
            {BRAND_DISPLAY_NAME}!
          </p>
          <div className='info-text'>
            <p className='text'>
              <strong>Data de vencimento: </strong>
              {formatDate(dueDate, DATE_FORMAT.SHORT)}
            </p>
            <p className='text'>
              <strong>Valor: </strong>
              {formatCurrency(price)}
            </p>
            <p className='text'>
              <strong>Linha digitável: </strong>
              {digitableLine}
            </p>
          </div>
          <p className='text'>Imprima este boleto para pagar em casas lotéricas.</p>
          {handleTable(dueDate, price)}
          <svg className='bar-code' id={`barcode-${barCode}`} />
          <Button
            type='button'
            onClick={() => printBankSlip(barCode, digitableLine, price, dueDate)}
            color='primary'
            className='button'
            isLoading={isLoading}
          >
            Imprimir
          </Button>
          <Button
            type='button'
            onClick={() => clipBoard(digitableLine)}
            color='primary'
            className='button'
          >
            Copiar Código
          </Button>
        </div>
      )
    )
  }

  return (
    <div className='list-bank-slip'>
      {renderTitle()}
      <div className='list'>{renderList()}</div>
    </div>
  )
}
