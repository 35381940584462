import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { HASH_SIGMA_CHAT } from 'app-constants'
import './style.scss'

export function ChatAppVip() {
  const { hash } = useParams()
  const { push } = useHistory()
  const [displayChat, setDisplayChat] = useState(false)

  useEffect(() => {
    function onLoadChat() {
      setDisplayChat(true)

      // eslint-disable-next-line no-new, no-undef
      new SigmaChat({
        id: 'vipbrtelecom',
        logo: {
          src:
            'https://www.vipbrtelecom.com.br/_next/static/images/logo-9b15102ecdc39560f2d3371b1c9ec1b3.svg',
          height: '120px',
        },
        icon: {
          src:
            'https://www.vipbrtelecom.com.br/_next/static/images/vip-blue-icon-9aa4f74fd2df626d4e368c1e9053f297.png',
          height: '50px',
        },
        headline: 'Bem-vindo',
        autoMessage: 'Olá',
        links: [],
        drawFAB: false,
        phone: {
          code: '+55',
          pattern: '\\([0-9]{2}\\)[0-9]{4,5}-[0-9]{4}',
          title: 'Formato valido: (99)9999-9999 | (99)99999-9999',
          maskOptions: { mask: [{ mask: '(00)0000-0000' }, { mask: '(00)00000-0000' }] },
        },
        fields: [],
        lang: {
          name: 'Nome',
          namePH: 'Digite seu nome',
          email: 'Email',
          emailPH: 'Digite seu email',
          phone: 'Telefone',
          phonePH: 'Digite seu telefone',
          submit: 'Enviar',
          message: 'Mensagem',
          messagePH: 'Digite sua mensagem aqui...',
          download: 'Baixar',
          assistance: 'VIP Telecom',
        },
      })

      window.SigmaChat.showChat()
    }

    const script = document.createElement('script')
    if (hash === HASH_SIGMA_CHAT) {
      script.src = 'https://sigmavip.nuvetoapps.com.br/dist/js/sigma-chat.js'
      script.async = true
      script.onload = onLoadChat

      document.head.appendChild(script)
    } else {
      push('/')
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [hash, push])

  useEffect(() => {
    if (displayChat) {
      const sigmaContainer = document.querySelector('.sigma-container')
      sigmaContainer.classList.add('chat-app-container')

      document.documentElement.style.setProperty('--sigma-client-color', '#0053CE')
      document.documentElement.style.setProperty('--sigma-assistent-color', '#192A48')
      document.documentElement.style.setProperty('--sigma-start-chat-color', '#0053CE')
      document.documentElement.style.setProperty('--sigma-start-chat-hover-color', '#192A48')
      document.documentElement.style.setProperty('--sigma-background-chat', 'white')
    }
  }, [displayChat])

  return null
}
