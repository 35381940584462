export const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro, tente novamente mais tarde.'

export const COPY_CODE_MESSAGE = 'Copiar código.'

export const COPIED_CODE_MESSAGE = 'Código copiado!'

export const COUPON_ERROR_MESSAGE =
  'Não foi possível gerar seu cupom, tente novamente em alguns minutos'

export const GLOBOPLAY_ERROR_MESSAGE =
  'Ocorreu um erro. Não foi possível acessar o site, tente novamente mais tarde'

export const PIX_PAYMENT_ERROR_MESSAGE = 'Não foi possível buscar o QR Code.'

export const CREDIT_CARD_ERROR_MESSAGE = 'Não foi possível buscar os dados de pagamento com cartão'
