import React from 'react'
import { useSelector } from 'react-redux'
import { ServiceOrders } from './components'

function stages() {
  return {
    initial: <ServiceOrders />,
  }
}

export function ServiceOrdersDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages()[stage]
}
