import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFirebase } from 'hooks'
import { CONTRACT_SITUATION, FIREBASE_EVENTS } from 'app-constants'
import { STATICS } from 'statics'
import { setDrawer } from 'slices/drawer'
import { Card } from '../card'
import { CardButton } from '../card-button'
import './style.scss'

export function AddressCard({ className }) {
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()

  const {
    zipCode,
    type,
    street,
    number,
    complement,
    neighborhood,
    city,
    uf,
    situation,
  } = useSelector(state => {
    const { address } = state.contract.selected

    return {
      zipCode: address?.zipCode,
      type: address?.type?.toLowerCase(),
      street: address?.street?.toLowerCase(),
      number: address?.number,
      complement: address?.complement?.toLowerCase(),
      neighborhood: address?.neighborhood?.toLowerCase(),
      city: address?.city?.toLowerCase(),
      uf: address?.uf,
      situation: state.contract.selected.situation,
    }
  })

  function handleChangeAddressClick(drawer) {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_ADDRESS_CHANGE)
    dispatch(setDrawer(drawer))
  }

  function renderAddressChangeButton() {
    const shouldRenderButton = situation === CONTRACT_SITUATION.ENABLED

    return (
      shouldRenderButton && (
        <div className='wrapper -actions'>
          <CardButton
            icon={<STATICS.ADDRESS_CHANGE />}
            onClick={() => handleChangeAddressClick('addressChange')}
          >
            Alterar endereço de instalação
          </CardButton>
        </div>
      )
    )
  }

  return (
    <Card className={`address-card-component ${className || ''}`}>
      <div className='wrapper'>
        <h3 className='title'>Meu endereço</h3>
        <p className='text'>
          {type} {street}, {number}
        </p>
        <p className='text'>
          {complement}, {neighborhood}
        </p>
        <p className='text'>
          {city} - {uf}
        </p>
        <p className='text'>CEP: {zipCode}</p>
      </div>
      {renderAddressChangeButton()}
    </Card>
  )
}
