import React from 'react'

export function PasswordRules() {
  return (
    <div className='rules'>
      <ul>
        <li>Sua senha deve conter: </li>
        <li>Entre 6 e 20 caracteres.</li>
        <li>Não possuir espaços ou vírgulas.</li>
      </ul>
    </div>
  )
}
