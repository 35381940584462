export const PLAN_STATUS_KEY = {
  ST_CONT_EM_ATIVACAO: 'ST_CONT_EM_ATIVACAO',
  ST_CONT_HABILITADO: 'ST_CONT_HABILITADO',
  ST_CONT_SUSP_SOLICITACAO: 'ST_CONT_SUSP_SOLICITACAO',
  ST_CONT_CANCELADO: 'ST_CONT_CANCELADO',
  ST_CONT_SUSP_CANCELAMENTO: 'ST_CONT_SUSP_CANCELAMENTO',
  ST_CONT_SUSP_DEBITO: 'ST_CONT_SUSP_DEBITO',
  ST_CONT_SUSPENSO: 'ST_CONT_SUSPENSO',
}

export const PLAN_STATUS = {
  ST_CONT_EM_ATIVACAO: 'Aguardando Ativação',
  ST_CONT_HABILITADO: 'Habilitado',
  ST_CONT_SUSP_SOLICITACAO: 'Suspenso por solicitação',
  ST_CONT_CANCELADO: 'Cancelado',
  ST_CONT_SUSP_CANCELAMENTO: 'Suspenso para cancelamento',
  ST_CONT_SUSP_DEBITO: 'Suspenso por débito',
  ST_CONT_SUSPENSO: 'Suspenso',
}
