import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { sendContactUpdateModalVisualization } from 'hooks'
import { Alert } from 'components/alert'
import { ConfirmationStage, EmailStage, PhoneStage, SuccessStage } from './components'
import { CONTACT_UPDATE_STAGES, CONTACT_UPDATE_INITIAL_STATE } from './constants'
import './style.scss'

function stages({ ...props }) {
  return {
    [CONTACT_UPDATE_STAGES.INITIAL]: <PhoneStage {...props} />,
    [CONTACT_UPDATE_STAGES.CONFIRMATION]: <ConfirmationStage {...props} />,
    [CONTACT_UPDATE_STAGES.EMAIL]: <EmailStage {...props} />,
    [CONTACT_UPDATE_STAGES.SUCCESS]: <SuccessStage />,
  }
}

export function ContactUpdateModal() {
  const [data, setData] = useState(CONTACT_UPDATE_INITIAL_STATE)
  const [errorMessage, setErrorMessage] = useState('')
  const contractId = useSelector(state => state.contract.selected.id)
  const { stage } = useSelector(({ modal }) => modal)

  useEffect(() => {
    async function sendModalVisualization() {
      try {
        await sendContactUpdateModalVisualization(contractId)
      } catch (error) {
        console.error(error)
      }
    }

    sendModalVisualization()
  }, [contractId])

  return (
    <div className='contact-update-modal'>
      <div className='container-alert'>
        {errorMessage && <Alert className='alert' severity='error' text={errorMessage} />}
      </div>
      {stages({ data, setData, setErrorMessage })[stage]}
    </div>
  )
}
