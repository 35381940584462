import React from 'react'
import { STATICS } from 'statics'
import './style.scss'

const ICONS = {
  info: <STATICS.INFO />,
  success: <STATICS.SUCCESS />,
  error: <STATICS.ERROR />,
}

export function Alert({
  className,
  severity = 'success',
  onClose,
  title,
  text,
  size = 'small',
  action = {},
}) {
  const { label, onClick } = action

  function renderCloseButton() {
    return (
      <div className='close' onClick={onClose}>
        <STATICS.CLOSE />
      </div>
    )
  }

  return (
    <div className={`alert-component ${className || ''} -${severity} -${size}`}>
      <div className='icon'>{ICONS[severity]}</div>
      <div className='wrapper'>
        {title && (
          <h3 className='title'>
            {title}
            <button className='action' onClick={onClick}>
              {label}
            </button>
          </h3>
        )}
        <p className='text'>{text}</p>
      </div>
      {!!onClose && renderCloseButton()}
    </div>
  )
}
