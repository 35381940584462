import { STATICS } from 'statics'

export const ADDITIONAL_SERVICES = {
  PRO00182: { description: 'CANAIS FOX+', image: STATICS.FOX },
  PRO00184: { description: 'CANAIS SEX PRIVE', image: STATICS.SEX_PRIVE },
  PRO00313: { description: 'FALE ECONÔMICO ADICIONAL', image: '' },
  PRO00358: { description: 'LOCAÇÃO KIT TV ADICIONAL (VEL)', image: '' },
  PRO00515: { description: '50 DDRs adicionais (OST)', image: '' },
  PRO00559: { description: 'LOCAÇÃO KIT TV ADICIONAL (VMO)', image: '' },
  PRO00560: { description: 'CANAIS HBO HD PROMOCIONAL (VMO)', image: STATICS.HBO },
  PRO00561: { description: 'CANAIS FOX+SEX (VMO)', image: STATICS.FOX },
  PRO00562: { description: 'TOP MOVIES CMB VMO', image: STATICS.TOP_MOVIES },
  PRO00563: { description: 'DISPONIBILIDADE DE WIFI (VMO)', image: '' },
  PRO00579: { description: 'LOCAÇÃO KIT TV ADICIONAL (VEL)', image: '' },
  PRO00580: { description: 'DISPONIBILIDADE DE WIFI (VEL)', image: '' },
  PRO00581: { description: 'CRACKLE ADD (VMO)', image: '' },
  PRO00582: { description: 'FALE ILIMITADO BR FIXO ADD', image: '' },
  PRO00583: { description: 'FALE PREMIUM ADD', image: '' },
  PRO00695: { description: 'NOGGIN', image: STATICS.NOGGIN },
  PRO00696: { description: 'WATCH ESPN', image: STATICS.WATCH_ESPN },
  PRO00697: { description: 'SCM 60 MBPS VMO', image: '' },
  PRO00707: { description: 'NOGGIN 2', image: STATICS.NOGGIN },
  PRO00715: { description: 'CANAIS SEX PRIVE 2019', image: STATICS.SEX_PRIVE },
  PRO00738: { description: 'TOP MOVIES PREMIUM', image: STATICS.TOP_MOVIES },
}
