import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'components/button'
import { setDrawer } from 'slices/drawer'
import { STATICS } from 'statics'
import './style.scss'

export function ModemError({ title = 'Não foi possível alterar a sua senha' }) {
  const dispatch = useDispatch()

  function handleClick() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='modem-error-component'>
      <div className='icon'>
        <STATICS.INFO_CIRCLE />
      </div>
      <h3 className='title'>{title}</h3>
      <p className='text'>
        Tente novamente em instantes ou entre em contato com a nossa central de atendimento.
      </p>
      <Button className='button' color='primary' size='large' onClick={handleClick}>
        Ok, entendi!
      </Button>
    </div>
  )
}
