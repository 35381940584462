import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useForm, useFirebase } from 'hooks'
import { EVENT_STATUS, FIREBASE_EVENTS } from 'app-constants'
import { updateInternet } from 'slices/contract'
import { clearHistory, setStage } from 'slices/drawer'
import { Input, Button } from 'components'
import { STATICS } from 'statics'
import { setWifiTimerInitialDate, validateWifiName, validateWifiPassword } from 'utils'
import { STAGES, INVALID_WIFI_PASSWORD_MESSAGE, INVALID_WIFI_NAME_MESSAGE } from '../../constants'
import './style.scss'

const validationSchema = Yup.object().shape({
  ssid: Yup.string()
    .required('Campo obrigatório.')
    .test('ssid', INVALID_WIFI_NAME_MESSAGE, value => validateWifiName(value)),
  password: Yup.string()
    .required('Campos obrigatórios')
    .min(8, 'Deve ter no mínimo 8 caractéres')
    .max(20, 'Deve ter no máximo 20 caractéres')
    .test('password', INVALID_WIFI_PASSWORD_MESSAGE, value => validateWifiPassword(value)),
  confirmPassword: Yup.string()
    .required('Campos obrigatórios')
    .oneOf([Yup.ref('password'), null], 'Senhas não coincidem'),
})

export function ModemEdit() {
  const {
    internet,
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const [errors, setErrors] = useState({
    password: '',
    ssid: '',
  })

  const form = useForm({
    initialValues: {
      ssid: internet.ssid,
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    const { ssid: ssidError, password: passwordError } = form.errors

    setErrors({
      password: passwordError,
      ssid: ssidError,
    })

    if (Object.values(form.errors).length > 0) {
      sendEvent(FIREBASE_EVENTS.WIFI_UPDATE_NEW_REQUEST, EVENT_STATUS.ERROR)
    }
  }, [form.errors, sendEvent])

  async function handleSubmit(values) {
    sendEvent(FIREBASE_EVENTS.WIFI_UPDATE_NEW_REQUEST)
    try {
      await dispatch(updateInternet({ idContract, ...values })).unwrap()
      sendEvent(FIREBASE_EVENTS.WIFI_UPDATE_SUCCESS)
      setWifiTimerInitialDate()
      dispatch(setStage(STAGES.SUCCESS))
      dispatch(clearHistory())
    } catch {
      sendEvent(FIREBASE_EVENTS.WIFI_UPDATE_ERROR, EVENT_STATUS.ERROR)
      dispatch(setStage(STAGES.ERROR))
    }
  }

  function renderHelperText() {
    return (
      <div className='helper-text-container'>
        <div className='icon'>
          <STATICS.INFO_OUTLINE />
        </div>
        <p className='text'>
          A senha deve conter no mínimo 8 caracteres e não deve conter espaços, emojis, caracteres
          especiais ou vírgulas.
          <br />
          <br />
          O nome da sua rede deve iniciar sempre com uma letra e não deve conter caractere especial.
          <br />
          <br /> Assim que a senha for alterada, você receberá uma confirmação. Por favor, aguarde
          essa confirmação antes de atualizar os dados nos dispositivos conectados à sua rede.
        </p>
      </div>
    )
  }

  return (
    <form className='modem-edit' onSubmit={form.handleSubmit}>
      <h3 className='title'>Alterar meu WIFI</h3>
      <p className='text'>Infome o nome da rede</p>
      <Input
        className='input'
        name='ssid'
        value={form.values.ssid}
        onChange={form.handleChange}
        label='Nome da rede'
        hasError={form.errors.ssid}
      />
      <p className='error'>{errors.ssid}</p>
      <p className='text'>Informe sua nova senha</p>
      <Input
        className='input'
        name='password'
        value={form.values.password}
        onChange={form.handleChange}
        type='password'
        label='Nova senha'
        hasError={form.errors.password}
        visiblePassword
      />
      <p className='text'>Repetir nova senha</p>
      <Input
        className='input'
        name='confirmPassword'
        value={form.values.confirmPassword}
        onChange={form.handleChange}
        type='password'
        label='Repetir senha'
        hasError={form.errors.confirmPassword}
        visiblePassword
      />
      <p className='error'>{errors.password}</p>
      {renderHelperText()}
      <Button
        className='button'
        color='primary'
        type='submit'
        size='large'
        isLoading={form.isSubmitting}
      >
        Alterar WIFI
      </Button>
    </form>
  )
}
