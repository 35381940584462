import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { getOnuStatus, useFirebase } from 'hooks'
import { FIREBASE_EVENTS, VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import { Button } from 'components'
import { ONU_STATUS } from './constants'
import './style.scss'

export function AddressConfirmation({ contractAddressSelected }) {
  const [fowardStage, setFowardStage] = useState()
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  useEffect(() => {
    async function getOnusStatus() {
      try {
        const {
          data: { statusOnu },
        } = await getOnuStatus(contractAddressSelected.id)

        const hasSignalOpticalProblems =
          statusOnu === ONU_STATUS.OPTICAL_SIGNAL || statusOnu === ONU_STATUS.LOSS

        if (hasSignalOpticalProblems) setFowardStage(VIRTUAL_SUPPORT_STAGES.CHECK_MODEM_LIGHTS)
        else setFowardStage(VIRTUAL_SUPPORT_STAGES.SELECT_SERVICES)
      } catch (error) {
        console.error(error)
        setFowardStage(VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT)
      }
    }

    getOnusStatus()
  }, [contractAddressSelected.id])

  function handleButtonClick(stage) {
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_CONFIRM_ADDRESS)
    dispatch(setStage(stage))
  }

  function getLowerCaseAddressInfo() {
    const { city, neighborhood, number, street, uf, zipCode } = contractAddressSelected.address

    return {
      city: city.toLowerCase(),
      neighborhood: neighborhood.toLowerCase(),
      number,
      street: street.toLowerCase(),
      uf,
      zipCode: zipCode.toLowerCase(),
    }
  }

  function renderAddressCard() {
    const { city, neighborhood, number, street, uf, zipCode } = getLowerCaseAddressInfo()

    return (
      <div className='container-card'>
        <span className='title'>Endereço:</span>
        <span className='info'>
          {street}, {number}, {neighborhood} - {city}, {uf}
          <br />
          CEP: {zipCode}
        </span>
      </div>
    )
  }

  function renderActionButtons() {
    return (
      <>
        {fowardStage && (
          <Button
            className='button confirm'
            color='primary'
            size='large'
            onClick={() => handleButtonClick(fowardStage)}
          >
            Confirmar
          </Button>
        )}
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          onClick={() => handleButtonClick(VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS)}
        >
          Cancelar
        </Button>
      </>
    )
  }

  return (
    <div className='container-address-confirmation'>
      <div className='container-info'>
        <div className='container-title'>
          <span className='title'>Confirmar Endereço</span>
        </div>
        <span className='contract-info'>
          O endereço que você selecionou é referente ao contrato
          <span className='contract'> {contractAddressSelected.id}</span>
        </span>
        {renderAddressCard()}
        <span className='instruction'>
          Clique abaixo em confirmar se for esse o endereço desejado, ou volte se quiser selecionar
          o reparo para outro endereço
        </span>
      </div>
      <div className='container-buttons'>{renderActionButtons()}</div>
    </div>
  )
}
