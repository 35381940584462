import { STORAGE_KEYS } from 'app-constants'
import { getItem, setItem } from './sessionStorageUtils'

export function setRemindPasswordLaterStorage(item) {
  setItem({
    itemName: STORAGE_KEYS.REMIND_LATER,
    item,
  })
}

export function isRemindPasswordLaterStorage() {
  return getItem({ itemName: STORAGE_KEYS.REMIND_LATER })
}
