import React, { useEffect, useState } from 'react'
import { numbersAndLettersRegex } from 'utils'
import './style.scss'

export function OtpInput({ maxLength, setCode }) {
  const [codeDigits, setCodeDigits] = useState([])

  useEffect(() => {
    function formatInput() {
      const defaultArray = Array(maxLength).fill('')
      setCodeDigits(defaultArray)
    }

    formatInput()
  }, [maxLength])

  const focusToNextInput = target => {
    const { nextElementSibling } = target

    if (nextElementSibling) nextElementSibling.focus()
  }

  const focusToPreviousInput = target => {
    const { previousElementSibling } = target

    if (previousElementSibling) previousElementSibling.focus()
  }

  function getStringCode() {
    return codeDigits.reduce((previousCode, currentDigit) => `${previousCode}${currentDigit}`)
  }

  function insertDigit(digit, position, isValueNumber, target) {
    codeDigits[position] = digit
    setCode(getStringCode())

    if (isValueNumber) focusToNextInput(target)
  }

  function pasteCode(pastedCode, target) {
    setCode(pastedCode)
    setCodeDigits(Array.from(pastedCode))
    target.blur()
  }

  function handleDigitChange({ target }, position) {
    const { value } = target
    const digit = value.trim().toUpperCase()
    const isValidValue = numbersAndLettersRegex.test(digit)
    const isPastedCode = digit.length === maxLength
    const isDeletingDigit = digit === ''
    const shouldWriteOnInput = isValidValue || isDeletingDigit

    if (shouldWriteOnInput) {
      if (isPastedCode) pasteCode(digit, target)
      else insertDigit(digit, position, isValidValue, target)
    }
  }

  function handleKeyDown(event) {
    const { target, key } = event
    const { value } = target

    target.setSelectionRange(0, value.length)

    const isDeletingDigit = key === 'Backspace' && target.value === ''
    const shouldFocusPreviousInput = isDeletingDigit || key === 'ArrowLeft' || key === 'ArrowUp'
    const shouldFocusNextInput = key === 'ArrowRight' || key === 'ArrowDown'

    if (shouldFocusPreviousInput) focusToPreviousInput(target)
    else if (shouldFocusNextInput) focusToNextInput(target)
  }

  function handleFocus({ target }) {
    const { value } = target

    target.setSelectionRange(0, value.length)
  }

  return (
    <div className='otp-input-component'>
      {codeDigits.map((digit, index) => (
        <input
          key={index}
          type='text'
          value={digit}
          className='otp-input'
          onChange={e => handleDigitChange(e, index)}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
        />
      ))}
    </div>
  )
}
