import React from 'react'
import { useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { setModal } from 'slices/modal'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import './style.scss'

export function UpdateEmailModal() {
  const dispatch = useDispatch()

  function handleClick() {
    dispatch(setDrawer('email'))
    dispatch(setModal({ key: '' }))
  }

  return (
    <div className='update-email-modal'>
      <div className='round'>
        <div className='icon'>
          <STATICS.EDIT />
        </div>
      </div>
      <h3 className='title'>Altere seu e-mail</h3>
      <p className='text'>
        Insira um novo e-mail e lhe enviaremos um código para validarmos sua alteração
      </p>
      <Button className='button' color='primary' size='large' onClick={handleClick}>
        Ok, entendi
      </Button>
    </div>
  )
}
