import { useState, useEffect } from 'react'

export function CountdownTime({ onFinishCounter = () => {}, canSendCode, time = 60 }) {
  const [timer, setTimer] = useState(time)

  useEffect(() => {
    if (timer > 0) {
      const timeout = setTimeout(() => {
        const countTimer = timer - 1

        setTimer(countTimer)
      }, 1000)
      return () => clearTimeout(timeout)
    }
    onFinishCounter()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer])

  useEffect(() => {
    if (!canSendCode) {
      setTimer(time)
    }
  }, [canSendCode, time])

  return timer
}
