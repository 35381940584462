import * as COMMON_STATICS from '../common'
import Logo from './logo.png'
import LogoForgotPassword from './logo-forgot-password.png'

const BRAND_STATICS = {
  LOGO: Logo,
  LOGO_FORGOT_PASSWORD: LogoForgotPassword,
}

export const STATICS = {
  ...COMMON_STATICS,
  ...BRAND_STATICS,
}
