import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setCustomerMainContact } from 'slices/customer'
import { setDrawerAlert, setStage } from 'slices/drawer'
import { formatPhone } from 'utils'
import {
  REGISTRATION_DATA_STAGES,
  CONTACT_TYPE,
  EVENTS,
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import { Button, Card, RadioButton } from 'components'
import { useFirebase } from 'hooks'
import './style.scss'

export function PhoneSelectDefault() {
  const [error, setError] = useState(false)
  const [phonesList, setPhonesList] = useState([])
  const [phoneIndexSelected, setPhoneIndexSelected] = useState()
  const {
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)
  const {
    info: { contacts },
  } = useSelector(state => state.customer)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  useEffect(() => {
    function manipulateContacts() {
      const phonesArr = contacts.filter(item => item.contactType === CONTACT_TYPE.PHONE)

      setPhonesList(phonesArr.filter(phone => !phone.principal && phone.confirmed))
    }

    manipulateContacts()
  }, [contacts])

  async function handleSubmit() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_CONFIRM_MAIN_PHONE)

    if (phoneIndexSelected >= 0) {
      try {
        await dispatch(
          setCustomerMainContact({ contactId: phonesList[phoneIndexSelected].contact })
        ).unwrap()
        sendEvent(FIREBASE_EVENTS.ALTER_PHONE_SUCCESS, EVENT_STATUS.SUCCESS)
        dispatch(
          setDrawerAlert({ severity: 'success', title: `Você atualizou o seu telefone principal!` })
        )
      } catch (error) {
        console.error(error)
        sendEvent(FIREBASE_EVENTS.ALTER_PHONE_SUCCESS, EVENT_STATUS.ERROR, error?.response?.status)
        dispatch(setDrawerAlert({ severity: 'error', title: DEFAULT_ERROR_MESSAGE }))
      }

      dispatch(setStage(REGISTRATION_DATA_STAGES.LIST))
    } else {
      setError(true)
    }
  }

  function handleClickSelectPhone(index) {
    setPhoneIndexSelected(index)
    setError(false)
  }

  function renderPhone(phone) {
    return formatPhone(phone)
  }

  function renderValidPhonesList(phonesList) {
    return !phonesList.length ? (
      <span>
        Só é possível alterar o telefone principal para telefones confirmados, os telefones não
        confirmados não são exibidos na lista.
      </span>
    ) : (
      phonesList.map((item, index) => (
        <Card
          className={`card ${phoneIndexSelected === index ? '-selected' : ''} ${
            error ? '-error' : ''
          }`}
          onClick={() => handleClickSelectPhone(index)}
          key={String(index)}
        >
          <RadioButton id={index} checked={phoneIndexSelected === index} />
          <span className='text'>{renderPhone(item.contact)}</span>
        </Card>
      ))
    )
  }

  return (
    <div className='phone-select-default-component'>
      <div className='content'>
        <h3 className='title'>Selecionar telefone principal</h3>
        <div className='list'>{renderValidPhonesList(phonesList)}</div>
      </div>
      {!!phonesList.length && (
        <Button type='button' color='primary' size='large' onClick={handleSubmit}>
          Definir telefone principal
        </Button>
      )}
    </div>
  )
}
