import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useForm } from 'hooks'
import { CONTACT_TYPE, DEFAULT_ERROR_MESSAGE } from 'app-constants'
import { setDrawerAlert } from 'slices/drawer'
import { addCustomerContact, fetchContacts } from 'slices/customer'
import { Input, Button } from 'components'
import './style.scss'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato inválido')
    .required('Campo obrigatório'),
})

export function SignupEmail() {
  const dispatch = useDispatch()
  const [error, setError] = useState('')

  const form = useForm({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    const errors = [...Object.values(form.errors)]
    setError(errors[0])
  }, [form.errors])

  async function handleSubmit(values) {
    const { email } = values

    try {
      await dispatch(
        addCustomerContact({
          contact: email,
          contactType: CONTACT_TYPE.EMAIL,
        })
      ).unwrap()

      await dispatch(fetchContacts()).unwrap()
      dispatch(setDrawerAlert({ severity: 'success', title: 'E-mail adicionado com sucesso!' }))
    } catch (error) {
      dispatch(setDrawerAlert({ severity: 'error', title: DEFAULT_ERROR_MESSAGE }))
    }
  }

  return (
    <form className='signup-email' onSubmit={form.handleSubmit}>
      <h3 className='title'>Falta pouco!</h3>
      <p className='text'>Preencha seu e-mail para finalizarmos o seu cadastro abaixo:</p>
      <p className='label'>Insira seu e-mail</p>
      <Input
        className='input'
        name='email'
        label='E-mail'
        onChange={form.handleChange}
        value={form.values.email}
        hasError={form.errors.email}
        errorMessage={form.errors.email}
      />
      {!!error && <p className='error'>{error}</p>}
      <Button
        className='button'
        type='submit'
        color='primary'
        size='large'
        isLoading={form.isSubmitting}
      >
        Continuar
      </Button>
    </form>
  )
}
