import { CONTACT_UPDATE_STAGES } from './stages'

export const CONFIRMATION_TYPE_VALUES = {
  EMAIL: 'EMAIL',
  PHONE: 'TELEFONE',
}

export const CONFIRMATION_TYPE = {
  [CONTACT_UPDATE_STAGES.EMAIL]: CONFIRMATION_TYPE_VALUES.EMAIL,
  [CONTACT_UPDATE_STAGES.INITIAL]: CONFIRMATION_TYPE_VALUES.PHONE,
}
