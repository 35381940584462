export const INTERNET_KEY = 'internet'

export const SERVICES = [
  {
    LABEL: 'Internet',
    KEY: INTERNET_KEY,
  },
  {
    LABEL: 'TV',
    KEY: 'tv',
  },
  {
    LABEL: 'Telefone Fixo',
    KEY: 'phone',
  },
  {
    LABEL: 'Todos os serviços',
    KEY: 'all',
  },
]
