import { useEffect, useState, useCallback } from 'react'
import { getApp, initializeApp } from 'firebase/app'
import { addDoc, collection, getFirestore } from 'firebase/firestore'
import { formatISO } from 'date-fns'
import {
  EVENT_STATUS,
  FIREBASE_CONFIG,
  FIREBASE_DB_NAME,
  BRAND_NAME,
  SESSION_KEY,
  DEFAULT_DOCUMENT,
  DEFAULT_ORIGIN,
} from 'app-constants'

export function useFirebase() {
  const [database, setDatabase] = useState(null)

  useEffect(() => {
    function handleFirebaseConnection() {
      try {
        const currentAppInstance = getApp(FIREBASE_DB_NAME)
        return currentAppInstance
      } catch {
        const newAppInstance = initializeApp(FIREBASE_CONFIG, FIREBASE_DB_NAME)
        return newAppInstance
      }
    }

    const app = handleFirebaseConnection()
    const databaseInstance = getFirestore(app, FIREBASE_DB_NAME)

    setDatabase(databaseInstance)
  }, [])

  const sendLoginEvent = useCallback(
    async document => {
      if (database) {
        try {
          const data = {
            createdAt: formatISO(Date.now()),
            source: DEFAULT_ORIGIN,
            document: document || DEFAULT_DOCUMENT,
            brand: BRAND_NAME,
          }

          const docRef = await addDoc(collection(database, 'sessions'), data)
          localStorage.setItem(SESSION_KEY, docRef.id)
        } catch (error) {
          console.error(error)
        }
      }
    },
    [database]
  )

  const sendEvent = useCallback(
    async (event, status = EVENT_STATUS.SUCCESS, statusCode = null) => {
      if (database) {
        try {
          const { fluxo, jornada, label, ordem, title } = event
          const sessionId = localStorage.getItem(SESSION_KEY)
          const data = {
            createdAt: formatISO(Date.now()),
            label,
            title,
            jornada,
            fluxo,
            ordem,
            status,
            status_code: statusCode,
            session_id: sessionId,
          }

          const docRef = await addDoc(collection(database, 'events'), data)

          return docRef.id
        } catch (error) {
          console.error(error)
        }
      }
    },
    [database]
  )

  return { sendEvent, sendLoginEvent }
}
