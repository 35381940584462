import Keycloak from 'keycloak-js'

const {
  REACT_APP_KEYCLOACK_URL,
  REACT_APP_KEYCLOACK_REALM,
  REACT_APP_KEYCLOACK_CLIENTID,
} = process.env

const keycloak = new Keycloak({
  url: REACT_APP_KEYCLOACK_URL,
  realm: REACT_APP_KEYCLOACK_REALM,
  clientId: REACT_APP_KEYCLOACK_CLIENTID,
})

export default keycloak
