import React from 'react'
import './style.scss'

export function RadioButton({
  name = '',
  id,
  label = '',
  checked,
  onChange = () => {},
  value,
  labelClassName = '',
  circleClassName = '',
  inputClassName = '',
  dataCy,
}) {
  return (
    <label htmlFor={id} className={`radio-button-component ${labelClassName}`} data-cy={dataCy}>
      <input
        value={value}
        type='radio'
        name={name}
        id={id}
        className={`input ${inputClassName}`}
        checked={checked}
        onChange={onChange}
      />
      <span className={`label ${circleClassName}`} />
      {label}
    </label>
  )
}
