import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteCustomerContact } from 'slices/customer'
import { clearHistory, setDrawerAlert, setStage } from 'slices/drawer'
import {
  DEFAULT_ERROR_MESSAGE,
  EVENT_STATUS,
  FIREBASE_EVENTS,
  REGISTRATION_DATA_STAGES,
} from 'app-constants'
import { Button, Card } from 'components'
import { useFirebase } from 'hooks'
import './style.scss'

export function EmailDelete({ data }) {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  async function handleSubmit() {
    setIsLoading(true)
    sendEvent(FIREBASE_EVENTS.CLICK_ON_CONFIRM_DELETE_EMAIL)

    try {
      await dispatch(deleteCustomerContact(data)).unwrap()

      sendEvent(FIREBASE_EVENTS.DELETE_EMAIL_SUCCESS, EVENT_STATUS.SUCCESS)
      dispatch(setDrawerAlert({ severity: 'success', title: `E-mail apagado com sucesso!` }))
    } catch (error) {
      sendEvent(FIREBASE_EVENTS.DELETE_EMAIL_ERROR, EVENT_STATUS.ERROR, error?.response?.status)
      dispatch(setDrawerAlert({ severity: 'error', title: DEFAULT_ERROR_MESSAGE }))
    }

    dispatch(setStage(REGISTRATION_DATA_STAGES.LIST))
    dispatch(clearHistory())
  }

  return (
    <div className='email-delete-component'>
      <div className='content'>
        <h3 className='title'>Apagar e-mail</h3>
        <p className='text'>Deseja apagar o e-mail abaixo?</p>
        <Card className='card'>
          <p className='text'>{data.contact}</p>
        </Card>
      </div>
      <Button
        type='button'
        color='primary'
        isLoading={isLoading}
        size='large'
        onClick={handleSubmit}
      >
        Sim, apagar e-mail
      </Button>
    </div>
  )
}
