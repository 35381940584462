const cpfDigitsLength = 11
const cnpjDigitsLength = 14

export const formatCpf = cpf =>
  cpf
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2')

export const formatCnpj = cnpj =>
  cnpj
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')

export const isCpf = cnpj => /^\d{3}.\d{3}.\d{3}-\d{2}$/.test(cnpj)

export const isValidDocumentLength = document =>
  document.replace(/\D/g, '').length === cpfDigitsLength ||
  document.replace(/\D/g, '').length === cnpjDigitsLength
