import React from 'react'
import { GenericCardTemplate } from './templates'
import { Card } from '../card'
import './style.scss'

export function GenericCard({ className, template = 'small', onClick, dataCy, ...rest }) {
  function renderTemplate() {
    return GenericCardTemplate({ onClick, ...rest })[template]
  }

  return (
    <Card
      className={`generic-card-component ${className || ''} -${template}`}
      onClick={onClick}
      dataCy={dataCy}
    >
      {renderTemplate()}
    </Card>
  )
}
