import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { ContractSelect } from 'pages'
import { ContextLoader } from 'components'

export function RouteWrapper({ privateRoute, component, path, ignoreSelectContract, ...props }) {
  const { keycloak, initialized } = useKeycloak()
  const { selected: selectedContract } = useSelector(({ contract }) => contract)
  const renderPage = ignoreSelectContract || !!selectedContract

  if (!initialized) {
    return <ContextLoader />
  }

  if (!privateRoute) {
    return <Route component={component} {...props} />
  }

  if (!keycloak.authenticated) {
    keycloak.login()
    return null
  }
  if (!localStorage.getItem('SUMICITY_SESSION_TIME'))
    localStorage.setItem('SUMICITY_SESSION_TIME', Date.now())
  return <Route component={renderPage ? component : ContractSelect} {...props} />
}
