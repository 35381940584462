import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button } from 'components/button'
import { setModal } from 'slices/modal'
import { STATICS } from 'statics'
import './style.scss'

export function PasswordRecoveryModal() {
  const { push } = useHistory()
  const dispatch = useDispatch()

  function handleClick() {
    push('/')
    dispatch(setModal({ key: '' }))
  }

  return (
    <div className='password-recovery-modal'>
      <div className='round'>
        <div className='icon'>
          <STATICS.ROUNDED_LOCK />
        </div>
      </div>
      <h3 className='title'>Enviamos mais informações para seu e-mail</h3>
      <p className='text'>
        Acesse seu e-mail e siga as instruções para redefinir sua senha de acesso.
      </p>
      <Button className='button' color='primary' size='large' onClick={handleClick}>
        Ok, entendi
      </Button>
    </div>
  )
}
