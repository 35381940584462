export const ADDRESS_CHANGE_STAGES = {
  INITIAL: 'initial',
  CONDOMINIUM: 'condominium',
  SUCCESS: 'success',
  ERROR: 'error',
  ADDRESS_INVIABILITY: 'address_inviability',
  CONDOMINIUM_NOT_REGISTERED: 'condominium_not_registered',
  OPEN_INVOICES: 'open_invoices',
  UNAVAILABLE_FEATURE: 'unavailable_feature',
  SERVICE_ORDER_STATUS: 'service_order_status',
  BOOKING: 'booking',
  CONFIRMATION: 'confirmation',
  RESCHEDULE: 'reschedule',
  RESCHEDULE_CONFIRMATION: 'reschedule_confirmation',
  RESCHEDULE_SUCCESS: 'reschedule_success',
  CANCEL_CONFIRMATION: 'cancel_confirmation',
  CANCEL_SUCCESS: 'cancel_success',
}
