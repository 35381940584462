import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STATICS } from 'statics'
import { Button, ContextLoader } from 'components'
import { postConfirmeServiceSchedule } from 'hooks'
import { formatDate } from 'utils'
import { DRAWER_KEY, SERVICE_ORDER_LABEL, SERVICE_ORDER_TYPES } from 'app-constants'
import { setPendingConfirmation } from 'slices/serviceOrder'
import { setDrawer, setDrawerAlert } from 'slices/drawer'
import { SERVICE_ORDER_CONFIRMATION_ERROR } from '../../constants'
import './style.scss'

export function ConfirmScheduleInfo() {
  const [loading, setLoading] = useState(false)
  const { city, neighborhood, number, street, uf, zipCode } = useSelector(
    state => state.contract.selected.address
  )
  const {
    pendingConfirmation: {
      osId,
      expectedInstallationDate,
      serviceOrderType,
      expectedInstallationTurn,
      expectedAttendancePeriod,
    },
  } = useSelector(state => state.serviceOrder)
  const dispatch = useDispatch()

  async function handleConfirmClick() {
    setLoading(true)
    try {
      await postConfirmeServiceSchedule(osId, expectedInstallationDate)
      dispatch(setDrawer(DRAWER_KEY.NONE))
      dispatch(setPendingConfirmation(null))
    } catch (error) {
      console.error(error)
      dispatch(setDrawerAlert({ severity: 'error', title: SERVICE_ORDER_CONFIRMATION_ERROR }))
    } finally {
      setLoading(false)
    }
  }

  function redirectDrawer(drawer) {
    dispatch(setDrawer(drawer))
  }

  function handleRescheduleClick() {
    redirectDrawer(DRAWER_KEY.ACTIVATION_PENDING)
  }

  function handleManageVisitClick() {
    if (serviceOrderType === SERVICE_ORDER_TYPES.REPAIR) {
      redirectDrawer(DRAWER_KEY.VIRTUAL_SUPPORT)
    }
    if (serviceOrderType === SERVICE_ORDER_TYPES.ADDRESS_CHANGE) {
      redirectDrawer(DRAWER_KEY.ADDRESS_CHANGE)
    }
  }

  function renderSecondaryButton() {
    if (serviceOrderType === SERVICE_ORDER_TYPES.ACTIVATION)
      return <Button onClick={handleRescheduleClick}>Reagendar Visita</Button>

    return <Button onClick={handleManageVisitClick}>Gerenciar Visita</Button>
  }

  function renderServiceInfo() {
    return (
      <p>
        Lembrando que a solicitação para{' '}
        <strong className='service-order-type'>{SERVICE_ORDER_LABEL[serviceOrderType]}</strong>{' '}
        ocorrerá no dia:
      </p>
    )
  }

  function renderScheduledDate() {
    return (
      <p>
        <strong>{formatDate(expectedInstallationDate)}</strong> no período{' '}
        <strong>{expectedInstallationTurn}</strong> ({expectedAttendancePeriod})
      </p>
    )
  }

  function renderAddresssFormatted() {
    const fullAddress = `${street}, ${number} - ${neighborhood}, ${city}`
    return fullAddress.toLowerCase()
  }

  function renderAddressContainer() {
    return (
      <p className='confirm-schedule-address'>
        <strong>Endereço:</strong> {renderAddresssFormatted()} - {uf}
        <div>
          <span className='zip-label'>CEP:</span> {zipCode}
        </div>
      </p>
    )
  }

  function renderInformationText() {
    if (serviceOrderType === SERVICE_ORDER_TYPES.REPAIR) {
      return 'Seu sinal voltou? Se sim, você pode cancelar a visita'
    }

    return 'OBS: Será necessário a presença de um maior de idade para acompanhar nossa equipe técnica'
  }

  if (loading) return <ContextLoader fixed={false} />

  return (
    <div className='confirm-schedule-info'>
      <div className='icon-container'>
        <div className='icon'>
          <STATICS.CONTACT_EMAIL />
        </div>
      </div>
      <div className='content'>
        {renderServiceInfo()}
        {renderScheduledDate()}
        {renderAddressContainer()}
        <p>{renderInformationText()}</p>
      </div>
      <div className='actions'>
        <Button onClick={handleConfirmClick} color='primary'>
          Confirmar Visita
        </Button>
        {renderSecondaryButton()}
      </div>
    </div>
  )
}
