import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { STATICS } from 'statics'
import { setDrawer } from 'slices/drawer'
import './style.scss'

export function CancelSuccess() {
  const dispatch = useDispatch()

  function handleContinue() {
    dispatch(setDrawer(''))
  }

  function renderButton() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => {
            handleContinue()
          }}
        >
          Voltar para home
        </Button>
      </div>
    )
  }

  return (
    <div className='cancel-success'>
      <div className='content'>
        <STATICS.SUCCESS className='icon' />
        <h3 className='title'>{'Sua solicitação foi\n cadastrada com sucesso!'}</h3>
      </div>
      {renderButton()}
    </div>
  )
}
