import React from 'react'
import { ROUTES } from 'app-constants'
import { PasswordRecovery } from 'components'

export function PasswordRecoveryDocument() {
  return (
    <PasswordRecovery
      title='Esqueceu sua senha?'
      subtitle='Informe seu documento abaixo para enviarmos um código de redefinição de senha para o seu celular e e-mail cadastrados'
      buttonLabel='Redefinir senha'
      nextStep={ROUTES.VALIDATE_TOKEN.PATH}
    />
  )
}
