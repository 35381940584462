import React from 'react'
import { useDispatch } from 'react-redux'
import { GenericCard } from 'components'
import { setModal } from 'slices/modal'

export function FileUploadWhatsappCard({ className, title, buttonLabel, Icon, description }) {
  const dispatch = useDispatch()

  function handleFileUpload() {
    dispatch(setModal({ key: 'file_upload_whatsapp' }))
  }

  return (
    <GenericCard
      className={className}
      title={title}
      buttonLabel={buttonLabel}
      Icon={Icon}
      onClick={handleFileUpload}
    >
      {description}
    </GenericCard>
  )
}
