import React from 'react'
import { useSelector } from 'react-redux'
import { Copy, QrCode } from './components'
import { PIX_MODAL_STAGES } from './constants'

function stages({ ...props }) {
  return {
    [PIX_MODAL_STAGES.INITIAL]: <QrCode {...props} />,
    [PIX_MODAL_STAGES.COPY]: <Copy {...props} />,
  }
}

export function PixModal() {
  const { stage, data } = useSelector(({ modal }) => modal)

  return stages({ data })[stage]
}
