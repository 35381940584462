import React from 'react'
import { ROUTES } from 'app-constants'
import { ValidateToken } from 'components'

export function PasswordValidateToken() {
  return (
    <ValidateToken
      title='Esqueceu sua senha?'
      nextStep={ROUTES.PASSWORD_RECOVERY.PATH}
      prevStep={ROUTES.FORGOT_PASSWORD.PATH}
    />
  )
}
