import React from 'react'
import './style.scss'

export function Card({ className, children, onClick, thin = false, dataCy }) {
  return (
    <div
      className={`card-component ${className || ''} ${thin ? '-thin' : ''}`}
      onClick={onClick}
      data-testid='card-component'
      data-cy={dataCy}
    >
      {children}
    </div>
  )
}
