import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { FIREBASE_EVENTS, VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import { Button, RadioButton, Card } from 'components'
import { useFirebase } from 'hooks'
import { SERVICES } from './constants'
import './style.scss'

export function SelectServiceStage() {
  const [selected, setSelected] = useState('')
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()

  function handleClickService(key) {
    setSelected(key)
  }

  function handleClickActionFoward() {
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_SELECT_SERVICE)
    dispatch(setStage(VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
  }

  function handleClickActionBack() {
    dispatch(setStage(VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS))
  }

  function renderServiceOptions() {
    return SERVICES.map(({ LABEL, KEY }) => {
      const isSelected = selected === KEY
      return (
        <Card
          onClick={() => handleClickService(KEY)}
          key={KEY}
          className={`container-card ${isSelected ? '-selected' : ''}`}
        >
          <RadioButton id={KEY} checked={isSelected} />
          <span className='option-text'>{LABEL}</span>
        </Card>
      )
    })
  }

  return (
    <div className='container-select-service'>
      <div className='container-info'>
        <span className='title'>Para qual serviço você deseja suporte?</span>
        <div className='container-services'>{renderServiceOptions()}</div>
      </div>
      <div className='container-button'>
        <Button
          onClick={handleClickActionFoward}
          className='button confirm'
          color='primary'
          size='large'
        >
          Confirmar
        </Button>
        <Button
          onClick={handleClickActionBack}
          className='button'
          color='primary'
          size='large'
          outlined
        >
          Cancelar
        </Button>
      </div>
    </div>
  )
}
