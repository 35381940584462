import { FULL_TIMER, ONE_MINUTE, PLAN_STATUS_KEY, WIFI_TIMER_KEY } from 'app-constants'
import { currentInterval, formatDateTime } from './date'

export function setWifiTimerInitialDate() {
  const wifiTimerInitialDate = formatDateTime(new Date())
  localStorage.setItem(WIFI_TIMER_KEY, JSON.stringify(wifiTimerInitialDate))
}

export function removeWifiTimer() {
  localStorage.removeItem(WIFI_TIMER_KEY)
}

export function getCurrentWifiTimer() {
  const wifiTimerInitialDate = JSON.parse(localStorage.getItem(WIFI_TIMER_KEY))

  if (wifiTimerInitialDate) {
    const { minutes, seconds } = currentInterval(wifiTimerInitialDate)
    const convertedMinutes = minutes * ONE_MINUTE
    const fullIntervalInSeconds = convertedMinutes + seconds
    const countdownTimer = FULL_TIMER - fullIntervalInSeconds

    if (countdownTimer < 0) return 0

    return countdownTimer
  }
}

export function shouldRenderWifiInfo(contractStatus, internetCanBeChanged) {
  if (!internetCanBeChanged) return false

  const isContractInActivation = contractStatus === PLAN_STATUS_KEY.ST_CONT_EM_ATIVACAO
  return !isContractInActivation
}
