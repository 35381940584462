import { useSelector } from 'react-redux'
import { UnlockAccount, UnlockAccountError } from './components'
import { UNLOCK_ACCOUNT_STAGES } from './constants'

function stages() {
  return {
    [UNLOCK_ACCOUNT_STAGES.INITIAL]: <UnlockAccount />,
    [UNLOCK_ACCOUNT_STAGES.ERROR]: <UnlockAccountError />,
  }
}

export function UnlockAccountDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages()[stage]
}
