import React from 'react'
import './style.scss'

export function SelectInput({
  className,
  onChange = () => {},
  name,
  disabled,
  children,
  ...props
}) {
  return (
    <div className='input-component'>
      <select disabled={disabled} name={name} className={className} onChange={onChange} {...props}>
        {children}
      </select>
    </div>
  )
}
