import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CONTRACT_SITUATION,
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
  SERVICE_ORDER_TYPES,
  STATUS_CODE,
  VIRTUAL_SUPPORT_STAGES,
} from 'app-constants'
import { Button, ContextLoader } from 'components'
import { getServiceOrderDetails, useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { setDrawer, setDrawerAlert, setStage } from 'slices/drawer'
import { STEPS } from '../../constants'
import './style.scss'

export function AutoSupportInfo({ serviceOrder, setServiceOrder }) {
  const [loading, setLoading] = useState(true)
  const { sendEvent } = useFirebase()
  const {
    selected: { id, situation },
  } = useSelector(({ contract }) => contract)
  const shouldHideContinueButton = situation !== CONTRACT_SITUATION.ENABLED

  const dispatch = useDispatch()

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function handleContinue() {
    const stageToContinue = serviceOrder
      ? VIRTUAL_SUPPORT_STAGES.SERVICE_ORDER
      : VIRTUAL_SUPPORT_STAGES.TALK_TO_ATTENDANT
    dispatch(setStage(stageToContinue))
  }

  useEffect(() => {
    async function loadServiceOrder() {
      try {
        const { data } = await getServiceOrderDetails(id, SERVICE_ORDER_TYPES.REPAIR)
        setServiceOrder(data)
      } catch (error) {
        const controledErrors =
          error?.response?.status === STATUS_CODE.NOT_FOUND ||
          error?.response?.status === STATUS_CODE.BAD_REQUEST
        if (controledErrors) {
          console.error(error)
        } else {
          dispatch(
            setDrawerAlert({
              severity: 'error',
              title: error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
            })
          )
        }
      } finally {
        setLoading(false)
      }
    }

    loadServiceOrder()
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_CHECK_CONNECTION)
  }, [dispatch, sendEvent, id, setServiceOrder])

  function renderButtons() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => {
            handleClose()
          }}
        >
          Meu problema foi solucionado!
        </Button>
        {!shouldHideContinueButton && (
          <Button
            className='button'
            color='primary'
            size='large'
            outlined
            onClick={() => handleContinue()}
          >
            Ainda preciso de suporte técnico
          </Button>
        )}
      </div>
    )
  }

  function renderSteps() {
    return (
      <div className='steps-content'>
        {STEPS.map(item => (
          <div key={item.key} className='flex-row'>
            {item.icon}
            <p className='steps'>{item.message}</p>
          </div>
        ))}
      </div>
    )
  }

  if (loading) return <ContextLoader fixed={false} />

  return (
    <div className='virtual-support-info'>
      <div className='content'>
        <div className='round'>
          <STATICS.MODEM className='icon' />
        </div>
        <h3 className='title'>{'Está com problemas no\n seu modem?'}</h3>
        <p className='text'>Caro cliente, antes de prosseguirmos:</p>
        {renderSteps()}
      </div>
      {renderButtons()}
    </div>
  )
}
