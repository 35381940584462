export const SCHEDULING_SHIFT_LABEL = {
  MORNING: 'Manhã',
  AFTERNOON: 'Tarde',
}

export const SCHEDULE_TYPE = {
  TURN: 'TURN',
  TIME_FRAME: 'TIME_FRAME',
}

export const SHIFTS = {
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
}

export const SHIFT_TIME_FRAME_LABEL = {
  MORNING: '08h às 12h',
  AFTERNOON: 'a partir das 13h',
}
