/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  key: '',
  locked: false,
  stage: 'initial',
  history: [],
  shouldOpenContactModal: false,
  data: {},
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.key = action.payload.key
      state.locked = action.payload.locked
      state.stage = 'initial'
      state.history = []
      state.data = action.payload.data
    },
    setStage: (state, action) => {
      state.history.push(state.stage)
      state.stage = action.payload
    },
    goBack: state => {
      const stage = state.history.pop()
      state.stage = stage
    },
    setShouldOpenContactModal: (state, action) => {
      state.shouldOpenContactModal = action.payload
    },
  },
})

export const { setModal, setStage, goBack, setShouldOpenContactModal } = modalSlice.actions

export default modalSlice.reducer
