import React from 'react'
import { ROUTES } from 'app-constants'
import { PasswordResetForm } from 'components'

export function EmailPasswordReset() {
  return (
    <PasswordResetForm
      title='Crie sua nova senha'
      subtitle='Crie sua nova senha de acesso:'
      prevStep={ROUTES.FORGOT_PASSWORD.PATH}
    />
  )
}
