import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearHistory, setDrawer, setDrawerAlert, setStage } from 'slices/drawer'
import { setPendingConfirmation } from 'slices/serviceOrder'
import {
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
  SERVICE_ORDER_TYPES,
  SO_TYPES,
  VIRTUAL_SUPPORT_STAGES,
} from 'app-constants'
import { BackButton, Button, ContextLoader, TrackingButton } from 'components'
import { getServiceOrderDetails, useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { formatDate, formatTurn } from 'utils'
import './style.scss'

export function ServiceOrderInfo({ serviceOrder, setServiceOrder }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const {
    selected: { id },
  } = useSelector(({ contract }) => contract)

  useEffect(() => {
    async function loadServiceOrder() {
      try {
        if (!serviceOrder) {
          const { data } = await getServiceOrderDetails(id, SERVICE_ORDER_TYPES.REPAIR)
          if (data) {
            setServiceOrder(data)
          } else {
            dispatch(setPendingConfirmation(null))
            dispatch(setStage(VIRTUAL_SUPPORT_STAGES.INITIAL))
            dispatch(clearHistory())
          }
        }
      } catch (error) {
        console.log(error)
        dispatch(
          setDrawerAlert({
            severity: 'error',
            title: error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
          })
        )
      }
    }

    loadServiceOrder()
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_PENDING_REPAIR_ORDER)
  }, [sendEvent, dispatch, serviceOrder, id, setServiceOrder])

  function handleReschedule() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SERVICE_ORDER_RESCHEDULE)
    dispatch(setStage(VIRTUAL_SUPPORT_STAGES.RESCHEDULING_SERVICE_ORDER))
  }

  function handleCancel() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SERVICE_ORDER_CANCEL)
    dispatch(setStage(VIRTUAL_SUPPORT_STAGES.CANCEL_CONFIRMATION))
  }

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function renderInfosCard() {
    return (
      <div className='card-infos'>
        <div className='tag-status'>
          <p className='status'>{serviceOrder.type}</p>
        </div>
        <div className='infos'>
          <p className='info'>
            Ordem de serviço: <span>#{serviceOrder.osId}</span>
          </p>
          <p className='info'>
            Data de agendamento: <span>{formatDate(serviceOrder.expectedInstallationDate)}</span>
          </p>
          <p className='info'>
            Turno: <span>{formatTurn(serviceOrder.expectedInstallationTurn)}</span>
          </p>
          {serviceOrder.technician && (
            <p className='info'>
              Técnico: <span>{serviceOrder.technician}</span>
            </p>
          )}
        </div>
      </div>
    )
  }

  function renderButtons() {
    const osIsFinished = serviceOrder.type === SO_TYPES.FINISHED

    if (osIsFinished) {
      return (
        <Button className='button' color='primary' size='large' onClick={handleClose}>
          Ok, entendi!
        </Button>
      )
    }

    return (
      <>
        <TrackingButton osId={serviceOrder.osId} />
        {serviceOrder.isDateAbleToReschedule && (
          <>
            <Button
              className='button'
              color='primary'
              size='large'
              outlined
              onClick={handleReschedule}
            >
              Reagendar visita
            </Button>
            <Button className='button' color='primary' size='large' outlined onClick={handleCancel}>
              Cancelar visita
            </Button>
          </>
        )}
        <BackButton className='button' />
      </>
    )
  }

  if (!serviceOrder) return <ContextLoader fixed={false} />

  return (
    <div className='virtual-support-info'>
      <div className='content'>
        <div className='round'>
          <STATICS.INFO_OUTLINE className='icon-info' />
        </div>
        <h3 className='title'>{`Você possui uma solicitação de reparo ${
          serviceOrder.conclusionReason ? '' : 'em aberto'
        }`}</h3>
        {renderInfosCard()}
      </div>
      <div className='links'>{renderButtons()}</div>
    </div>
  )
}
