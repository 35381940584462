export const EVENTS = {
  PAYMENT_HISTORY_CONSULTATION: 'PAYMENT_HISTORY_CONSULTATION',
  PAYMENT_HISTORY_BANK_BILLET_DOWNLOAD: 'PAYMENT_HISTORY_BANK_BILLET_DOWNLOAD',
  PAYMENT_HISTORY_INVOICE_DOWNLOAD: 'PAYMENT_HISTORY_INVOICE_DOWNLOAD',
  PHONE_STATEMENT_CONSULTATION: 'PHONE_STATEMENT_CONSULTATION',
  PHONE_HISTORY_DETAILING_DOWNLOAD: 'PHONE_HISTORY_DETAILING_DOWNLOAD',
  GET_BANK_BILLET_COPY_ON_EMAIL_SMS: 'GET_BANK_BILLET_COPY_ON_EMAIL_SMS',
  DOWNLOAD_CURRENT_BANK_BILLET: 'DOWNLOAD_CURRENT_BANK_BILLET',
  DIGITABLE_LINE_COPY: 'DIGITABLE_LINE_COPY',
  UNLOCK_BY_TRUST: 'UNLOCK_BY_TRUST',
  WIFI_UPDATE: 'WIFI_UPDATE',
  PASSWORD_UPDATE: 'PASSWORD_UPDATE',
  EMAIL_UPDATE: 'EMAIL_UPDATE',
  PHONE_UPDATE: 'PHONE_UPDATE',
  COLLECT_CALLS_ENABLE_DISABLE: 'COLLECT_CALLS_ENABLE_DISABLE',
  FAQ_CONSULTATION: 'FAQ_CONSULTATION',
  CLICK_ON_APP_DOWNLOADS: 'CLICK_ON_APP_DOWNLOADS',
  CLICK_ON_FOOTER_APP_DOWNLOADS_GOOGLE_STORE: 'CLICK_ON_FOOTER_APP_DOWNLOADS_GOOGLE_STORE',
  CLICK_ON_FOOTER_APP_DOWNLOADS_APPLE_STORE: 'CLICK_ON_FOOTER_APP_DOWNLOADS_APPLE_STORE',
  CLICK_ON_OUVIDORIA: 'CLICK_ON_OUVIDORIA',
  CLICK_ON_HERO: 'CLICK_ON_HERO',
  CLICK_ON_FRIEND_REFER: 'CLICK_ON_FRIEND_REFER',
  CLICK_ON_VIRTUAL_SUPPORT: 'CLICK_ON_VIRTUAL_SUPPORT',
  CLICK_ON_DOWNLOAD_SELF_SUPPORT_DOCUMENT: 'CLICK_ON_DOWNLOAD_SELF_SUPPORT_DOCUMENT',
  CLICK_ON_DOWNLOAD_CONTRACT_DOCUMENT: 'CLICK_ON_DOWNLOAD_CONTRACT_DOCUMENT',
  CLICK_ON_MY_SERVICES: 'CLICK_ON_MY_SERVICES',
  SEND_FILES: 'SEND_FILES',
  CONTRACT_DUE_DATE_UPDATE: 'CONTRACT_DUE_DATE_UPDATE',
  CLICK_ON_NOTIFICATION_CENTER: 'CLICK_ON_NOTIFICATION_CENTER',
  CLICK_ON_VIEW_PLAN_DETAILS: 'CLICK_ON_VIEW_PLAN_DETAILS',
  REGISTRATION_UPDATE: '_REGISTRATION_UPDATE',
  ABNORMALITY_WARNING: 'ABNORMALITY_WARNING',
  CLICK_ON_TECHNICAL_SUPPORT: 'CLICK_ON_TECHNICAL_SUPPORT',
  TECHNICAL_SUPPORT_CHECKLIST_OK: 'TECHNICAL_SUPPORT_CHECKLIST_OK',
  SCHEDULE_REPAIR: 'SCHEDULE_REPAIR',
  RESCHEDULE_REPAIR: 'RESCHEDULE_REPAIR',
  CONSULT_REPAIR_SCHEDULE: 'CONSULT_REPAIR_SCHEDULE',
  START_SUPPORT_CHAT: 'START_SUPPORT_CHAT',
  CONSULT_ACTIVATION_OS: 'CONSULT_ACTIVATION_OS',
  RESCHEDULE_ACTIVATION: 'RESCHEDULE_ACTIVATION',
  CLICK_ON_DOWNLOAD_OWNERSHIP_CHANGE_DOCUMENT: 'CLICK_ON_DOWNLOAD_OWNERSHIP_CHANGE_DOCUMENT',
  CLICK_ON_DOWNLOAD_ACCEPTANCE_TERM_DOCUMENT: 'CLICK_ON_DOWNLOAD_ACCEPTANCE_TERM_DOCUMENT',
  CLICK_ON_DOWNLOAD_SUBSCRIPTION_TRANSFER_DOCUMENT:
    'CLICK_ON_DOWNLOAD_SUBSCRIPTION_TRANSFER_DOCUMENT',
  TALK_ATTENDANT_ON_SUBMISSION_PAYMENT: 'TALK_ATTENDANT_ON_SUBMISSION_PAYMENT',
  TALK_ATTENDANT_ON_UNAVAILABLE_BILLET: 'TALK_ATTENDANT_ON_UNAVAILABLE_BILLET',
  PASSWORD_UPDATE_FIRST_ACCESS: 'PASSWORD_UPDATE_FIRST_ACCESS',
  LOGIN: 'LOGIN',
  CLICK_ON_CONTRACT_SELECTION: 'CLICK_ON_CONTRACT_SELECTION',
  CLICK_ON_UNLOCK_BY_TRUST: 'CLICK_ON_UNLOCK_BY_TRUST',
  CLICK_ON_OK_UNLOCK_BY_TRUST: 'CLICK_ON_OK_UNLOCK_BY_TRUST',
  CLICK_ON_WIFI_PASSWORD: 'CLICK_ON_WIFI_PASSWORD',
  CLICK_ON_REGISTRATION_DATA: 'CLICK_ON_REGISTRATION_DATA',
  CLICK_ON_CALLS: 'CLICK_ON_CALLS',
  CLICK_ON_CURRENT_INVOICE: 'CLICK_ON_CURRENT_INVOICE',
  CLICK_ON_DUE_DATE: 'CLICK_ON_DUE_DATE',
  CLICK_ON_UNDERSTAND_INVOICE: 'CLICK_ON_UNDERSTAND_INVOICE',
  PAYMENT_HISTORY_INVOICE_CONSULTATION: 'PAYMENT_HISTORY_INVOICE_CONSULTATION',
  CLICK_ON_GLOBOPLAY_LINK: 'CLICK_ON_GLOBOPLAY_LINK',
  CLICK_ON_TELECINE_LINK: 'CLICK_ON_TELECINE_LINK',
  CLICK_ON_GLOBO_PLAY_MAIS_CANAIS_LINK: 'CLICK_ON_GLOBO_PLAY_MAIS_CANAIS_LINK',
  CLICK_ON_SERVICE_MENU: 'CLICK_ON_SERVICE_MENU',
  CLICK_ON_PREMIERE_LINK: 'CLICK_ON_PREMIERE_LINK',
  CLICK_ON_SVA_GIGA_TV: 'CLICK_ON_SVA_GIGA_TV',
  CLICK_ON_NEGOTIATION: 'CLICK_ON_NEGOTIATION',
  CLICK_ON_CONFIRM_NEGOTIATION: 'CLICK_ON_CONFIRM_NEGOTIATION',
  CLICK_ON_SPEED_TEST: 'CLICK_ON_SPEED_TEST',
  CLICK_ON_CANCEL_OS: 'CLICK_ON_CANCEL_OS',
  CLICK_ON_PIX_PAYMENT: 'CLICK_ON_PIX_PAYMENT',
  CLICK_ON_CREDIT_CARD_PAYMENT: 'CLICK_ON_CREDIT_CARD_PAYMENT',
}

export const SESSION_KEY = 'SESSION_ID'

export const DEFAULT_DOCUMENT = 'EMPTY'

export const DEFAULT_ORIGIN = 'WEB'

const USER_JOURNEY = {
  CHANGE_DUE_DATE: 'ALTERAR VENCIMENTO',
  LOGIN: 'LOGIN',
  PAYMENT: 'PAGAMENTO',
  CONTRACTS: 'CONTRATOS',
  ADDRESS_CHANGE: 'MUDANÇA DE ENDEREÇO',
  REGISTER_DATA: 'DADOS CADASTRAIS',
  CALLS: 'LIGAÇÕES',
  SUPPORT: 'SUPORTE',
  WIFI_UPDATE: 'CONFIGURAR WI-FI',
  NOTIFICATION: 'CENTRAL DE NOTIFICAÇÕES',
  ACCOUNT_INFO: 'INFORMAÇÕES DA CONTA',
  FILE: 'ENVIO DE ARQUIVO',
  REFERER_FRIEND: 'INDIQUE UM AMIGO',
}

const USER_FLOW = {
  CHANGE_DUE_DATE: 'ALTERAR VENCIMENTO DA FATURA',
  FORGOT_PASSWORD: 'ESQUECI MINHA SENHA',
  MY_BILLETS: 'MINHAS FATURAS',
  NEGOTIATION: 'NEGOCIAÇÃO',
  CALLS: 'LIGAÇÕES',
  CONNECTION_PROBLEM: 'PROBLEMAS COM A CONEXÃO',
  WIFI_UPDATE: 'CONFIGURAÇÃO DO WI-FI',
  ADDRESS_CHANGE: 'MUDANÇA DE ENDEREÇO',
  NOTIFICATION: 'NOTIFICAÇÃO',
  MY_PHONES: 'MEUS TELEFONES',
  ADD_PHONE: 'MEUS TELEFONES ADICIONAR TELEFONE',
  REMOVE_PHONE: 'MEUS TELEFONES REMOVER TELEFONE',
  ALTER_PHONE: 'MEUS TELEFONES ALTERAR TELEFONE PRINCIPAL',
  MY_EMAILS: 'MEUS EMAILS',
  ADD_EMAIL: 'MEUS EMAILS ADICIONAR EMAIL',
  DELETE_EMAIL: 'MEUS EMAILS REMOVER EMAIL',
  ALTER_MAIN_EMAIL: 'MEUS EMAILS ALTERAR EMAIL PRINCIPAL',
  CHANGE_SAC_PASSWORD: 'ALTERAR SENHA SAC',
  FAQ: 'PERGUNTAS FREQUENTES FAQ',
  SELECT_CONTRACT: 'SELECIONAR CONTRATO',
  SPEED_TEST: 'TESTE DE VELOCIDADE',
  DIGITAL_SERVICES: 'SERVIÇOS DIGITAIS',
  DIGITAL_SERVICES_ACTIVATION: 'SERVIÇOS DIGITAIS ATIVAÇÃO',
  FILE: 'ENVIO DE ARQUIVO',
  ACCOUNT_UNLOCK: 'DESBLOQUEIO DE CONTA',
  REFERER_FRIEND: 'INDIQUE UM AMIGO',
  SERVICE_ORDER: 'ORDENS DE SERVIÇO',
  SERVICE_ORDER_RESCHEDULE: 'ORDENS DE SERVIÇO REAGENDAR',
  SERVICE_ORDER_CANCEL: 'ORDENS DE SERVIÇO CANCELAR',
  SERVICE_ORDER_TRACK: 'RASTREIO',
  CHAT_SERVICE: 'ATENDIMENTO',
}

export const EVENT_STATUS = {
  SUCCESS: 'SUCESSO',
  ERROR: 'ERRO',
}

export const FIREBASE_EVENTS = {
  CLICK_ON_FORGOT_PASSWORD: {
    title: 'CLICK_ON_FORGOT_PASSWORD',
    label: 'Esqueci minha senha',
    jornada: USER_JOURNEY.LOGIN,
    fluxo: USER_FLOW.FORGOT_PASSWORD,
    ordem: 1,
  },
  SEND_DOCUMENT_FORGOT_PASSWORD: {
    title: 'SEND_DOCUMENT_FORGOT_PASSWORD',
    label: 'informou o documento',
    jornada: USER_JOURNEY.LOGIN,
    fluxo: USER_FLOW.FORGOT_PASSWORD,
    ordem: 2,
  },
  CONFIRM_TOKEN_FORGOT_PASSWORD: {
    title: 'CONFIRM_TOKEN_FORGOT_PASSWORD',
    label: 'confirmou o token',
    jornada: USER_JOURNEY.LOGIN,
    fluxo: USER_FLOW.FORGOT_PASSWORD,
    ordem: 3,
  },
  RESEND_TOKEN_FORGOT_PASSWORD: {
    title: 'RESEND_TOKEN_FORGOT_PASSWORD',
    label: 'reenviar token',
    jornada: USER_JOURNEY.LOGIN,
    fluxo: USER_FLOW.FORGOT_PASSWORD,
    ordem: 3,
  },
  CONFIRM_TOKEN_ERROR_FORGOT_PASSWORD: {
    title: 'CONFIRM_TOKEN_ERROR_FORGOT_PASSWORD',
    label: 'falha ao confirmar o token',
    jornada: USER_JOURNEY.LOGIN,
    fluxo: USER_FLOW.FORGOT_PASSWORD,
    ordem: 3,
  },
  ON_CHANGE_PASSWORD: {
    title: 'ON_CHANGE_PASSWORD',
    label: 'alterou a senha',
    jornada: USER_JOURNEY.LOGIN,
    fluxo: USER_FLOW.FORGOT_PASSWORD,
    ordem: 4,
  },
  ON_CHANGE_PASSWORD_SUCCESS: {
    title: 'ON_CHANGE_PASSWORD_SUCCESS',
    label: 'senha alterada com sucesso',
    jornada: USER_JOURNEY.LOGIN,
    fluxo: USER_FLOW.FORGOT_PASSWORD,
    ordem: 5,
  },
  ON_CHANGE_PASSWORD_ERROR: {
    title: 'ON_CHANGE_PASSWORD_ERROR',
    label: 'falha ao alterar a senha',
    jornada: USER_JOURNEY.LOGIN,
    fluxo: USER_FLOW.FORGOT_PASSWORD,
    ordem: 5,
  },
  CLICK_DOWNLOAD_BILLET: {
    title: 'ON_DOWNLOAD_BILLET',
    label: 'Download da Fatura',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 1,
  },
  CLICK_PIX_PAYMENT_BILLET: {
    title: 'ON_PIX_PAYMENT_BILLET',
    label: 'Pagar com PIX',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 1,
  },
  CLICK_COPY_BILLET_CODE: {
    title: 'ON_COPY_BILLET_CODE',
    label: 'Copiar codigo',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 1,
  },
  CLICK_CREDIT_CARD_BILLET_PAYMENT: {
    title: 'ON_CREDIT_CARD_BILLET_PAYMENT',
    label: 'Pagar com cartão',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 1,
  },
  CLICK_SEE_BILLETS: {
    title: 'CLICK_SEE_BILLETS',
    label: 'Ver todas as faturas',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 1,
  },
  CLICK_PIX_COPY_CODE: {
    title: 'CLICK_PIX_COPY_CODE',
    label: 'Copiar código PIX',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 1,
  },
  CLICK_DOWNLOAD_BILLETS_LIST: {
    title: 'ON_DOWNLOAD_BILLET',
    label: 'Baixar faturas',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 2,
  },
  CLICK_PIX_PAYMENT_LIST: {
    title: 'ON_PIX_PAYMENT_BILLET',
    label: 'Pagar com Pix',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 2,
  },
  CLICK_COPY_BILLET_CODE_LIST: {
    title: 'ON_COPY_BILLET_CODE',
    label: 'Copiar código de barras',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 2,
  },
  CLICK_CREDIT_CARD_LIST: {
    title: 'ON_CREDIT_CARD_BILLET_PAYMENT',
    label: 'Pagar com cartão',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 2,
  },
  CLICK_ON_INVOICES: {
    title: 'CLICK_ON_INVOICES',
    label: 'Notas fiscais',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 2,
  },
  CLICK_ON_PAYMENT_HISTORY: {
    title: 'CLICK_ON_PAYMENT_HISTORY',
    label: 'Histórico de pagamento',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 2,
  },
  CLICK_ON_BILLET_NEGOTIATION: {
    title: 'CLICK_ON_BILLET_NEGOTIATION',
    label: 'Negociar faturas',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 1,
  },
  BILLET_NEGOTIATION_ILEGIBLE: {
    title: 'NEGOTIATION_ILEGIBLE',
    label: 'Não elegível para negociação',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 2,
  },
  BILLET_NEGOTIATION_LIST: {
    title: 'BILLET_NEGOTIATION_LIST',
    label: 'Listar faturas',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 3,
  },
  BILLET_NEGOTIATION: {
    title: 'BILLET_NEGOTIATION',
    label: 'Negociar faturas',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 4,
  },
  CLICK_ON_BILLET_NEGOTIATION_REJECT_NEGOTIATION: {
    title: 'click_on_BILLET_NEGOTIATION_REJECT_NEGOTIATION',
    label: 'Rejeitar negociação',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 4,
  },
  CLICK_ON_BILLET_NEGOTIATION_ACCEPT: {
    title: 'CLICK_ON_BILLET_NEGOTIATION_ACCEPT',
    label: 'Aceitar nova proposta',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 4,
  },
  CLICK_ON_BILLET_NEGOTIATION_REJECT: {
    title: 'CLICK_ON_BILLET_NEGOTIATION_REJECT',
    label: 'Recusar nova proposta',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 4,
  },
  CLICK_ON_BILLET_NEGOTIATION_CONFIRM: {
    title: 'CLICK_ON_BILLET_NEGOTIATION_CONFIRM',
    label: 'Confirmar negociação',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 5,
  },
  BILLET_NEGOTIATION_SUCCESS: {
    title: 'BILLET_NEGOTIATION_SUCCESS',
    label: 'Negociação confirmada',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 6,
  },
  BILLET_NEGOTIATION_ERROR: {
    title: 'BILLET_NEGOTIATION_ERROR',
    label: 'Falha na negociação',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 6,
  },
  CLICK_ON_SEE_BILLETS: {
    title: 'CLICK_ON_SEE_BILLETS',
    label: 'Ver faturas negociadas',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.NEGOTIATION,
    ordem: 7,
  },
  TELEPHONY_MY_CALLS: {
    title: 'TELEPHONY_MY_CALLS',
    label: 'Minhas ligações',
    jornada: USER_JOURNEY.CALLS,
    fluxo: USER_FLOW.CALLS,
    ordem: 1,
  },
  TELEPHONY_COLLECT_CALL: {
    title: 'TELEPHONY_COLLECT_CALL',
    label: 'Gerenciar ligações a cobrar',
    jornada: USER_JOURNEY.CALLS,
    fluxo: USER_FLOW.CALLS,
    ordem: 1.1,
  },
  TELEPHONY_CALL_HISTORY: {
    title: 'TELEPHONY_CALL_HISTORY',
    label: 'Histórico de ligações',
    jornada: USER_JOURNEY.CALLS,
    fluxo: USER_FLOW.CALLS,
    ordem: 2,
  },
  TELEPHONY_CALL_EXTRACT: {
    title: 'TELEPHONY_CALL_EXTRACT',
    label: 'Extrato de ligações',
    jornada: USER_JOURNEY.CALLS,
    fluxo: USER_FLOW.CALLS,
    ordem: 3,
  },
  CLICK_ON_VIRTUAL_SUPPORT: {
    title: 'CLICK_ON_VIRTUAL_SUPPORT',
    label: 'Suporte Técnico',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 1,
  },
  VIRTUAL_SUPPORT_CHECK_CONNECTION: {
    title: 'VIRTUAL_SUPPORT_CHECK_CONNECTION',
    label: 'Verificar conexão',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 2,
  },
  VIRTUAL_SUPPORT_LOCATION_WITH_MASSIVE: {
    title: 'VIRTUAL_SUPPORT_LOCATION_WITH_MASSIVE',
    label: 'Localidade com massiva',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 2,
  },
  VIRTUAL_SUPPORT_PENDING_REPAIR_ORDER: {
    title: 'VIRTUAL_SUPPORT_PENDING_REPAIR_ORDER',
    label: 'Ordem de reparo aberta',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 2,
  },
  VIRTUAL_SUPPORT_INITIAL_STEP: {
    title: 'VIRTUAL_SUPPORT_INITIAL_STEP',
    label: 'Preciso de Suporte',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 3,
  },
  VIRTUAL_SUPPORT_ADDRESS_REPAIR: {
    title: 'VIRTUAL_SUPPORT_ADDRESS_REPAIR',
    label: 'Selecionar endereço de reparo',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 4,
  },
  VIRTUAL_SUPPORT_CONFIRM_ADDRESS: {
    title: 'VIRTUAL_SUPPORT_CONFIRM_ADDRESS',
    label: 'Confirmar endereço de reparo',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 5,
  },
  VIRTUAL_SUPPORT_SELECT_SERVICE: {
    title: 'VIRTUAL_SUPPORT_SELECT_SERVICE',
    label: 'Selecionar o serviço para suporte',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 6,
  },
  VIRTUAL_SUPPORT_SELECT_MODEM_PROBLEM: {
    title: 'VIRTUAL_SUPPORT_SELECT_MODEM_PROBLEM',
    label: 'Selecionar problema do modem',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 6,
  },
  VIRTUAL_SUPPORT_BEGIN_CHAT: {
    title: 'VIRTUAL_SUPPORT_BEGIN_CHAT',
    label: 'Iniciar chat',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 7,
  },
  VIRTUAL_SUPPORT_SCHEDULE_DATE: {
    title: 'VIRTUAL_SUPPORT_SCHEDULE_DATE',
    label: 'Agendar visita de reparo',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 7,
  },
  VIRTUAL_SUPPORT_SCHEDULE_CONFIRMATION: {
    title: 'VIRTUAL_SUPPORT_SCHEDULE_CONFIRMATION',
    label: 'Confirmar solicitação de reparo',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 8,
  },
  VIRTUAL_SUPPORT_SCHEDULE_SUCCESS: {
    title: 'VIRTUAL_SUPPORT_SCHEDULE_SUCCESS',
    label: 'Reparo agendado com sucesso',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 9,
  },
  CLICK_ON_WIFI_UPDATE: {
    title: 'CLICK_ON_WIFI_UPDATE',
    label: 'Configurações de wi-fi',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 1,
  },
  WIFI_INFO: {
    title: 'WIFI_INFO',
    label: 'Meu wif-fi',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 2,
  },
  WIFI_INFO_EMPTY: {
    title: 'WIFI_INFO_EMPTY',
    label: 'Contrato sem dados de wif-i',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 2,
  },
  WIFI_INFO_ERROR: {
    title: 'WIFI_INFO_ERROR',
    label: 'Erro ao carregar dados de wif-i',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 2,
  },
  WIFI_UPDATE_IN_PROGRESS: {
    title: 'WIFI_UPDATE_IN_PROGRESS',
    label: 'Alteração de wi-fi em andamento',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 2,
  },
  WIFI_UPDATE_NEW_REQUEST: {
    title: 'WIFI_UPDATE_NEW_REQUEST',
    label: 'Alterar wi-fi',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 3,
  },
  WIFI_UPDATE_SUCCESS: {
    title: 'WIFI_UPDATE_SUCCESS',
    label: 'Alterar wi-fi sucesso',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 4,
  },
  WIFI_UPDATE_ERROR: {
    title: 'WIFI_UPDATE_ERROR',
    label: 'Erro ao alterar wi-fi',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 4,
  },
  CLICK_ON_ADDRESS_CHANGE: {
    title: 'CLICK_ON_ADDRESS_CHANGE',
    label: 'Alterar endereço de instalação',
    jornada: USER_JOURNEY.ADDRESS_CHANGE,
    fluxo: USER_FLOW.ADDRESS_CHANGE,
    ordem: 1,
  },
  CLICK_ON_MENU_CONTRACT: {
    title: 'CLICK_ON_MENU_CONTRACT',
    label: 'Acessar contrato',
    jornada: USER_JOURNEY.CONTRACTS,
    fluxo: USER_FLOW.SELECT_CONTRACT,
    ordem: 1,
  },
  CLICK_ON_MENU_INVOICES: {
    title: 'CLICK_ON_MENU_INVOICES',
    label: 'Notas fiscais',
    jornada: USER_JOURNEY.PAYMENT,
    fluxo: USER_FLOW.MY_BILLETS,
    ordem: 2,
  },
  CLICK_ON_MENU_ADDRESS_CHANGE: {
    title: 'CLICK_ON_MENU_ADDRESS_CHANGE',
    label: 'Alterar endereço de instalação',
    jornada: USER_JOURNEY.ADDRESS_CHANGE,
    fluxo: USER_FLOW.ADDRESS_CHANGE,
    ordem: 1,
  },
  ADDRESS_CHANGE_NEW_INFO: {
    title: 'ADDRESS_CHANGE_NEW_INFO',
    label: 'Informar novo endereço',
    jornada: USER_JOURNEY.ADDRESS_CHANGE,
    fluxo: USER_FLOW.ADDRESS_CHANGE,
    ordem: 2,
  },
  ADDRESS_CHANGE_SCHEDULE: {
    title: 'ADDRESS_CHANGE_SCHEDULE',
    label: 'Agendar mudança de endereço',
    jornada: USER_JOURNEY.ADDRESS_CHANGE,
    fluxo: USER_FLOW.ADDRESS_CHANGE,
    ordem: 3,
  },
  ADDRESS_CHANGE_CONFIRMATION: {
    title: 'ADDRESS_CHANGE_CONFIRMATION',
    label: 'Confirmar mudança de endereço',
    jornada: USER_JOURNEY.ADDRESS_CHANGE,
    fluxo: USER_FLOW.ADDRESS_CHANGE,
    ordem: 4,
  },
  ADDRESS_CHANGE_SUCCESS: {
    title: 'ADDRESS_CHANGE_SUCCESS',
    label: 'Solicitação agendada com sucesso',
    jornada: USER_JOURNEY.ADDRESS_CHANGE,
    fluxo: USER_FLOW.ADDRESS_CHANGE,
    ordem: 5,
  },
  ADDRESS_CHANGE_ERROR: {
    title: 'ADDRESS_CHANGE_ERROR',
    label: 'Erro ao solicitar mudança de endereço',
    jornada: USER_JOURNEY.ADDRESS_CHANGE,
    fluxo: USER_FLOW.ADDRESS_CHANGE,
    ordem: 5,
  },
  CLICK_CHANGE_DUE_DATE: {
    title: 'CLICK_CHANGE_DUE_DATE',
    label: 'Alterar vencimento da fatura',
    jornada: USER_JOURNEY.CHANGE_DUE_DATE,
    fluxo: USER_FLOW.CHANGE_DUE_DATE,
    ordem: 1,
  },
  CLICK_CHANGE_DUE_DATE_INELIGIBLE: {
    title: 'CLICK_CHANGE_DUE_DATE_INELIGIBLE',
    label: 'Não elegível para alterar vencimento',
    jornada: USER_JOURNEY.CHANGE_DUE_DATE,
    fluxo: USER_FLOW.CHANGE_DUE_DATE,
    ordem: 1,
  },
  CHANGE_DUE_DATE_SELECTION: {
    title: 'CHANGE_DUE_DATE_SELECTION',
    label: 'Selecionar nova data',
    jornada: USER_JOURNEY.CHANGE_DUE_DATE,
    fluxo: USER_FLOW.CHANGE_DUE_DATE,
    ordem: 2,
  },
  CHANGE_DUE_DATE_SUCCESS: {
    title: 'CHANGE_DUE_DATE_SUCCESS',
    label: 'Vencimento alterado com sucesso',
    jornada: USER_JOURNEY.CHANGE_DUE_DATE,
    fluxo: USER_FLOW.CHANGE_DUE_DATE,
    ordem: 3,
  },
  CHANGE_DUE_DATE_ERROR: {
    title: 'CHANGE_DUE_DATE_ERROR',
    label: 'Erro ao alterar vencimento',
    jornada: USER_JOURNEY.CHANGE_DUE_DATE,
    fluxo: USER_FLOW.CHANGE_DUE_DATE,
    ordem: 3,
  },
  CLICK_ON_OPEN_NOTIFICATIONS: {
    title: 'CLICK_ON_OPEN_NOTIFICATIONS',
    label: 'Notificações',
    jornada: USER_JOURNEY.NOTIFICATION,
    fluxo: USER_FLOW.NOTIFICATION,
    ordem: 1,
  },
  NOTIFICATION_OPEN: {
    title: 'NOTIFICATION_OPEN',
    label: 'Abrir notificação',
    jornada: USER_JOURNEY.NOTIFICATION,
    fluxo: USER_FLOW.NOTIFICATION,
    ordem: 2,
  },
  NOTIFICATION_DELETE: {
    title: 'NOTIFICATION_DELETE',
    label: 'Excluir notificação',
    jornada: USER_JOURNEY.NOTIFICATION,
    fluxo: USER_FLOW.NOTIFICATION,
    ordem: 3,
  },
  CLICK_ON_SEE_PHONES: {
    title: 'CLICK_ON_SEE_PHONES',
    label: 'Ver meus telefones',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.MY_PHONES,
    ordem: 1,
  },
  CLICK_ON_MENU_SEE_PHONES: {
    title: 'CLICK_ON_MENU_SEE_PHONES',
    label: 'Ver meus telefones',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.MY_PHONES,
    ordem: 1,
  },
  CLICK_ON_ADD_NEW_PHONE: {
    title: 'CLICK_ON_ADD_NEW_PHONE',
    label: 'Adicionar novo telefone',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.MY_PHONES,
    ordem: 1,
  },
  CLICK_ON_ADD_EMAIL: {
    title: 'CLICK_ON_ADD_EMAIL',
    label: 'Adicionar novo email',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_EMAIL,
    ordem: 2,
  },
  ADD_EMAIL_INFORM_NEW_EMAIL: {
    title: 'ADD_EMAIL_INFORM_NEW_EMAIL',
    label: 'Informar novo email',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_EMAIL,
    ordem: 3,
  },
  CLICK_ON_CONFIRM_TOKEN_EMAIL: {
    title: 'CLICK_ON_CONFIRM_TOKEN',
    label: 'Confirmar token',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_EMAIL,
    ordem: 4,
  },
  ADD_EMAIL_SUCCESS: {
    title: 'ADD_EMAIL_SUCCESS',
    label: 'Email adicionado com sucesso',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_EMAIL,
    ordem: 5,
  },
  ADD_EMAIL_ERROR: {
    title: 'ADD_EMAIL_ERROR',
    label: 'Falha ao adicionar email',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_EMAIL,
    ordem: 5.1,
  },
  CLICK_ON_DELETE_EMAIL: {
    title: 'CLICK_ON_DELETE_EMAIL',
    label: 'Apagar email',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.DELETE_EMAIL,
    ordem: 2,
  },
  CLICK_ON_CONFIRM_DELETE_EMAIL: {
    title: 'CLICK_ON_CONFIRM_DELETE_EMAIL',
    label: 'Confirmar exclusão de email',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.DELETE_EMAIL,
    ordem: 3,
  },
  DELETE_EMAIL_SUCCESS: {
    title: 'DELETE_EMAIL_SUCCESS',
    label: 'Email removido com sucesso',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.DELETE_EMAIL,
    ordem: 4,
  },
  DELETE_EMAIL_ERROR: {
    title: 'DELETE_EMAIL_ERROR',
    label: 'Falha ao remover email',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.DELETE_EMAIL,
    ordem: 4.1,
  },
  CLICK_ON_ALTER_MAIN_EMAIL: {
    title: 'CLICK_ON_ALTER_MAIN_EMAIL',
    label: 'Mudar email principal',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ALTER_MAIN_EMAIL,
    ordem: 2,
  },
  CLICK_ON_SELECT_MAIN_EMAIL: {
    title: 'CLICK_ON_SELECT_MAIN_EMAIL',
    label: 'Definir email principal',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ALTER_MAIN_EMAIL,
    ordem: 3,
  },
  ALTER_MAIN_EMAIL_SUCCESS: {
    title: 'ALTER_MAIN_EMAIL_SUCCESS',
    label: 'Email principal alterado com sucesso',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ALTER_MAIN_EMAIL,
    ordem: 4,
  },
  ALTER_MAIN_EMAIL_ERROR: {
    title: 'ALTER_MAIN_EMAIL_ERROR',
    label: 'Falha ao alterar email principal',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ALTER_MAIN_EMAIL,
    ordem: 4.1,
  },
  CLICK_ON_CHANGE_SAC_PASSWORD: {
    title: 'CLICK_ON_CHANGE_SAC_PASSWORD',
    label: 'Alterar senha',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.CHANGE_SAC_PASSWORD,
    ordem: 1,
  },
  INFORM_NEW_SAC_PASSWORD: {
    title: 'INFORM_NEW_SAC_PASSWORD',
    label: 'Informar nova senha',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.CHANGE_SAC_PASSWORD,
    ordem: 2,
  },
  CHANGE_SAC_PASSWORD_SUCCESS: {
    title: 'CHANGE_SAC_PASSWORD_SUCCESS',
    label: 'Senha alterada com sucesso',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.CHANGE_SAC_PASSWORD,
    ordem: 3,
  },
  CHANGE_SAC_PASSWORD_ERROR: {
    title: 'CHANGE_SAC_PASSWORD_ERROR',
    label: 'Erro ao alterar a senha',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.CHANGE_SAC_PASSWORD,
    ordem: 3.1,
  },
  CLICK_ON_FAQ: {
    title: 'CLICK_ON_FAQ',
    label: 'Ver FAQ',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.FAQ,
    ordem: 1,
  },
  FAQ_CATEGORIES_VIEW: {
    title: 'FAQ_CATEGORIES_VIEW',
    label: 'Tela perguntas frequentes categorias',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.FAQ,
    ordem: 2,
  },
  FAQ_QUESTIONS_LIST_VIEW: {
    title: 'FAQ_QUESTIONS_LIST_VIEW',
    label: 'Tela de perguntas',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.FAQ,
    ordem: 3,
  },
  CLICK_ON_MENU_SEE_MAILS: {
    title: 'CLICK_ON_MENU_SEE_MAILS',
    label: 'Ver meus emails',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.MY_EMAILS,
    ordem: 1,
  },
  CLICK_ON_MENU_ACCOUNT_UNLOCK: {
    title: 'CLICK_ON_MENU_ACCOUNT_UNLOCK',
    label: 'Desbloquear conta',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.ACCOUNT_UNLOCK,
    ordem: 1,
  },
  CLICK_ON_INTERNET_SPEED_TEST: {
    title: 'CLICK_ON_INTERNET_SPEED_TEST',
    label: 'Testar velocidade da conexão',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SPEED_TEST,
    ordem: 1,
  },
  CLICK_ON_SVA_DETAILS: {
    title: 'CLICK_ON_SVA_DETAILS',
    label: 'Detalhes dos SVAs',
    jornada: USER_JOURNEY.ACCOUNT_INFO,
    fluxo: USER_FLOW.DIGITAL_SERVICES,
    ordem: 1,
  },
  CLICK_ON_SVA_GO_TO_WEBSITE: {
    title: 'CLICK_ON_SVA_GO_TO_WEBSITE',
    label: 'Ir para o site',
    jornada: USER_JOURNEY.ACCOUNT_INFO,
    fluxo: USER_FLOW.DIGITAL_SERVICES_ACTIVATION,
    ordem: 1,
  },
  CLICK_ON_SEND_FILE: {
    title: 'CLICK_ON_SEND_FILE',
    label: 'Eviar arquivo',
    jornada: USER_JOURNEY.FILE,
    fluxo: USER_FLOW.FILE,
    ordem: 1,
  },
  CLICK_ON_SELECT_FILE: {
    title: 'CLICK_ON_SELECT_FILE',
    label: 'Selecionar arquivo',
    jornada: USER_JOURNEY.FILE,
    fluxo: USER_FLOW.FILE,
    ordem: 2,
  },
  SEND_FILE_SUCCESS: {
    title: 'SEND_FILE_SUCCESS',
    label: 'Arquivo enviado com sucesso',
    jornada: USER_JOURNEY.FILE,
    fluxo: USER_FLOW.FILE,
    ordem: 3,
  },
  SEND_FILE_ERROR: {
    title: 'SEND_FILE_ERROR',
    label: 'Falha ao enviar arquivo',
    jornada: USER_JOURNEY.FILE,
    fluxo: USER_FLOW.FILE,
    ordem: 3.1,
  },
  SEND_FILE_TALK_WITH_ATTENDANT: {
    title: 'SEND_FILE_TALK_WITH_ATTENDANT',
    label: 'Falar com atendente',
    jornada: USER_JOURNEY.FILE,
    fluxo: USER_FLOW.FILE,
    ordem: 3,
  },
  CLICK_ON_ACCOUNT_UNLOCK: {
    title: 'CLICK_ON_ACCOUNT_UNLOCK',
    label: 'Desbloquear conta',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.ACCOUNT_UNLOCK,
    ordem: 1,
  },
  CLICK_ON_MENU_WIFI_CONFIGURATION: {
    title: 'CLICK_ON_MENU_WIFI_CONFIGURATION',
    label: 'Configurações de wi-fi',
    jornada: USER_JOURNEY.WIFI_UPDATE,
    fluxo: USER_FLOW.WIFI_UPDATE,
    ordem: 1,
  },
  CLICK_ON_MENU_CHANGE_SAC_PASSWORD: {
    title: 'CLICK_ON_MENU_CHANGE_SAC_PASSWORD',
    label: 'Alterar senha',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.CHANGE_SAC_PASSWORD,
    ordem: 1,
  },
  CLICK_ON_MENU_FAQ: {
    title: 'CLICK_ON_MENU_FAQ',
    label: 'Ver FAQ',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.FAQ,
    ordem: 1,
  },
  CLICK_ON_MENU_ACCOUNT_INFORMATION: {
    title: 'CLICK_ON_MENU_ACCOUNT_INFORMATION',
    label: 'Detalhes dos SVAs',
    jornada: USER_JOURNEY.ACCOUNT_INFO,
    fluxo: USER_FLOW.DIGITAL_SERVICES,
    ordem: 1,
  },
  CLICK_ON_MENU_CALLS: {
    title: 'CLICK_ON_MENU_CALLS',
    label: 'Minhas ligações',
    jornada: USER_JOURNEY.CALLS,
    fluxo: USER_FLOW.CALLS,
    ordem: 1,
  },
  CLICK_ON_MENU_CALLS_HISTORY: {
    title: 'CLICK_ON_MENU_CALLS_HISTORY',
    label: 'Histórico de ligações',
    jornada: USER_JOURNEY.CALLS,
    fluxo: USER_FLOW.CALLS,
    ordem: 1.2,
  },
  CLICK_ON_MENU_CALLS_EXTRACT: {
    title: 'CLICK_ON_MENU_CALLS_EXTRACT',
    label: 'Extrato de ligações',
    jornada: USER_JOURNEY.CALLS,
    fluxo: USER_FLOW.CALLS,
    ordem: 1.3,
  },
  CLICK_ON_MENU_SELECT_CONTRACTS: {
    title: 'CLICK_ON_MENU_SELECT_CONTRACTS',
    label: 'Listar contratos',
    jornada: USER_JOURNEY.CONTRACTS,
    fluxo: USER_FLOW.SELECT_CONTRACT,
    ordem: 1.1,
  },
  CLICK_ON_MENU_VERIFY_CONNECTION: {
    title: 'CLICK_ON_MENU_VERIFY_CONNECTION',
    label: 'Verificar a conexão',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 2,
  },
  CLICK_ON_MENU_MASSIVE: {
    title: 'CLICK_ON_MENU_MASSIVE',
    label: 'Localidade com massiva',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 2.2,
  },
  CLICK_ON_MENU_OPEN_SERVICE_ORDER: {
    title: 'CLICK_ON_MENU_OPEN_SERVICE_ORDER',
    label: 'Ordem de reparo aberta',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CONNECTION_PROBLEM,
    ordem: 2.3,
  },
  CLICK_ON_MENU_ACCESS_CONTRACT: {
    title: 'CLICK_ON_MENU_ACCESS_CONTRACT',
    label: 'Acessar contrato',
    jornada: USER_JOURNEY.CONTRACTS,
    fluxo: USER_FLOW.SELECT_CONTRACT,
    ordem: 1,
  },
  CLICK_ON_ACCOUNT_UNLOCK_NOW: {
    title: 'CLICK_ON_ACCOUNT_UNLOCK_NOW',
    label: 'Desbloquear agora',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.ACCOUNT_UNLOCK,
    ordem: 2,
  },
  UNLOCK_ACCOUNT_UNBLOCKED_ACCOUNT: {
    title: 'UNLOCK_ACCOUNT_UNBLOCKED_ACCOUNT',
    label: 'Conta não bloqueada',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.ACCOUNT_UNLOCK,
    ordem: 2.1,
  },
  UNLOCK_ACCOUNT_NOT_ELIGIBLE: {
    title: 'UNLOCK_ACCOUNT_NOT_ELIGIBLE',
    label: 'Não elegível para desbloqueio',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.ACCOUNT_UNLOCK,
    ordem: 2.2,
  },
  UNLOCK_ACCOUNT_SUCCESS: {
    title: 'UNLOCK_ACCOUNT_SUCCESS',
    label: 'Desbloqueio com sucesso',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.ACCOUNT_UNLOCK,
    ordem: 3,
  },
  UNLOCK_ACCOUNT_ERROR: {
    title: 'UNLOCK_ACCOUNT_ERROR',
    label: 'Falha ao desbloquear conta',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.ACCOUNT_UNLOCK,
    ordem: 3.1,
  },
  CLICK_ON_REFERER_FRIEND: {
    title: 'CLICK_ON_REFERER_FRIEND',
    label: 'Gerar cupom',
    jornada: USER_JOURNEY.REFERER_FRIEND,
    fluxo: USER_FLOW.REFERER_FRIEND,
    ordem: 1,
  },
  REFERER_FRIEND_SUCCESS: {
    title: 'REFERER_FRIEND_SUCCESS',
    label: 'Cupom gerado com sucesso',
    jornada: USER_JOURNEY.REFERER_FRIEND,
    fluxo: USER_FLOW.REFERER_FRIEND,
    ordem: 2,
  },
  REFERER_FRIEND_ERROR: {
    title: 'REFERER_FRIEND_ERROR',
    label: 'Falha ao gerar cupom',
    jornada: USER_JOURNEY.REFERER_FRIEND,
    fluxo: USER_FLOW.REFERER_FRIEND,
    ordem: 2.1,
  },
  SERVICE_ORDER_INITIAL: {
    title: 'SERVICE_ORDER_INITIAL',
    label: 'Ordens de serviço',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER,
    ordem: 1,
  },
  CLICK_ON_SERVICE_ORDER_RESCHEDULE: {
    title: 'CLICK_ON_SERVICE_ORDER_RESCHEDULE',
    label: 'Reagendar ordem de serviço',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_RESCHEDULE,
    ordem: 1,
  },
  SERVICE_ORDER_RESCHEDULE_SELECT_DATE: {
    title: 'SERVICE_ORDER_RESCHEDULE_SELECT_DATE',
    label: 'Selecionar nova data',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_RESCHEDULE,
    ordem: 2,
  },
  SERVICE_ORDER_RESCHEDULE_CONFIRM: {
    title: 'SERVICE_ORDER_RESCHEDULE_CONFIRM',
    label: 'Confirmar reagendamento',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_RESCHEDULE,
    ordem: 3,
  },
  SERVICE_ORDER_RESCHEDULE_SUCCESS: {
    title: 'SERVICE_ORDER_RESCHEDULE_SUCCESS',
    label: 'Visita reagendada com sucesso',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_RESCHEDULE,
    ordem: 4,
  },
  SERVICE_ORDER_RESCHEDULE_ERROR: {
    title: 'SERVICE_ORDER_RESCHEDULE_ERROR',
    label: 'Falha ao reagendar visita',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_RESCHEDULE,
    ordem: 4.1,
  },
  CLICK_ON_SERVICE_ORDER_CANCEL: {
    title: 'CLICK_ON_SERVICE_ORDER_CANCEL',
    label: 'Cancelar visita técnica',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_CANCEL,
    ordem: 1,
  },
  SERVICE_ORDER_CANCEL_CONFIRM: {
    title: 'SERVICE_ORDER_CANCEL_CONFIRM',
    label: 'Confirmar cancelamento de visita',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_CANCEL,
    ordem: 2,
  },
  SERVICE_ORDER_CANCEL_SUCCESS: {
    title: 'SERVICE_ORDER_CANCEL_SUCCESS',
    label: 'Visita cancelada com sucesso',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_CANCEL,
    ordem: 3,
  },
  SERVICE_ORDER_CANCEL_ERROR: {
    title: 'SERVICE_ORDER_CANCEL_ERROR',
    label: 'Falha no cancelamento',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_CANCEL,
    ordem: 6,
  },
  CLICK_ON_SERVICE_ORDER_TRACK: {
    title: 'CLICK_ON_SERVICE_ORDER_TRACK',
    label: 'Rastrear visita',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.SERVICE_ORDER_TRACK,
    ordem: 1,
  },
  CLICK_ON_CHAT_BUTTON: {
    title: 'CLICK_ON_CHAT_BUTTON',
    label: 'Chat',
    jornada: USER_JOURNEY.SUPPORT,
    fluxo: USER_FLOW.CHAT_SERVICE,
    ordem: 1,
  },
  CLICK_ON_DELETE_PHONE: {
    title: 'CLICK_ON_DELETE_PHONE',
    label: 'Apagar telefone',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.REMOVE_PHONE,
    ordem: 2,
  },
  CLICK_ON_ALTER_PHONE: {
    title: 'CLICK_ON_ALTER_PHONE',
    label: 'Mudar telefone principal',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ALTER_PHONE,
    ordem: 2,
  },
  CLICK_ON_INFORM_NEW_PHONE: {
    title: 'CLICK_ON_INFORM_NEW_PHONE',
    label: 'Informar novo telefone',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_PHONE,
    ordem: 3,
  },
  CLICK_ON_CONFIRM_DELETE_PHONE: {
    title: 'CLICK_ON_CONFIRM_DELETE',
    label: 'Confirmar exclusão de telefone',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.REMOVE_PHONE,
    ordem: 3,
  },
  CLICK_ON_CONFIRM_MAIN_PHONE: {
    title: 'CLICK_ON_CONFIRM_MAIN_PHONE',
    label: 'Definir telefone principal',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ALTER_PHONE,
    ordem: 3,
  },
  CLICK_ON_ADD_PHONE_CONFIRM_TOKEN: {
    title: 'CLICK_ON_CONFIRM_TOKEN',
    label: 'Confirmar token',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_PHONE,
    ordem: 4,
  },
  DELETE_PHONE_SUCCESS: {
    title: 'DELETE_PHONE_SUCCESS',
    label: 'Telefone removido com sucesso',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.REMOVE_PHONE,
    ordem: 4,
  },
  DELETE_PHONE_ERROR: {
    title: 'DELETE_PHONE_ERROR',
    label: 'Falha ao remover o telefone',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.REMOVE_PHONE,
    ordem: 4.1,
  },
  ALTER_PHONE_SUCCESS: {
    title: 'ALTER_PHONE_SUCCESS',
    label: 'Telefone principal alterado com sucesso',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.REMOVE_PHONE,
    ordem: 4,
  },
  ALTER_PHONE_ERROR: {
    title: 'ALTER_PHONE_ERROR',
    label: 'Falha ao alterar telefone principal',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.REMOVE_PHONE,
    ordem: 4.1,
  },
  ADD_PHONE_SUCCESS: {
    title: 'ADD_PHONE_SUCCESS',
    label: 'Telefone adicionado com sucesso',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_PHONE,
    ordem: 5,
  },
  ADD_PHONE_ERROR: {
    title: 'ADD_PHONE_ERROR',
    label: 'Falha ao adicionar telefone',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.ADD_PHONE,
    ordem: 5.1,
  },
  CLICK_ON_SEE_EMAILS: {
    title: 'CLICK_ON_SEE_EMAILS',
    label: 'Ver meus emails',
    jornada: USER_JOURNEY.REGISTER_DATA,
    fluxo: USER_FLOW.MY_MAILS,
    ordem: 1,
  },
}
