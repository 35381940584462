import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  EmailList,
  EmailAdd,
  EmailConfirmation,
  EmailSuccess,
  EmailDelete,
  EmailSelectDefault,
} from './components'

export function EmailDrawer() {
  const [data, setData] = useState({})
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages({ data, setData })[stage]
}

function stages({ ...props }) {
  return {
    initial: <EmailList {...props} />,
    add: <EmailAdd {...props} />,
    confirmation: <EmailConfirmation {...props} />,
    success: <EmailSuccess {...props} />,
    select_default: <EmailSelectDefault {...props} />,
    delete: <EmailDelete {...props} />,
  }
}
