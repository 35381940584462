import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Messages } from './components'
import { Message } from './components/details/components'

export function MessagesDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)
  const [data, setData] = useState({})
  return stages({ data, setData })[stage]
}

function stages({ ...props }) {
  return {
    initial: <Messages { ...props } />,
    message: <Message { ...props } />
  }
}
