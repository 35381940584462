export const SERVICE_ORDER_TYPES = {
  ACTIVATION: 'ACTIVATION',
  ADDRESS_CHANGE: 'ADDRESS_CHANGE',
  REPAIR: 'REPAIR',
}

export const SERVICE_ORDER_LABEL = {
  [SERVICE_ORDER_TYPES.ACTIVATION]: 'Instalação',
  [SERVICE_ORDER_TYPES.ADDRESS_CHANGE]: 'Mudança de endereço',
  [SERVICE_ORDER_TYPES.REPAIR]: 'Reparo',
}

export const SERVICE_ORDER_CONFIRMATION = [
  SERVICE_ORDER_TYPES.ACTIVATION,
  SERVICE_ORDER_TYPES.ADDRESS_CHANGE,
  SERVICE_ORDER_TYPES.REPAIR,
]

export const SO_OPEN = 'ST_OS_CRIADA'

export const SO_TYPES = {
  FINISHED: 'ORDEM EXECUTADA',
}
