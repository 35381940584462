import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FIREBASE_EVENTS, PLAN_STATUS_KEY, VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import { Button } from 'components'
import { useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { setStage } from 'slices/drawer'
import { MAX_CONTRACTS_LENGTH } from './constants'
import './style.scss'

export function TalkToAttendant() {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const {
    customer: { contracts },
  } = useSelector(state => state)

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_INITIAL_STEP)
  }, [sendEvent])

  async function handleContinue() {
    const contractsFiltred = contracts.filter(
      contract => contract.contractStatus !== PLAN_STATUS_KEY.ST_CONT_CANCELADO
    )

    if (contractsFiltred.length > MAX_CONTRACTS_LENGTH) {
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
    } else {
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS))
    }
  }

  function renderButton() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => {
            handleContinue()
          }}
        >
          Continuar
        </Button>
      </div>
    )
  }

  return (
    <div className='virtual-support-info'>
      <div className='content'>
        <div className='round'>
          <STATICS.MODEM className='icon' />
        </div>
        <h3 className='title'>{'Está com problemas no\n seu modem?'}</h3>
        <p className='text'>Vamos iniciar alguns testes, para prosseguirmos com seu atendimento </p>
      </div>
      {renderButton()}
    </div>
  )
}
