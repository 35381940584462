import React from 'react'
import { useSelector } from 'react-redux'
import {
  ModemInfo,
  ModemEdit,
  ModemSuccess,
  ModemInProgress,
  ModemError,
  ModemErrorServer,
} from './components'
import { STAGES } from './constants'

export function ModemDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages()[stage]
}

function stages() {
  return {
    [STAGES.INITIAL]: <ModemInfo />,
    [STAGES.EDIT]: <ModemEdit />,
    [STAGES.SUCCESS]: <ModemSuccess />,
    [STAGES.IN_PROGRESS]: <ModemInProgress />,
    [STAGES.ERROR]: <ModemError />,
    [STAGES.ERROR_SERVER]: <ModemErrorServer />,
  }
}
