/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  canShowChat: false,
  chatURL: '',
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCanShowChat: (state, action) => {
      state.canShowChat = !state.canShowChat
      state.chatURL = action.payload
    },
  },
})

export const { setCanShowChat } = chatSlice.actions

export default chatSlice.reducer
