import React from 'react'
import { Button } from 'components'
import { PAGE_STAGES_KEYS } from '../../constants'
import './style.scss'

export function ResponseBankSlip({ setPageStage }) {
  function handleOnClick() {
    setPageStage(PAGE_STAGES_KEYS.SEARCH_BANK_SLIP)
  }

  return (
    <div className='response-bank-slip'>
      <h3 className='title'>Nenhuma fatura encontrada!</h3>
      <p className='text'>
        Se você tem alguma fatura em aberto, acesse a <a href='/'>Central do Assinante!</a>
      </p>
      <Button onClick={handleOnClick} type='button' color='primary' className='button'>
        Ok
      </Button>
    </div>
  )
}
