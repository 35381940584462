import {
  PASSWORD_FORCES,
  ONLY_DIGITS_PATTERN,
  ONLY_CHARACTERS_PATTERN,
  CHARACTERS_AND_DIGITS_ONLY_PATTERN,
  HAS_SPECIAL_CHARACTERS_PATTERN,
  MINIMUM_PASSWORD_LENGTH,
  HAS_CHARACTER_PATTERN,
  HAS_DIGIT_PATTERN,
  SPECIAL_CHARACTER_ONLY_PATTERN,
  SPECIAL_CHARACTER_AND_DIGIT_ONLY,
  SPECIAL_CHARACTER_AND_CHARACTER_ONLY,
  WIFI_PASSWORD_REGEXP,
  WIFI_NAME_REGEXP,
} from 'app-constants'

export const isValidCharPassword = password => password?.match(/[a-zA-Z0-9]+/g)

function isCharacterOnly(password) {
  return ONLY_CHARACTERS_PATTERN.test(password)
}

function isNumberOnly(password) {
  return ONLY_DIGITS_PATTERN.test(password)
}

function isNumberAndCharacterOnly(password) {
  return CHARACTERS_AND_DIGITS_ONLY_PATTERN.test(password)
}

function getHasSpecialCharacters(password) {
  return HAS_SPECIAL_CHARACTERS_PATTERN.test(password)
}

function hasDigit(password) {
  return HAS_DIGIT_PATTERN.test(password)
}

function hasCharacter(password) {
  return HAS_CHARACTER_PATTERN.test(password)
}

function getHasNumberAndCharacter(password) {
  return hasDigit(password) && hasCharacter(password)
}

function isNumberAndDigitAndSpecialCharacter(password) {
  const hasSpecialCharacters = getHasSpecialCharacters(password)
  const hasNumberAndCharacter = getHasNumberAndCharacter(password)

  return hasSpecialCharacters && hasNumberAndCharacter
}

function isDigitAndSpecialCharacterOnly(password) {
  return SPECIAL_CHARACTER_AND_DIGIT_ONLY.test(password)
}

function isCharacterAndSpecialCharacterOnly(password) {
  return SPECIAL_CHARACTER_AND_CHARACTER_ONLY.test(password)
}

function isSpecialCharacterOnly(password) {
  return SPECIAL_CHARACTER_ONLY_PATTERN.test(password)
}

function isWeakPasswordForce(password) {
  const isLessThanMinimumLengthAndNotEmpty =
    password.length >= 1 && password.length < MINIMUM_PASSWORD_LENGTH

  return (
    isLessThanMinimumLengthAndNotEmpty ||
    isCharacterOnly(password) ||
    isNumberOnly(password) ||
    isSpecialCharacterOnly(password)
  )
}

function isMediumPasswordForce(password) {
  return (
    isNumberAndCharacterOnly(password) ||
    isDigitAndSpecialCharacterOnly(password) ||
    isCharacterAndSpecialCharacterOnly(password)
  )
}

function isStrongPasswordForce(password) {
  return isNumberAndDigitAndSpecialCharacter(password)
}

export function getPasswordForce(password) {
  if (isWeakPasswordForce(password)) return PASSWORD_FORCES.WEAK
  if (isMediumPasswordForce(password)) return PASSWORD_FORCES.MEDIUM
  if (isStrongPasswordForce(password)) return PASSWORD_FORCES.STRONG

  return PASSWORD_FORCES.EMPTY
}

export function validateWifiPassword(value) {
  return value && WIFI_PASSWORD_REGEXP.test(value)
}

export function validateWifiName(value) {
  return value && WIFI_NAME_REGEXP.test(value)
}
