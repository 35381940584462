import React from 'react'
import { useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { STATICS } from 'statics'
import './style.scss'

export function BackButton({ className }) {
  const dispatch = useDispatch()

  function handleClickButton() {
    dispatch(setDrawer(''))
  }

  return (
    <button
      type='button'
      className={`back-button-component ${className}`}
      onClick={handleClickButton}
    >
      <div className='icon'>
        <STATICS.BACK />
      </div>{' '}
      Voltar
    </button>
  )
}
