export const formatPhone = (phone = '') => {
  const onlyDigit = phone?.toString()?.replace(/\D/g, '')

  const formated = onlyDigit?.replace(/(\d{2})(\d)/, '($1) $2')

  return onlyDigit?.length < 11
    ? formated?.replace(/(\d{4})(\d)/, '$1-$2')
    : formated?.replace(/(\d{5})(\d)/, '$1-$2')
}

export const formatPhoneToDB = phone => phone.replace(/[() -]/g, '')
