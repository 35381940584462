import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_ERROR_MESSAGE, FIREBASE_EVENTS, SERVICE_ORDER_TYPES, SHIFTS } from 'app-constants'
import { Button, Card, ContextLoader, CustomDatePicker, RadioButton } from 'components'
import { getBookings, useFirebase } from 'hooks'
import { setDrawerAlert, setStage } from 'slices/drawer'
import { convertISOStringToDate, formatDate, formatTurn, formatZipCode, toISO } from 'utils'
import { ADDRESS_CHANGE_STAGES } from '../../constants'
import './style.scss'

export function Reschedule({ data, setSelectedSchedule, setBookingCode, serviceOrderInfo }) {
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [isLoading, setIsLoading] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear())
  const [availableDays, setAvailableDays] = useState([])
  const [selectedTimeFrames, setSelectedTimeFrames] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedTurn, setSelectedTurn] = useState(null)
  const [isMorningCardChecked, setIsMorningCardChecked] = useState(false)
  const [isAfternoonCardChecked, setIsAfternoonCardChecked] = useState(false)
  const [isMorningAvailable, setIsMorningAvailable] = useState(false)
  const [isAfternoonAvailable, setIsAfternoonAvailable] = useState(false)
  const [bucket, setBucket] = useState('')
  const [scheduleId, setScheduleId] = useState(null)
  const [unity, setUnity] = useState('')
  const { id: contractId, address } = useSelector(state => state.contract.selected)
  const { schedulingDate, turn } = data
  const { city, neighborhood, number, street, uf, zipCode } = address
  const { sendEvent } = useFirebase()
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      try {
        const params = {
          unity: serviceOrderInfo.unity,
          contractId,
          transmissionType: serviceOrderInfo.transmissionType,
          number,
          city,
          district: neighborhood,
          street,
          state: uf,
          zipCode: formatZipCode(zipCode),
          serviceOrderType: SERVICE_ORDER_TYPES.ADDRESS_CHANGE,
        }

        const { data: booking } = await getBookings({ params })

        setAvailableDays(booking?.bookingDaysAvailable || [])
        setBookingCode(booking?.code)
        setScheduleId(booking?.scheduleId)
        setUnity(booking.unity)
      } catch (error) {
        const errorMessage = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
        console.error(error)
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [
    month,
    year,
    dispatch,
    contractId,
    city,
    neighborhood,
    number,
    street,
    uf,
    zipCode,
    setBookingCode,
    serviceOrderInfo,
  ])

  const getScheduleData = useCallback(
    date => availableDays.find(day => day?.date === toISO(date)),
    [availableDays]
  )

  const handleChangeSchedule = useCallback(
    date => {
      sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_RESCHEDULE_SELECT_DATE)
      setSelectedDate(date)

      const selectedDateScheduleData = getScheduleData(date)

      const hasMorningAvailable = selectedDateScheduleData.bookingShiftAvailabilities.filter(
        item => item.shift === SHIFTS.MORNING
      )
      const hasAfternoonAvailable = selectedDateScheduleData.bookingShiftAvailabilities.filter(
        item => item.shift === SHIFTS.AFTERNOON
      )

      if (hasMorningAvailable.length > 0) setIsMorningAvailable(true)
      if (hasAfternoonAvailable.length > 0) setIsAfternoonAvailable(true)

      const defaultShift = selectedDateScheduleData?.bookingShiftAvailabilities[0]

      setSelectedTimeFrames(defaultShift || {})
      setBucket(selectedDateScheduleData?.bucket)
    },
    [getScheduleData, setSelectedDate, sendEvent]
  )

  function getDisableButton() {
    return (
      !selectedDate ||
      !selectedTurn ||
      !selectedTimeFrames?.initialTimeFrame ||
      !selectedTimeFrames?.finalTimeFrame
    )
  }

  function mapBookingAvailableDays() {
    return availableDays.map(({ date }) => convertISOStringToDate(date))
  }

  function handleMonthChange(date) {
    setMonth(date.getMonth() + 1)
  }

  function handleYearChange(date) {
    setYear(date.getFullYear())
  }

  function handleContinue() {
    setSelectedSchedule({
      date: selectedDate,
      turn: selectedTurn,
      timeFrames: selectedTimeFrames,
      bucket,
      scheduleId,
      unity,
    })

    dispatch(setStage(ADDRESS_CHANGE_STAGES.RESCHEDULE_CONFIRMATION))
  }

  function handleSelectShift(shift) {
    if (shift === SHIFTS.AFTERNOON) {
      setIsAfternoonCardChecked(true)
      setIsMorningCardChecked(false)
    } else {
      setIsAfternoonCardChecked(false)
      setIsMorningCardChecked(true)
    }
    setSelectedTurn(shift)
  }

  if (isLoading) return <ContextLoader fixed={false} />

  return (
    <div className='reschedule-installation-date'>
      <div className='reschedule-info'>
        <h3 className='reschedule-title'>Reagendamento de solicitação de mudança de endereço</h3>
        <p className='reschedule-text'>Sua instalação está agendada para:</p>
        <span className='reschedule-info-date'>
          <strong>{formatDate(schedulingDate)}</strong> pela
          <strong> {formatTurn(turn)}</strong>
        </span>

        <p className='reschedule-text'>Deseja reagendar a data da instalação?</p>
        <p className='reschedule-text select-date'>Selecione abaixo uma nova data:</p>
        <CustomDatePicker
          name='calendar-sheduling'
          availableDates={mapBookingAvailableDays()}
          onChange={handleChangeSchedule}
          selectedDate={selectedDate}
          dataCy='select-schedule-day'
          placeholderText={formatDate(schedulingDate)}
          onMonthChange={date => handleMonthChange(date)}
          onYearChange={date => handleYearChange(date)}
          showMonthDropdown
          showYearDropdown
        />

        {!!selectedDate && (
          <p className='reschedule-text'>Escolha o melhor período para atendimento:</p>
        )}

        {isMorningAvailable && (
          <Card className='reschedule-card' onClick={() => handleSelectShift(SHIFTS.MORNING)}>
            <RadioButton checked={isMorningCardChecked} />
            <div className='card-content'>
              <p className='card-text'>Manhã (08h às 12h)</p>
            </div>
          </Card>
        )}

        {isAfternoonAvailable && (
          <Card className='reschedule-card' onClick={() => handleSelectShift(SHIFTS.AFTERNOON)}>
            <RadioButton checked={isAfternoonCardChecked} />
            <div className='card-content'>
              <p className='card-text'>Tarde (13h às 20h)</p>
            </div>
          </Card>
        )}
      </div>

      <Button
        className='button'
        color='primary'
        size='large'
        disabled={getDisableButton()}
        onClick={() => {
          handleContinue()
        }}
      >
        Continuar
      </Button>
    </div>
  )
}
