import React from 'react'
import './style.scss'

export function Tag({ className, text, color }) {
  function resolveClassNames() {
    const result = []

    !!className && result.push(className)
    !!color && result.push(`-${color}`)

    return result.join(' ')
  }

  return <span className={`tag-component ${resolveClassNames()}`}>{text}</span>
}
