import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FIREBASE_EVENTS } from 'app-constants'
import { getInternet, useFirebase } from 'hooks'
import { Card, ContextLoader } from 'components'
import { setStage } from 'slices/drawer'
import { setInternetInfo } from 'slices/contract'
import { STATICS } from 'statics'
import { shouldRenderWifiInfo } from 'utils'
import { STAGES } from '../../constants'
import './style.scss'

export function ModemInfo() {
  const [internet, setInternet] = useState(null)
  const {
    selected: { id: idContract, contractStatus },
  } = useSelector(({ contract }) => contract)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const [renderWifiInfo, setRenderWifiInfo] = useState(false)

  useEffect(() => {
    function handleInternet(wifi) {
      setInternet(wifi)
      if (wifi.hasConfigurationInProgress) {
        dispatch(setStage(STAGES.IN_PROGRESS))
      } else if (shouldRenderWifiInfo(contractStatus, wifi?.canBeChanged)) {
        setRenderWifiInfo(true)
        sendEvent(FIREBASE_EVENTS.WIFI_INFO)
      } else {
        sendEvent(FIREBASE_EVENTS.WIFI_INFO_EMPTY)
      }
    }

    async function fetchInternet() {
      try {
        const { data } = await getInternet(idContract)
        dispatch(setInternetInfo(data))
        handleInternet(data)
      } catch (error) {
        dispatch(setStage(STAGES.ERROR_SERVER))
      }
    }

    if (!internet) fetchInternet()
  }, [contractStatus, dispatch, idContract, internet, sendEvent])

  function handleEditInternet() {
    dispatch(setStage(STAGES.EDIT))
  }

  function renderContractWithoutWifiInfo() {
    return <p className='empty'>Contrato selecionado não possui dados de WIFI.</p>
  }

  function renderContractWifiInfo() {
    return (
      <Card className='modemcard'>
        <p className='text'>
          <b>Rede: </b>
          {internet.ssid}
        </p>
        <p className='text'>
          <b>Senha: </b>
          {internet.wifiPassword}
        </p>
        <p className='text'>
          <b>Contrato: </b>
          {idContract}
        </p>
        <div className='icon' onClick={handleEditInternet}>
          <STATICS.EDIT />
        </div>
      </Card>
    )
  }

  function renderContent() {
    return (
      <div className='modem-info'>
        <h3 className='title'>Meu WIFI</h3>
        {renderWifiInfo ? renderContractWifiInfo() : renderContractWithoutWifiInfo()}
      </div>
    )
  }

  return internet ? renderContent() : <ContextLoader fixed={false} />
}
