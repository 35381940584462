import React from 'react'
import { useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import './style.scss'

export function RefuseOptions() {
  const dispatch = useDispatch()

  function handleClick() {
    dispatch(setDrawer(''))
  }

  return (
    <div className='container-refuse-options'>
      <div className='container-info'>
        <div className='container-icon'>
          <STATICS.REFUSE_NEGOTIATION />
        </div>
        <span className='title'>Propostas recusadas</span>
        <span className='description'>
          Para negociar suas faturas em atraso entre em contato com a Central de Atendimento.
        </span>
      </div>
      <div className='container-buttons'>
        <Button onClick={() => handleClick()} className='button accept' color='primary'>
          Ok! Entendi
        </Button>
      </div>
    </div>
  )
}
