import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setStage } from 'slices/modal'
import { fetchContacts } from 'slices/customer'
import {
  confirmCellphone,
  confirmEmail,
  sendCellphoneConfirmation,
  sendEmailConfirmation,
} from 'hooks'
import { STATICS } from 'statics'
import { CountdownTime } from 'components/countdown-time'
import { Button } from 'components/button'
import { Input } from 'components/input'
import {
  CONFIRMATION_TYPE,
  CONFIRMATION_INPUT_PLACEHOLDER,
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TYPE_VALUES,
  CONTACT_UPDATE_STAGES,
  DEFAULT_SEND_CODE_ERROR_MESSAGE,
  DEFAULT_CONFIRM_ERROR_MESSAGE,
} from '../../constants'
import './style.scss'

export function ConfirmationStage({ data, setErrorMessage }) {
  const [canSendCode, setCanSendCode] = useState(false)
  const [code, setCode] = useState('')
  const { email, phone } = data

  const dispatch = useDispatch()
  const { history } = useSelector(({ modal }) => modal)
  const lastStage = history[history.length - 1]

  function getIsEmailConfirmation() {
    return CONFIRMATION_TYPE[lastStage] === CONFIRMATION_TYPE_VALUES.EMAIL
  }

  function getNextStage() {
    return getIsEmailConfirmation() ? CONTACT_UPDATE_STAGES.SUCCESS : CONTACT_UPDATE_STAGES.EMAIL
  }

  function onTimerFinish() {
    setCanSendCode(true)
  }

  async function handleClickResendCode() {
    try {
      if (getIsEmailConfirmation()) await sendEmailConfirmation(email)
      else await sendCellphoneConfirmation(phone)

      setCanSendCode(false)
      setErrorMessage('')
    } catch (error) {
      console.error(error)
      setErrorMessage(DEFAULT_SEND_CODE_ERROR_MESSAGE)
    }
  }

  async function handleclickConfirmationButton() {
    try {
      if (getIsEmailConfirmation()) await confirmEmail(code, email)
      else await confirmCellphone(code, phone)

      const nextStage = getNextStage()
      setErrorMessage('')
      dispatch(fetchContacts())
      dispatch(setStage(nextStage))
    } catch (error) {
      console.error(error)
      if (error.response?.data?.message) setErrorMessage(error.response.data.message)
      else setErrorMessage(DEFAULT_CONFIRM_ERROR_MESSAGE)
    }
  }

  function handleOnchangeCodeInput({ target }) {
    const { value } = target

    setCode(value)
    setErrorMessage('')
  }

  function renderCountdownText() {
    return (
      <span className='time-left'>
        <STATICS.LOADER />
        Restam <CountdownTime
          onFinishCounter={onTimerFinish}
          time={90}
          canSendCode={canSendCode}
        />{' '}
        Segundos
      </span>
    )
  }

  function renderEmailInputMessage() {
    return (
      <span className='input-message'>
        Atenção! Caso não receba o email com o código, verifique também a sua caixa de spam
      </span>
    )
  }

  function renderResendCodeButton() {
    const buttonContent = canSendCode ? 'Reenviar Código' : renderCountdownText()
    const buttonColorType = canSendCode ? 'primary' : ''

    return (
      <Button
        disabled={!canSendCode}
        onClick={handleClickResendCode}
        className='button'
        color={buttonColorType}
      >
        {buttonContent}
      </Button>
    )
  }

  return (
    <div className='contact-update-confirmation-stage'>
      <span className='title'>Atualização de Contato</span>
      <span className='description'>{CONFIRMATION_DESCRIPTION[lastStage]}</span>
      <div className='container-input'>
        <Input
          placeholder={CONFIRMATION_INPUT_PLACEHOLDER[lastStage]}
          name='code'
          className='input-code'
          onChange={handleOnchangeCodeInput}
        />
        {getIsEmailConfirmation() && renderEmailInputMessage()}
      </div>
      <div className='container-buttons'>
        {renderResendCodeButton()}
        <Button onClick={handleclickConfirmationButton} className='button' color='primary'>
          Confirmar Código
        </Button>
      </div>
    </div>
  )
}
