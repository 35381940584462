/* eslint-disable no-console */
import { API_KEY, API_URL, API_REQUEST_ORIGIN, JWT_TOKEN_KEY } from 'app-constants'

export function downloadContractTerm(contractId, setError) {
  const token = localStorage.getItem(JWT_TOKEN_KEY)

  fetch(`${API_URL}/v1/contracts/term/${contractId}`, {
    headers: {
      apikey: API_KEY,
      Authorization: `Bearer ${token}`,
      'x-origin': API_REQUEST_ORIGIN,
    },
  })
    .then(response => response.blob())
    .then(blob => {
      const urlTerm = window.URL.createObjectURL(blob)

      window.open(urlTerm, '_blank').focus()
    })
    .catch(err => {
      console.err(err)

      setError({
        title: 'Ocorreu um erro',
        text: 'Ops! Ocorreu um erro inesperado. Tente novamente mais tarde.',
      })
    })
}
