import React from 'react'
import { useDispatch } from 'react-redux'
import { GenericCard } from 'components'
import { setDrawer } from 'slices/drawer'

export function MyServicesCard({ className, title, buttonLabel, Icon, description }) {
  const dispatch = useDispatch()

  function handleDrawer() {
    dispatch(setDrawer('services'))
  }

  return (
    <GenericCard
      className={className}
      title={title}
      buttonLabel={buttonLabel}
      Icon={Icon}
      onClick={handleDrawer}
    >
      {description}
    </GenericCard>
  )
}
