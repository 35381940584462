import React from 'react'
import { getPasswordForce } from 'utils'
import { PASSWORD_BARS } from 'app-constants'
import './style.scss'

export function PasswordForce({ password }) {
  const passwordForce = getPasswordForce(password)

  function renderStrengthBars() {
    return PASSWORD_BARS.map(({ activeKeys }, index) => {
      const isActive = activeKeys.includes(passwordForce.KEY)

      return (
        <div
          key={index}
          className={`bar ${passwordForce.KEY} ${isActive ? 'active' : 'inactive'}`}
        />
      )
    })
  }

  return (
    <div className='container-bars'>
      {renderStrengthBars()}
      <span className={`translate ${passwordForce.KEY}`}>{passwordForce.TRANSLATE}</span>
    </div>
  )
}
