import React from 'react'
import { useSelector } from 'react-redux'
import { Calls, CallsHistory, CallsExtract } from './components'

export function CallsDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages()[stage]
}
function stages() {
  return {
    initial: <Calls />,
    history: <CallsHistory />,
    extract: <CallsExtract />,
  }
}
