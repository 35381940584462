import React, { useState, useEffect, useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'
import {
  PLAN_STATUS,
  MY_SERVICES,
  TV_PLAN_CARDS,
  TV_PLAN_EVENTS,
  COMPONENTS_RENDER,
  DYNAMIC_RENDER_COMPONENTS,
} from 'app-constants'
import { Card, Button, Alert } from 'components'
import { STATICS } from 'statics'
import { fetchContractDetails, fetchTvPlansActivationLink } from 'slices/contract'
import { downloadContractTerm } from 'utils'
import { Pending } from './components'
import { GO_TO_WEBSITE_TEXT, SEE_DETAILS_TEXT } from './constants'
import './style.scss'

const SHOW_TV_PASSWORD_TOOLTIP_TEXT = 'Mostrar senha'
const HIDE_TV_PASSWORD_TOOLTIP_TEXT = 'Esconder senha'

export function PlanDetails() {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState('')
  const [showTvPassword, setShowTvPassword] = useState(false)
  const { selected: contract, detail: contractDetails, activationLinks } = useSelector(
    ({ contract }) => contract
  )
  const dispatch = useDispatch()

  const title = useMemo(() => {
    if (pending)
      return (
        <h3 className='title'>
          Acompanhe o status <br />
          do seu pedido
        </h3>
      )

    return (
      <h3 className='title'>
        Detalhamento <br />
        do meu plano
      </h3>
    )
  }, [pending])

  useEffect(() => {
    const isPendentContract = contractDetails?.status === PLAN_STATUS.ST_CONT_EM_ATIVACAO

    if (!contractDetails) dispatch(fetchContractDetails(contract.id))
    if (isPendentContract) setPending(true)
    dispatch(fetchTvPlansActivationLink())
  }, [dispatch, contractDetails, contract.id])

  function handleOpenTerm() {
    downloadContractTerm(contract.id, setError)
  }

  function handleAlertClose() {
    setError(null)
  }

  function handleClickShowTvPassword() {
    setShowTvPassword(!showTvPassword)
  }

  function getIsTvPlanCardAdditional(itemName) {
    return Object.values(TV_PLAN_CARDS).includes(itemName)
  }

  function getTvPlanActivationLink(itemCode) {
    const activationLink = activationLinks.find(({ tvPlanKey }) => tvPlanKey === itemCode)

    return activationLink?.activationLink || ''
  }

  function getAdditionalInfo(itemCode, urlDocument) {
    const isTvPlanAdditional = getIsTvPlanCardAdditional(itemCode)
    const event = isTvPlanAdditional ? TV_PLAN_EVENTS[itemCode] : MY_SERVICES[itemCode].eventName
    const link = isTvPlanAdditional ? getTvPlanActivationLink(itemCode) : urlDocument
    const buttonText = isTvPlanAdditional ? GO_TO_WEBSITE_TEXT : SEE_DETAILS_TEXT
    const isTvPlan = isTvPlanAdditional && urlDocument

    return { event, link, buttonText, isTvPlan }
  }

  function renderAdditionals() {
    if (!contractDetails.contractAdditionals.length) return null
    return (
      <>
        <p className='subtitle'>Serviços adicionais</p>
        {contractDetails.contractAdditionals.map(
          ({ itemCode, urlDocument, urlLogo, urlImage, itemName }) => {
            const { link, buttonText, isTvPlan } = getAdditionalInfo(itemCode, urlDocument)

            const urlSvaLogo = urlLogo || urlImage

            return (
              <Card className={`card -additional -flex ${isTvPlan && 'tv-plan'}`} key={itemCode}>
                <div className='container-info'>
                  {urlSvaLogo && <img src={urlSvaLogo} alt={itemName} className='img' />}
                  <p className='text'>{itemName}</p>
                </div>
                <div className='container-buttons'>
                  {link && (
                    <a href={link} rel='noreferrer' target='_blank' className='see-details'>
                      <Button
                        color='primary'
                        outlined
                        className='see-details'
                        data-cy='button-see-details'
                      >
                        {buttonText}
                      </Button>
                    </a>
                  )}
                  {isTvPlan && (
                    <a href={urlDocument} rel='noreferrer' target='_blank'>
                      <Button
                        color='primary'
                        outlined
                        className='see-details globoplay'
                        data-cy='button-see-details-globoplay'
                      >
                        {SEE_DETAILS_TEXT}
                      </Button>
                    </a>
                  )}
                </div>
              </Card>
            )
          }
        )}
      </>
    )
  }

  function renderShowTvPasswordButton() {
    return (
      <>
        <div
          className='icon'
          data-for='tv-password-tip'
          data-tip={showTvPassword ? HIDE_TV_PASSWORD_TOOLTIP_TEXT : SHOW_TV_PASSWORD_TOOLTIP_TEXT}
        >
          {showTvPassword ? (
            <STATICS.EYE onClick={handleClickShowTvPassword} />
          ) : (
            <STATICS.EYE_SLASH onClick={handleClickShowTvPassword} />
          )}
        </div>
        <ReactTooltip id='tv-password-tip' />
      </>
    )
  }

  function renderDownloadButton() {
    const shouldRenderDownloadButton =
      COMPONENTS_RENDER[DYNAMIC_RENDER_COMPONENTS.CONTRACT_PDF_DOWNLOAD]

    return (
      shouldRenderDownloadButton && (
        <Button color='primary' className='button' outlined onClick={handleOpenTerm}>
          Download do PDF
        </Button>
      )
    )
  }

  if (!contractDetails) return null

  return (
    <div className='plan-details-component'>
      {title}
      {!!error && (
        <Alert
          className='alert'
          severity='error'
          size='large'
          title={error.title}
          text={error.text}
          onClose={handleAlertClose}
        />
      )}
      {pending && <Pending />}

      <p className='subtitle'>Plano contratado</p>
      <Card className='card'>
        <p className='text'>{contractDetails.planName}</p>
        <p className='text'>Valor: {contractDetails.formattedTotalPrice}</p>
        {contractDetails.additionalTvPointsQuantity > 0 && (
          <p className='text'>
            {contractDetails.additionalTvPointsQuantity} Ponto opcional (HD):{' '}
            {contractDetails.formattedAdditionalTvPointsTotalPrice}
          </p>
        )}
        <p className='text'>Status: {PLAN_STATUS[contract.contractStatus]}</p>
      </Card>

      {!!contractDetails.tvPassword && (
        <>
          <p className='subtitle'>Login da TV | Sumicity Play</p>
          <Card className='card'>
            <p className='text'>Usuário: {contractDetails.tvLogin}</p>
            <div className='tv-password-container'>
              <p className='text'>Senha :&nbsp;</p>
              <p className={showTvPassword ? 'password' : 'password -hidden'}>
                {contractDetails.tvPassword}
              </p>
              {renderShowTvPasswordButton()}
            </div>
          </Card>
        </>
      )}

      {renderAdditionals()}
      <Card className='card -contract -flex'>
        <div className='content'>
          <p className='title'>Meu contrato</p>
          <p className='text'>{contractDetails.planName}</p>
        </div>
        {!pending && renderDownloadButton()}
      </Card>
    </div>
  )
}
