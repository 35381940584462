import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MY_BILLS_PAGE_TAB_LABEL, ROUTES } from 'app-constants'
import { Button } from 'components/button'
import { setDrawer } from 'slices/drawer'
import { STATICS } from 'statics'
import './style.scss'

export function VirtualSupportAlert() {
  const { push } = useHistory()
  const dispatch = useDispatch()

  function handleClickGoToInvoices() {
    push(`${ROUTES.INVOICES.PATH}#${MY_BILLS_PAGE_TAB_LABEL.BANKSLIPS}`)
  }

  return (
    <div className='address-change-alert-component'>
      <div className='icon'>
        <STATICS.INFO_ERROR />
      </div>
      <h2 className='title'>Você possui faturas em aberto</h2>
      <p className='description'>
        Efetue o pagamento pendente para solicitar suporte técnico. Se o pagamento já foi efetuado,
        aguarde a compensação bancária.
      </p>
      <Button
        type='button'
        color='primary'
        onClick={() => {
          handleClickGoToInvoices()
          dispatch(setDrawer(''))
        }}
      >
        Ir para minhas faturas
      </Button>
    </div>
  )
}
