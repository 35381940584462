/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.list.push({ ...action.payload, id: Date.now().toString() })
    },
    clearAlert: (state, action) => {
      state.list = state.list.filter(alert => alert.id !== action.payload.id)
    },
  },
})

export const { setAlert, clearAlert } = alertSlice.actions

export default alertSlice.reducer
