import React from 'react'
import { useDispatch } from 'react-redux'
import { GenericCard } from 'components'
import { setModal } from 'slices/modal'
import { useFirebase } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'

export function FileUploadCard({ className, title, buttonLabel, Icon, description }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  function handleFileUpload() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SEND_FILE)
    dispatch(setModal({ key: 'file_upload_sumicity' }))
  }

  return (
    <GenericCard
      className={className}
      title={title}
      buttonLabel={buttonLabel}
      Icon={Icon}
      onClick={handleFileUpload}
    >
      {description}
    </GenericCard>
  )
}
