export const ROUTES = {
  HOME: {
    PATH: '/',
  },
  INVOICES: {
    PATH: '/minhas-faturas',
  },
  TECHNICAL_SUPPORT: {
    PATH: '/suporte-tecnico',
  },
  VALIDATE_TOKEN: {
    PATH: '/validar-codigo',
  },
  FORGOT_PASSWORD: {
    PATH: '/esqueci-minha-senha',
  },
  PASSWORD_RECOVERY: {
    PATH: '/recuperacao-senha',
  },
  FIRST_ACCESS: {
    PATH: '/primeiro-acesso',
  },
  FIRST_ACCESS_TOKEN: {
    PATH: '/primeiro-acesso/validar-token',
  },
  FIRST_ACCESS_PASSWORD: {
    PATH: '/primeiro-acesso/senha',
  },
  REQUIRED_PASSWORD_UPDATE: {
    PATH: '/atualizar-senha',
  },
}

export const URLS = {
  SUMICITY: 'https://sumicity.com.br',
  SUMICITY_PLAY: 'https://portal.sumicityplay.com.br',
  GIGA: '',
  SPEED_TEST: 'https://polosumicity.speedtestcustom.com/',
}
