import React from 'react'
import { formatCurrency } from 'utils'
import './style.scss'

export function TotalNegotiationCard({ invoices = [] }) {
  function renderAmount() {
    return invoices.reduce((sum, current) => sum + current.value, 0)
  }
  return (
    <div className='total-negotiation-card'>
      <h4 className='title'>Total Pendente:</h4>
      <span className='value'>{formatCurrency(renderAmount())}</span>
      <hr className='divider' />
    </div>
  )
}
