import React from 'react'
import { Error } from '../error'
import { InvoicesList } from '../invoices-list'
import { WithoutOptions } from '../without-options'

export function InitialNegotiation(props) {
  const { invoices, isEligible, hasError } = props
  const hasInvoices = invoices?.length

  if (hasError) return <Error {...props} />

  if (isEligible && hasInvoices) return <InvoicesList {...props} />

  return <WithoutOptions {...props} />
}
