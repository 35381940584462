import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setModal } from 'slices/modal'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import { EVALUATION_UTILS_MODAL } from './utils'
import './style.scss'

export function UraEvaluationValidationModal() {
  const { push } = useHistory()
  const dispatch = useDispatch()

  function handleClick() {
    dispatch(setModal({ key: '' }))
    push('/')
  }

  return (
    <div className='ura-evaluation-validation-modal'>
      <div className='round'>
        <div className='icon'>
          <STATICS.SUCCESS />
        </div>
      </div>
      <h3 className='title'>{EVALUATION_UTILS_MODAL.TITLE}</h3>
      <p className='text'>{EVALUATION_UTILS_MODAL.DESCRIPTION}</p>
      <Button className='button' color='primary' size='large' onClick={handleClick}>
        {EVALUATION_UTILS_MODAL.BUTTON}
      </Button>
    </div>
  )
}
