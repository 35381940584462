import { BRAND_DISPLAY_NAME } from 'app-constants'

export const EVALUATION_UTILS = {
  FORM: {
    TITLE_FIRST: 'Avalie seu atendimento!',
    QUESTION_1: 'A sua solicitação foi atendida:',
    QUESTION_2: `De 0 a 10, qual a chance de indicar a ${BRAND_DISPLAY_NAME} para um amigo`,
    QUESTION_3: 'De 0 a 10, qual o seu grau de satisfação com o atendimento:',
    BUTTON: 'Enviar',
  },
  NUMBERS: [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
    {
      id: 9,
    },
    {
      id: 10,
    },
  ],
}
