import React from 'react'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import './style.scss'

export function DuplicateInvoiceModal() {
  return (
    <div className='duplicate-invoice-container'>
      <div className='icon'>
        <div className='background'>
          <STATICS.ROUNDED_RECEIPT className='image' />
        </div>
      </div>
      <div className='title'>2ª via da fatura</div>
      <div className='message'>
        <p className='text'>Deseja receber a 2ª via da sua fatura?</p>
        <p className='text'>
          Clique em "Enviar 2ª via da fatura" que enviaremos a fatura por SMS e e-mail
        </p>
      </div>
      <Button className='button' color='primary' size='large'>
        Enviar 2ª via da fatura
      </Button>
      <div className='warning'>
        Caso não receba o SMS e E-mail, confira seu celular e e-mail nos dados cadastrais ou
        solicite novamente.
      </div>
    </div>
  )
}
