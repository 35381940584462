import React from 'react'
import { STATICS } from 'statics'

export const BUTTON_THEME = {
  GO_TO_INVOICES: 'GO_TO_INVOICES',
  WHATS_APP_SUPPORT: 'WHATS_APP_SUPPORT',
  RESCHEDULE: 'RESCHEDULE',
  CANCEL: 'CANCEL',
}

export const BUTTON_CONTENT = {
  [BUTTON_THEME.GO_TO_INVOICES]: {
    TEXT: 'Ir para minhas faturas',
  },
  [BUTTON_THEME.WHATS_APP_SUPPORT]: {
    ICON: <STATICS.WHATSAPP_BADGE />,
    TEXT: 'Falar com atendente',
  },
  [BUTTON_THEME.RESCHEDULE]: {
    TEXT: 'Reagendar visita',
  },
  [BUTTON_THEME.CANCEL]: {
    TEXT: 'Cancelar visita',
  },
}

export const ALERT_THEME = {
  SUCCESS: 'SUCCESS',
  OPEN_INVOICES: 'OPEN_INVOICES',
  UNAVAILABLE_FEATURE: 'UNAVAILABLE_FEATURE',
  ADDRESS_INVIABILITY: 'ADDRESS_INVIABILITY',
  ERROR: 'ERROR',
  CONDOMINIUM_NOT_REGISTERED: 'CONDOMINIUM_NOT_REGISTERED',
}

export const ALERT_THEME_CONTENT = {
  [ALERT_THEME.SUCCESS]: {
    ICON: <STATICS.SUCCESS className='green' />,
    TITLE: 'Sua solicitação foi cadastrada com sucesso!',
    DESCRIPTION: 'Aguarde a visita do técnico na data agendada.',
  },
  [ALERT_THEME.OPEN_INVOICES]: {
    ICON: <STATICS.INFO_ERROR />,
    TITLE: 'Você possui faturas em aberto',
    DESCRIPTION:
      'Efetue o pagamento pendente para solicitar a mudança de endereço. Se o pagamento já foi efetuado, aguarde a compensação bancária.',
    BUTTON: BUTTON_THEME.GO_TO_INVOICES,
  },
  [ALERT_THEME.ADDRESS_INVIABILITY]: {
    ICON: <STATICS.INFO_ERROR />,
    TITLE: 'Não localizamos cobertura para seu novo endereço',
    DESCRIPTION:
      'Entre em contato conosco através do WhatsApp, para prosseguirmos com a sua solicitação.',
    BUTTON: BUTTON_THEME.WHATS_APP_SUPPORT,
  },
  [ALERT_THEME.ERROR]: {
    ICON: <STATICS.INFO_ERROR />,
    TITLE: 'Não foi possível concluir a sua solicitação',
    DESCRIPTION: 'Entre em contato conosco através do WhatsApp.',
    BUTTON: BUTTON_THEME.WHATS_APP_SUPPORT,
  },
  [ALERT_THEME.UNAVAILABLE_FEATURE]: {
    ICON: <STATICS.INFO_CHAT />,
    TITLE: 'Essa  funcionalidade está  temporariamente indisponível',
    DESCRIPTION:
      'Entre em contato conosco através do WhatsApp que iremos te ajudar com a sua solicitação.',
    BUTTON: BUTTON_THEME.WHATS_APP_SUPPORT,
  },
  [ALERT_THEME.CONDOMINIUM_NOT_REGISTERED]: {
    ICON: <STATICS.INFO_CHAT />,
    TITLE: 'Obrigado pela confirmação de dados',
    DESCRIPTION:
      'Já conseguimos avaliar em nossos sistemas, mas para continuar seu agendamento, será necessário falar com um atendente por aqui mesmo.\n\nClique no botão abaixo e você já será atendido para terminar o processo.',
    BUTTON: BUTTON_THEME.WHATS_APP_SUPPORT,
  },
}
