import React from 'react'
import { STATICS } from 'statics'

export const FAQ_CATEGORIES = {
  TECHNICAL_SUPPORT: 'TECHNICAL_SUPPORT',
  PAYMENT: 'PAYMENT',
  WIFI: 'WIFI',
  PRODUCTS: 'PRODUCTS',
  CENTRAL_ASSINANTE: 'CENTRAL_ASSINANTE',
  OTHER: 'OTHER',
}

export const FAQ_CATEGORIES_LABELS = {
  [FAQ_CATEGORIES.TECHNICAL_SUPPORT]: 'Suporte técnico',
  [FAQ_CATEGORIES.PAYMENT]: 'Pagamento',
  [FAQ_CATEGORIES.WIFI]: 'Wi-fi',
  [FAQ_CATEGORIES.PRODUCTS]: 'Produtos',
  [FAQ_CATEGORIES.CENTRAL_ASSINANTE]: 'Central do Assinante',
  [FAQ_CATEGORIES.OTHER]: 'Outras dúvidas',
}

export const FAQ_CATEGORIES_ICONS = {
  [FAQ_CATEGORIES.TECHNICAL_SUPPORT]: <STATICS.SUPPORT />,
  [FAQ_CATEGORIES.PAYMENT]: <STATICS.CASH />,
  [FAQ_CATEGORIES.WIFI]: <STATICS.WIFI_FAQ />,
  [FAQ_CATEGORIES.PRODUCTS]: <STATICS.PHONE_TV />,
  [FAQ_CATEGORIES.CENTRAL_ASSINANTE]: <STATICS.USER_WEBSITE />,
  [FAQ_CATEGORIES.OTHER]: <STATICS.CHAT_QUESTION />,
}
