import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { useForm, resetPassword, useFirebase, changePassword, useRemindPasswordLater } from 'hooks'
import { setModal } from 'slices/modal'
import {
  MESSAGES,
  NEITHER_WHITESPACE_NOR_COMMA_PATTERN,
  DEFAULT_ERROR_MESSAGE,
  ORIGIN,
  FIREBASE_EVENTS,
  EVENT_STATUS,
  MINIMUM_PASSWORD_LENGTH,
  MAXIMUM_PASSWORD_LENGTH,
  ROUTES,
} from 'app-constants'
import { PasswordForce } from 'components/password-force'
import { Button } from '../button'
import { PasswordAlert } from '../password-alert'
import { PasswordHeader } from '../password-header'
import { PasswordLogo } from '../password-logo'
import { Input } from '../input'
import { PasswordRules } from '../password-rules'
import './style.scss'

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(MESSAGES.REQUIRED)
    .min(MINIMUM_PASSWORD_LENGTH, MESSAGES.PASSWORD_MIN_LENGTH)
    .max(MAXIMUM_PASSWORD_LENGTH, MESSAGES.PASSWORD_MAX_LENGTH)
    .test(
      'newPassword',
      'A senha não pode conter espaços ou vírgulas',
      value => value && NEITHER_WHITESPACE_NOR_COMMA_PATTERN.test(value)
    ),
  confirmationNewPassword: Yup.string()
    .required(MESSAGES.REQUIRED)
    .oneOf([Yup.ref('newPassword'), null], MESSAGES.PASSWORDS_DONT_MATCH),
})

export function PasswordResetForm({ title, subtitle, prevStep, required = false }) {
  const [error, setError] = useState(null)
  const [alertError, setAlertError] = useState(null)
  const history = useHistory()
  const { passwordRecovery } = useSelector(state => state.customer)
  const { sendEvent } = useFirebase()
  const { setRemindPasswordLater } = useRemindPasswordLater()
  const dispatch = useDispatch()

  const form = useForm({
    initialValues: {
      password: '',
      newPassword: '',
      confirmationNewPassword: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    const errors = [...Object.values(form.errors)]
    if (errors[0]) {
      setError({ title: errors[0] })
    }
  }, [form.errors])

  async function updatePassword(values) {
    const { password, newPassword } = values
    if (required) {
      return changePassword({
        password,
        newPassword,
        brand: ORIGIN,
      })
    }

    const { document, token } = passwordRecovery
    return resetPassword({
      password: newPassword,
      brand: ORIGIN,
      document,
      token,
    })
  }

  async function handleSubmit(values) {
    try {
      const { status } = await updatePassword(values)

      sendEvent(FIREBASE_EVENTS.ON_CHANGE_PASSWORD, EVENT_STATUS.SUCCESS, status)
      setError(null)
      dispatch(setModal({ key: 'password_reset', locked: true }))
    } catch (err) {
      setError(null)
      const errorMessage = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE

      sendEvent(FIREBASE_EVENTS.ON_CHANGE_PASSWORD, EVENT_STATUS.ERROR, err?.response?.status)
      sendEvent(FIREBASE_EVENTS.ON_CHANGE_PASSWORD_ERROR, EVENT_STATUS.ERROR, err?.response?.status)

      setAlertError({ title: 'Ocorreu um erro', text: errorMessage })
    }
  }

  function handleAlertClose() {
    setAlertError(null)
  }

  function handleRemindLaterClick() {
    setRemindPasswordLater(true)
  }

  if (prevStep && !passwordRecovery?.token) {
    history.push(prevStep)

    return null
  }

  return (
    <div className='password-reset-page'>
      <PasswordLogo />
      <PasswordAlert error={alertError} onClose={handleAlertClose} />
      <form className='form' onSubmit={form.handleSubmit}>
        <PasswordHeader title={title} subtitle={subtitle} alert={required} />
        <PasswordRules />
        {!!required && (
          <Input
            className='password'
            name='password'
            label='Senha atual'
            type='password'
            maxLength='20'
            value={form.values.password}
            onChange={form.handleChange}
            hasError={form.errors.password}
          />
        )}
        <Input
          className='password'
          name='newPassword'
          label='Nova senha'
          type='password'
          maxLength='20'
          value={form.values.newPassword}
          onChange={form.handleChange}
          hasError={form.errors.newPassword}
        />
        <PasswordForce password={form.values.newPassword} />
        <Input
          className='confirmation'
          name='confirmationNewPassword'
          label='Repetir nova senha'
          type='password'
          maxLength='20'
          value={form.values.confirmationNewPassword}
          onChange={form.handleChange}
          hasError={form.errors.confirmationNewPassword}
        />
        {!!error && <p className='error'>{error.title}</p>}
        <Button
          className='button'
          type='submit'
          color='primary'
          size='large'
          isLoading={form.isSubmitting}
        >
          Criar nova senha
        </Button>
        <div className='remind-later'>
          <Link to={ROUTES.HOME.PATH} onClick={handleRemindLaterClick}>
            Me lembre mais tarde
          </Link>
        </div>
      </form>
    </div>
  )
}
