const fs = require('fs')

function writeFile(file, string) {
  fs.writeFileSync(file, string)

  return true
}

module.exports = {
  writeFile,
}
