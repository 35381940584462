import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { STATICS } from 'statics'
import { setDrawer } from 'slices/drawer'
import './style.scss'

export function RescheduleSuccess() {
  const dispatch = useDispatch()

  function handleContinue() {
    dispatch(setDrawer(''))
  }
  return (
    <div className='reschedule-success'>
      <div className='content'>
        <STATICS.SUCCESS className='icon' />
        <h3 className='title'>A mudança de endereço foi reagendada com sucesso!</h3>
        <p className='text'>Aguarde a visita do técnico na data agendada.</p>
      </div>
      <Button
        className='button'
        color='primary'
        size='large'
        onClick={() => {
          handleContinue()
        }}
      >
        Voltar para home
      </Button>
    </div>
  )
}
