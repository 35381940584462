import React from 'react'
import { CENTRAL_GIGA_URL, BRAND_DISPLAY_NAME_REDIRECT } from 'app-constants'
import { PasswordLogo } from 'components'
import './style.scss'

export function ApplicationRedirect() {
  return (
    <div className='application-redirect-page'>
      <PasswordLogo />
      <div className='container-message'>
        <h2 className='title'>{BRAND_DISPLAY_NAME_REDIRECT} agora é Giga+ Fibra</h2>
        <span className='text'>
          Temos uma novidade. Sua central do Assinante está de cara nova.
          <br />
          <br />
          Mob agora é Giga+ Fibra, clique no botão abaixo e acesse a nova Central do Assinante.
        </span>
        <a href={CENTRAL_GIGA_URL} className='redirect-button'>
          Ir para Central Giga+ Fibra
        </a>
      </div>
    </div>
  )
}
