import React from 'react'
import { Button } from 'components/button'
import './style.scss'

export function GenericCardLarge({
  title,
  children,
  image,
  onClick,
  buttonLabel = 'Saiba mais',
  disabled,
}) {
  function handleClick(e) {
    e.stopPropagation()
    onClick()
  }

  return (
    <>
      <div className='header'>
        <img className='image' src={image} alt='' />
        <h3 className='title'>{title}</h3>
      </div>
      <div className='content'>
        <h3 className='title'>{title}</h3>
        <p className='text'>{children}</p>
      </div>
      <Button
        className='button'
        disabled={disabled}
        color='secondary'
        onClick={e => handleClick(e)}
      >
        {buttonLabel}
      </Button>
    </>
  )
}
